import React, { useEffect } from "react";
import { H2 } from "../../../styles/Common.style";
import InputField from "../../../Components/Textfield/InputField";
import ETButton from "../../../Components/Buttons/ETButton";
import IconTextField from "../../../Components/GroupText/IconTextField";
import { IconField } from "../../../Components/GroupText/IconTextField";
import CustomReactSelect from "../../../Components/DropDown/CustomReactSelect";
import { Controller, useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { SelectPhoneNumber } from "./../../../Components/phoneNumberDropdown/SelectPhoneNumber";

const UserFormStepOne = ({
  handleNext,
  userBasicData,
  customToken,
  isSendOtpLoading,
}) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    clearErrors,
    reset,
    resetField,
    getFieldState,
    formState: { errors, isDirty, dirtyFields, isValid },
  } = useForm({});

  const handleSubmitFormOne = (data) => {
    handleNext(data);
  };
  useEffect(() => {
    setValue("first_name", userBasicData?.first_name);
    setValue("last_name", userBasicData?.last_name);
    setValue("email", userBasicData?.email);
    setValue("isd_code", userBasicData?.isd_code);
    setValue("phone", userBasicData?.contact_no);
  }, [userBasicData]);
  return (
    <Form
      onSubmit={handleSubmit(handleSubmitFormOne)}
      className="whiteContainerStyle p-3"
    >
      <div className="pb-40">
        <H2>Create user</H2>
        <span className="caption-light">Step 1 out of 5</span>
      </div>

      <div className="pb-40">
        <div className="light-font-text mb-3">
          Excited? You will soon be part of the future of proptech!
          <div>{`You are invited to join the estatetool solution for ${userBasicData?.organisation_name}`}</div>
        </div>
        <div className="light-font-text mb-3">
          Edit in the fields below if something is wrong.
        </div>
        <div className="d-flex flex-column gap-3">
          <div className="w-75">
            <Controller
              control={control}
              {...register("first_name")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <IconField
                  type="text"
                  id="first_name"
                  value={value}
                  errorscope={true}
                  iserror={errors?.first_name}
                  onChange={(e) => {
                    onChange(e);
                    clearErrors("first_name");
                  }}
                  placeholder="First name "
                  Icons="fa-solid fa-signature"
                />
              )}
            />
          </div>

          <div className="w-75">
            <Controller
              control={control}
              {...register("last_name")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <IconField
                  type="text"
                  id="last_name"
                  value={value}
                  errorscope={true}
                  iserror={errors?.last_name}
                  onChange={(e) => {
                    onChange(e);
                    clearErrors("last_name");
                  }}
                  placeholder="Last name "
                  Icons="fa-solid fa-signature"
                />
              )}
            />
          </div>
          <div className="w-75">
            <Controller
              control={control}
              {...register("email")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <IconField
                  type="email"
                  id="email"
                  value={value}
                  errorscope={true}
                  iserror={errors?.email}
                  disabled={true}
                  onChange={(e) => {
                    onChange(e);
                    clearErrors("email");
                  }}
                  placeholder="Email "
                  Icons="fa-regular fa-envelope"
                />
              )}
            />
          </div>
          <div className="w-75">
            <SelectPhoneNumber
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              getPhoneId={"isd_code"}
              getPhoneNumber={"phone"}
              control={control}
              placeholder={"Phone"}
              token={customToken}
              isdValue={userBasicData?.isd_code}
              isDisabled={true}
              isRemoveOpacity={true}
              isPhoneIconRequired
            />
          </div>
        </div>
      </div>

      <div>
        <ETButton
          variant={"primary"}
          buttonText="NEXT"
          type={"submit"}
          disabled={isSendOtpLoading}
        />
      </div>
    </Form>
  );
};

export default UserFormStepOne;
