import React, { useEffect, useRef, useState } from "react";
import CustomPopup from "../CustomPopup";
import ETButton from "../Buttons/ETButton";
import { useForm } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import { GreenSpan, H4 } from "../../styles/Common.style";
import CustomToolTip from "../Tooltip/CustomTooltip";
import { SelectPhoneNumber } from "../phoneNumberDropdown/SelectPhoneNumber";
import { REQUIRED_ERR } from "../../utils/validationConstants";
import { useSendOtpWithPayload } from "../../hooks/queries/useSMSQueries";
import {
  ErrorAlert,
  LoadingAlert,
  SucessAlert,
} from "../alerts/AlertsAndMessages";
import { toast } from "react-toastify";
import { status } from "../../model/Constant";
import {
  useGetUserDetails,
  usePutUpdateMobile,
} from "../../hooks/queries/useUserQueries";

const ChangePhoneNumber = ({ show, setShow }) => {
  //   ----------- use form ----------------//
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    reset,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {},
  });

  //   ----------- State ----------------//
  const [stepWizard, setStepWizard] = useState("EnterPhoneNumber");
  const [referenceNo, setReferenceNo] = useState("");

  //   ----------- React query ----------------//
  const {
    mutateAsync: PostSendOtpWithPayload,
    isLoading: PostSendOtpWithPayloadIsLoading,
  } = useSendOtpWithPayload();
  const { mutateAsync: PutUpdateMobile, isLoading: PutUpdateMobileIsLoading } =
    usePutUpdateMobile();
  const { data: GetUserDetailsData, refetch: GetUserDetailsRefetch } =
    useGetUserDetails();
  //   ----------- EnterPhoneNumber ----------------//
  const handleNextEnterPhoneNumber = async (data) => {
    let id;
    const phoneNumberPlayload = {
      mobile_number: `${data?.isd_code}${data?.contact_no}`,
    };

    if (!PostSendOtpWithPayloadIsLoading) {
      id = toast.loading(<LoadingAlert />);
    }
    try {
      const response = await PostSendOtpWithPayload(phoneNumberPlayload);
      if (response?.status === "success" && response.data.bReturn) {
        toast.update(id, {
          render: <SucessAlert />,
          isLoading: false,
          type: status.s,
          autoClose: 2000,
        });

        setReferenceNo(response?.data?.data?.ref_no);
        setStepWizard("EnterVerificationCode");
      } else {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.update(id, {
        render: <ErrorAlert />,
        isLoading: false,
        type: status.e,
        autoClose: 2000,
      });
    }
  };
  const handleCancelEnterPhoneNumber = () => {
    setShow(false);
  };

  //   ----------- EnterVerificationCode ----------------//
  const handleNextEnterVerificationCode = async (data) => {
    let id;
    const VerificationCodePlayload = {
      mobile_number: watch("contact_no"),
      isd_code: watch("isd_code"),
      reference_number: referenceNo?.toString(),
      otp_code: `${data?.otp1}${data?.otp2}${data?.otp3}${data?.otp4}${data?.otp5}${data?.otp6}`,
    };
    if (!PutUpdateMobileIsLoading) {
      id = toast.loading(<LoadingAlert />);
    }
    try {
      const response = await PutUpdateMobile(VerificationCodePlayload);
      if (response?.status === "success" && response.data.bReturn) {
        toast.update(id, {
          render: <SucessAlert />,
          isLoading: false,
          type: status.s,
          autoClose: 2000,
        });
        setStepWizard("FinalConfirmation");
        GetUserDetailsRefetch();
        reset();
      } else {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.update(id, {
        render: <ErrorAlert />,
        isLoading: false,
        type: status.e,
        autoClose: 2000,
      });
    }
  };
  const handleCancelEnterVerificationCode = () => {
    setShow(false);
  };

  //   ----------- FinalConfirmation ----------------//
  const handleCloseFinalConfirmation = (data) => {
    setShow(false);
  };
  const handleBackFinalConfirmation = () => {
    setStepWizard("EnterPhoneNumber");
  };

  const WizardsPages = {
    EnterPhoneNumber: (
      <EnterPhoneNumber
        handleCancel={handleCancelEnterPhoneNumber}
        handleNext={handleNextEnterPhoneNumber}
        control={control}
        handleSubmit={handleSubmit}
        reset={reset}
        getValues={getValues}
        setValue={setValue}
        errors={errors}
        disableButton={PostSendOtpWithPayloadIsLoading}
      />
    ),
    EnterVerificationCode: (
      <EnterVerificationCode
        refNo={referenceNo}
        handleCancel={handleCancelEnterVerificationCode}
        handleNext={handleNextEnterVerificationCode}
        disableButton={PutUpdateMobileIsLoading}
        handleSubmit={handleSubmit}
        setValue={setValue}
        errors={errors}
        register={register}
      />
    ),
    FinalConfirmation: (
      <FinalConfirmation
        handleBack={handleBackFinalConfirmation}
        handleClose={handleCloseFinalConfirmation}
        isResponses={"S"}
      />
    ),
  };

  useEffect(() => {
    if (show === false) {
      setStepWizard("EnterPhoneNumber");
    }
  }, [show]);

  return (
    <>
      {" "}
      <CustomPopup
        size="md"
        open={show}
        // onHide={() => setShow(false)}
        title={"Change phone number"}
        title_color="black"
        titleTooltipText={"Change phone number"}
        titleTooltip={true}

        // onHide={() => {
        //   setShow(false);
        // }}
      >
        <div>{WizardsPages[stepWizard]}</div>
      </CustomPopup>
    </>
  );
};

export default ChangePhoneNumber;

const EnterPhoneNumber = ({
  handleCancel,
  handleNext,
  control,
  handleSubmit,
  reset,
  getValues,
  setValue,
  errors,
  disableButton,
}) => {
  const onFormSubmit = (data) => {
    handleNext(data);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <div style={{ minHeight: "180px" }}>
          <div className="light-font-text d-flex flex-column gap-3 p-4 border-bottom">
            <div>Enter your new phone number below. </div>
            <div>
              Please make sure that you have access to this phone number.
            </div>
          </div>
          <div className="w-75 p-4">
            <div className="d-flex align-items-center gap-2 pb-3">
              <H4>Enter new phone number</H4>{" "}
              <CustomToolTip
                titleTooltip="Enter new phone number"
                titleTooltipText="Enter new phone number"
              />
            </div>
            <div>
              <SelectPhoneNumber
                errors={errors}
                getValues={getValues}
                setValue={setValue}
                getPhoneId={"isd_code"}
                getPhoneNumber={"contact_no"}
                control={control}
                placeholder={"Phone"}
              />
            </div>
          </div>
        </div>{" "}
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="Cancel"
            type={"button"}
            onClick={() => handleCancel()}
          />
          <ETButton
            variant={"primary"}
            type={"submit"}
            buttonText={"get verification code"}
            disabled={disableButton}
          />
        </div>
      </Form>
    </div>
  );
};

const EnterVerificationCode = ({
  refNo,
  handleCancel,
  handleNext,
  disableButton,
  handleSubmit,
  setValue,
  errors,
  register,
}) => {
  const [emailSuccess, setEmailSuccess] = useState(null);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);

  // ----------------- all const managed here -------------
  const hasEmptyString = otpValues.every((value) => value !== "");
  const otpFieldsRef = useRef([]);

  const handleInput = (index, value) => {
    if (!value) return null;
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);
    setValue(`otp${index + 1}`, value, { shouldValidate: true });
    if (value.length === 1 && index < otpValues.length - 1) {
      otpFieldsRef.current[index + 1].focus();
    }
  };
  const handleBackspace = (index) => {
    if (otpValues[index] !== "") {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = "";
      setValue(`otp${index + 1}`, "", { shouldValidate: true });
      setOtpValues(newOtpValues);
    } else if (index > 0) {
      otpFieldsRef.current[index - 1].focus();
    }
  };
  const handleResend = async () => {
    //   if (errorMessage?.flag === "Expired") {
    //     await handleUserSignUpStepOneNext();
    //     setVerificationProcess("D");
    //   } else {
    //     setVerificationProcess("D");
    //   }
  };

  // ------------ form submit button clicked
  const onFormSubmit = (data) => {
    setEmailSuccess(true);
    handleNext(data);
    // setOtpValues(["", "", "", "", "", ""]);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <div style={{ minHeight: "180px" }}>
          <div className="light-font-text d-flex flex-column gap-2 p-4 border-bottom">
            <div>
              We have send you a text message with a six digit verification
              code.
            </div>
            <div>
              Please fill out the code below to verify your phone number.
            </div>
          </div>

          <div className="p-4">
            <div className="d-flex align-items-center gap-2 pb-2">
              <H4>Enter verification code</H4>{" "}
              <CustomToolTip
                titleTooltip="Enter verification code"
                titleTooltipText="Enter verification code"
              />
            </div>

            {/* <Row>
              <Col className=""> */}
            <div className="profile-otp-container gap-3 flex-wrap px-2 ">
              {otpValues.map((value, index) => (
                <div className="d-flex flex-column" key={index}>
                  <input
                    type="number"
                    maxLength="1"
                    className="profile-otp-input"
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                    value={value}
                    {...register(`otp${index + 1}`, {
                      required: true,
                    })}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace") {
                        handleBackspace(index);
                      } else if (!/^\d$/.test(e.key)) {
                        // If the pressed key is not a digit, prevent its input
                        e.preventDefault();
                      } else {
                        handleInput(index, e.key);
                      }
                    }}
                    ref={(ref) => {
                      otpFieldsRef.current[index] = ref;
                    }}
                  />
                  <div
                    className="otp-border"
                    style={{
                      backgroundColor: value !== "" && "#F1664B",
                    }}
                  ></div>
                </div>
              ))}
            </div>

            <div className="d-flex align-items-center gap-1 fs-14 my-3">
              <span>Reference number:</span>
              <span>{refNo}</span>
            </div>
            {Boolean(Object.keys(errors).length) && (
              <div className="d-flex light-font-login">{REQUIRED_ERR}</div>
            )}

            <div className="mt-3 d-flex light-font-login">
              <span className="fs-14 me-1">Didn't get a code?</span>
              <GreenSpan
                className="fs-14 cursor-pointer font-medium "
                // onClick={() => handleSendOtp(token?.authToken)}
              >
                Resend code
              </GreenSpan>
            </div>
            {/* </Col>
            </Row> */}
          </div>
        </div>

        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="Cancel"
            type={"button"}
            onClick={() => handleCancel()}
          />
          <ETButton
            variant={"primary"}
            type={"submit"}
            buttonText={"verify"}
            disabled={hasEmptyString === false ? true : false || disableButton}
          />
        </div>
      </Form>
    </div>
  );
};

const FinalConfirmation = ({ isResponses, handleBack, handleClose }) => {
  return (
    <div>
      <div style={{ minHeight: "100px" }}>
        {isResponses === "S" ? (
          <div className="light-font-text d-flex flex-column gap-2 p-4 ">
            <div>Great success, the code was correct!</div>
            <div>Your phone number has been changed.</div>
          </div>
        ) : (
          <div className="light-font-text d-flex flex-column gap-2 p-4 ">
            <div>Something went wrong. The verification code was declined.</div>
            <div>Please go back and try again.</div>
          </div>
        )}
      </div>{" "}
      <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
        <div>
          {" "}
          {isResponses !== "S" && (
            <ETButton
              variant={"white"}
              buttonText="back"
              type={"button"}
              onClick={() => handleBack()}
            />
          )}
        </div>

        {isResponses === "S" && (
          <ETButton
            variant={"primary"}
            buttonText={"close"}
            onClick={() => handleClose()}
          />
        )}
      </div>
    </div>
  );
};
