import React from "react";
import { REQUIRED_ERR } from "../../utils/validationConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InputField = ({
  type,
  placeholder,
  id,
  maxLength = 30,
  value,
  onChange,
  iserror,
  errormessage,
  errorscope = true,
  disabled = false,
  defaultValue = "",
  removeOpacity = false,
  showValueCount = false,
  textTransform = "",
  handleBlur = () => {},
  fieldType = "",
  fieldName = "",
  subText = "",
  min,
}) => {
  const valueCount = value?.length ? value?.length : 0;
  return (
    <>
      <div className="position-relative">
        <div
          className={` ${iserror ? "form-error-input" : "form-input"}`}
          style={{ opacity: disabled && !removeOpacity ? "0.7" : "1" }}
        >
          <input
            type={type}
            placeholder={placeholder}
            id={id}
            style={{ textTransform: textTransform ? textTransform : "" }}
            maxLength={maxLength}
            value={value}
            onChange={onChange}
            disabled={disabled}
            defaultValue={defaultValue}
            min={min}
            onBlur={() => handleBlur(fieldType, fieldName, value)}
          />
          <label htmlFor={id} style={{ fontWeight: "300" }}>
            {placeholder}
          </label>
        </div>
        {errorscope && iserror && (
          <p className="error-msg">
            <div className=" d-flex align-items-center gap-1">
              <FontAwesomeIcon
                icon="fa-solid fa-circle-exclamation "
                size="sm"
                style={{ color: "#e33716" }}
              />{" "}
              {errormessage ? errormessage : REQUIRED_ERR}
            </div>
            {/* {errormessage ? errormessage : REQUIRED_ERR} */}
          </p>
        )}
        <sub>{subText}</sub>
        {/* -------------------- below code used to show value count ------------------- */}
        {showValueCount && (
          <div
            className="fs-8 form-label-title "
            style={{ position: "absolute", top: "20px", right: "8px" }}
          >
            {valueCount}/{maxLength}
          </div>
        )}
      </div>
    </>
  );
};

export default InputField;

// added value count to by Suraj to showing value count/ max value count
