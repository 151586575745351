import { Get, Post, Put, Delete } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

// // get user by id
// export const GetUserById = () => {
// 	return new Promise((resolve, reject) => {
// 		Get(Site.ET, Endpoint.ET.Get_UserById, null)
// 			.then((response) => {
// 				resolve(response.data);
// 			})
// 			.catch((error) => {
// 				reject({ error: true, message: error.message });
// 			});
// 	});
// };
// Get User details
export const GetUserDetails = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.User_GetUserDetails, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Put Users Edit User
export const PutUserEditUser = (userPayloadData) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Post_User_EditUser, null, userPayloadData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Get User by division Id
export const GetUserByDivisionId = (divisionId) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_User_By_DivisionId,
      null,
      divisionId + "/users"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Get Users
export const GetUsers = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Users_Get_Users, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Put Update User Details

export const PutUpdateUserProfile = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Users_UpdateUserProfile, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Users - GetUserBasicDetails

export const GetValidateUserSignUp = (inviteId) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, `${Endpoint.ET.Users_ValidateUserSignUp}${inviteId}`, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({
          error: true,
          message: error.message,
          data: error?.response?.data,
        });
      });
  });
};

export const UpdateUserSignup = (userPayloadData) => {
  const customHeader = {
    "auth-token": "",
    token: userPayloadData?.[0]?.inviteId,
  };

  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Users_UpdateUserSignup,
      customHeader,
      userPayloadData?.[0]?.payload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Users_Send Reset Mail //

export const PostSendResetMail = (userPayloadData) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Users_SendResetMail, null, userPayloadData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

// Get validate reset password
export const GetValidateResetPassword = (reset_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Users_ValidateResetPassword,
      null,
      `reset?reset_id=${reset_id}`
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error?.response?.data);
      });
  });
};
// Put Change user password
export const PutUserChangeUserPassword = (payload) => {
  const customHeader = {
    "auth-token": "",
    token: payload?.token,
  };

  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.User_PutChangeUserPassword,
      customHeader,
      payload?.changeUserPasswordPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error?.response);
      });
  });
};

//Get Owners
export const GetOwners = (level_name, id) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Users_GetOwners, null, level_name + "/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Get user by level
export const GetUserByLevel = (level_name, level_id, team, status, type) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Users_GetUserByLevel,
      null,
      level_name +
        "/" +
        level_id +
        "/team/" +
        team +
        "/status/" +
        status +
        "/type/" +
        type
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Users_GetUserDetailsById

export const GetUserDetailsById = (userId) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Users_GetUserDetailsById, null, userId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Users - GetUserRoles (for creating users)

export const GetRolesBySystemId = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Roles_GetRolesBySystemId, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Users_UpdateUserStatus
export const UpdateUserStatus = (updateStatusPaylaod) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Users_UpdateUserStatus, null, updateStatusPaylaod)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Users - DeleteUser

export const DeleteUser = (userId) => {
  return new Promise((resolve, reject) => {
    Delete(Site.ET, Endpoint.ET.Users_DeleteUser, null, userId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Users- AddUser

export const PostAddUser = (addUserPayload) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Users_AddUser, null, addUserPayload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

// Users- UpdateUserDetails
export const UpdateUserDetails = (updateUserPayload) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Users_UpdateUserDetails, null, updateUserPayload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

// Users_ResendInvitation

export const ResendInvitation = (resendInvitationPayload) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Users_ResendInvitation,
      null,
      resendInvitationPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

// Put Update Mobile

export const PutUpdateMobile = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Users_UpdateMobile, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

// Users_GetUserRoles

export const GetUserRoles = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Users_GetUserRoles, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
