import React, { useLayoutEffect, useState, useEffect } from "react";
import styled from "@emotion/styled";
import "./sidebar.css";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import {
  ControlCenter,
  Properties,
  TabletLogo,
  // DesktopLogo
} from "../../assets/icons/icons";
import {
  Button as MUIButton,
  Menu as MUIMenu,
  MenuItem as MUIMenuItem,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { GetAllPropertiesList } from "../../services/Properties";
import { LogoutApi } from "../../services/LogoutApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { setFilter } from "../../store/PropertyId";
import { useSelector } from "react-redux";
import { useGetUserDetails } from "../../hooks/queries/useUserQueries";
import {
  useFavoritesData,
  usePropertyId,
  useShowPage,
} from "../../hooks/stores/usePropertyStore";
import { useUserDetails } from "../../hooks/stores/useUserDetailsStore";
import { useGetFavoriteProperties } from "../../hooks/queries/usePropertyQueries";
import DesktopLogo from "../../assets/images/App-Logo.png";
import { useShowCompanyPage } from "../../hooks/stores/useCompanyStore";
import { useBreadcrumb } from "../../Components/BreadcrumbContext ";
import { useRouting } from "../../hooks/stores/useRoutingStore";
import { useUnitType } from "../../hooks/stores/useUnitStore";
import { useOrganizationId } from "../../hooks/stores/useOrganizationStore";
import {
  useDivisionId,
  useDivisionName,
  useShowDivisionPage,
} from "../../hooks/stores/useDivisionsStore";
import { Image } from "react-bootstrap";
import {
  useGetDivisionByUserId,
  usePostAddDivisionSession,
} from "../../hooks/queries/useDivisionQueries";
import { ImageComponent, ShortNameComponent } from "../../widget/Widget";
import { GetSessionItem } from "../../utils/SessionStorage";

const StyledSidebarHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  background-color: #ffffff;
`;
const StyledSidebarFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(97, 145, 48, 0.08);
  width: 100%;
  overflow: hidden;
`;

const RecentProperties = ["Carolinelunden", "Brofæstet", "Kolding Sky"];

export const SidebarPanel = ({ Breadcrumbs }) => {
  //===================== States & constants ===================//
  const menuStyle = {
    height: 40,
  };

  const subMenuOptionStyle = {
    height: 30,
    backgroundColor: "#F9FAFA",
    paddingLeft: "65px",
    paddingRight: "20px",
    fontFamily: "Cerebri sans-medium",
  };

  const subMenuOptionForDivision = {
    height: 40,
    backgroundColor: "#FFF",
    paddingLeft: "14px",
    paddingRight: "20px",
    fontFamily: "Cerebri sans-light",
  };

  const navigate = useNavigate();
  const filter = useSelector((state) => state.propertyId.filter);
  const { updateBreadcrumbs } = useBreadcrumb();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [divisionAnchorEl, setDivisionAnchorEl] = useState(null);

  const responseBreakpoint = 768;
  const open = Boolean(anchorEl);
  const divisionOpen = Boolean(divisionAnchorEl);
  const [width, setWidth] = useState(0);
  const [expandCustFunnel, setExpandCustFunnel] = useState(true);
  const [expandSettings, setExpandSettings] = useState(true);
  const [expandRecentPropty, setExpandRecentPropty] = useState(true);
  const [expandFavPropty, setExpandFavPropty] = useState(true);
  const [expandDivName, setExpandDivName] = useState(true);
  // const [divisionName, setDivisionName] = useState("Division name");
  const { showPage, setShowPage } = useShowPage((state) => state);
  const [FavoriteProperties, setFavoriteProperties] = useState([]);
  const [hover, setHover] = useState(false);
  const [propertyId, setpropertyId] = useState("");
  const { setShowDivisionPage } = useShowDivisionPage((state) => state);
  //=================== Zustand ========================//
  const setPropertyId = usePropertyId((state) => state.setPropertyId);
  const {
    setSelectedSubTab,
    selectedSubTab,
    setSelectedSubTabType,
    selectedSubTabType,
  } = useRouting((state) => state);
  const { setTypeOfUnit } = useUnitType((state) => state);

  const { favoritePropertiesStoreData, setFavoritePropertiesStoreData } =
    useFavoritesData((state) => state);
  const { collapseSidebar, collapsed } = useProSidebar();
  const { setUserDetails } = useUserDetails((state) => state);
  const { showCompanyPage, setShowCompanyPage } = useShowCompanyPage(
    (state) => state
  );
  const setOrganizationId = useOrganizationId(
    (state) => state.setOrganizationId
  );
  const { setDivisionId, divisionId } = useDivisionId((state) => state);
  const { setDivisionName } = useDivisionName((state) => state);

  //====================== React query ==================//
  const { data: favouritePropertiesData, refetch: favouritePropertiesRefetch } =
    useGetFavoriteProperties("d", divisionId, "details");
  console.log("favouritePropertiesData", favouritePropertiesData);
  const { data: GetUserDetailsData } = useGetUserDetails();
  const {
    data: GetDivisionByUserIdData,
    isFetched: GetDivisionByUserIdDataFetched,
    remove: GetDivisionByUserIdDataRemove,
  } = useGetDivisionByUserId();
  const { mutateAsync: PostAddDivisionSession } = usePostAddDivisionSession();

  // useEffect(() => {
  // 	favouritePropertiesRefetch();
  // }, []);

  useEffect(() => {
    // favouritePropertiesRefetch();

    setFavoritePropertiesStoreData(
      favouritePropertiesData?.data
        ?.sort((a, b) => a?.propertyName?.localeCompare(b?.propertyName))
        ?.map((item, i) => item)
    );
  }, [favouritePropertiesData]);

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window?.innerWidth);
    }
    window?.addEventListener("resize", updateSize);
    updateSize();

    return () => window?.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    setUserDetails(GetUserDetailsData?.data?.[0]);
    setOrganizationId(GetUserDetailsData?.data?.[0]?.organisation_id);
  }, [GetUserDetailsData]);

  useEffect(() => {
    if (GetDivisionByUserIdData?.data) {
      //Check if division Id exist, if yes then update the division in side bar
      if (divisionId) {
        const divisionByUserData = GetDivisionByUserIdData?.data?.filter(
          (x) => x?.Id?.toString() === divisionId?.toString()
        )?.[0];
        setDivisionName(divisionByUserData?.Text);
        setDivisionId(divisionByUserData?.Id);
      } //If division Id does not exist then set the 1st division by default
      else {
        const divisionByUserData = GetDivisionByUserIdData?.data?.[0];

        setDivisionName(divisionByUserData?.Text);
        setDivisionId(divisionByUserData?.Id);
      }
    }

    return () => {};
  }, [GetDivisionByUserIdDataFetched, GetDivisionByUserIdData]);

  useEffect(() => {
    return () => {
      GetDivisionByUserIdDataRemove();

      setDivisionId("");
    };
  }, []);

  useEffect(() => {
    if (divisionId) {
      addDivisionSession(divisionId);
    }

    return () => {};
  }, [divisionId]);

  //================= Handle logout ==================//
  const logoutHandler = async () => {
    const response = await LogoutApi();
    if (response?.status === 204) {
      navigate("/");
      sessionStorage.clear();
    }
  };

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  const handleClick = (event) => {
    // console.log(event, "eeeeeeeeeeeeeeeeeeeee");
    setAnchorEl(event?.currentTarget);
  };
  // console.log(divisionAnchorEl, "divisionAnchorEl");
  const divisionHandleClick = (e) => {
    // console.log(e, "eeeeeee");
    setDivisionAnchorEl(e?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDivisionClose = () => {
    setDivisionAnchorEl(null);
  };

  const handlePropertyId = (e) => {
    setPropertyId(e);
    setTypeOfUnit(1);
    dispatch(setFilter(e));
    updateBreadcrumbs(["Residential"]);
    // addToRecentData(e);
  };

  // below code used to get and store recent data in local storage //
  // const [recentData, setRecentData] = useState([]);
  // console.log(recentData, "recentDatarecentData");
  // const MAX_RECENT_ITEMS = 3; // Maximum number of recent items to store

  // // Load recent data from local storage on component mount
  // useEffect(() => {
  //   const storedData = JSON.parse(localStorage.getItem("recentData")) || [];
  //   setRecentData(storedData);
  // }, []);

  // // Add a new item to the recent data
  // const addToRecentData = (newItem) => {
  //   const updatedData = [newItem, ...recentData.slice(0, MAX_RECENT_ITEMS - 1)];
  //   setRecentData(updatedData);
  //   localStorage.setItem("recentData", JSON.stringify(updatedData));
  // };

  const activeDivisionName = GetDivisionByUserIdData?.data?.filter(
    (x) => x?.Id?.toString() === divisionId?.toString()
  )?.[0]?.Text;

  //================== handle add division session ================//
  const addDivisionSession = async (division_id) => {
    const addDivisionSessionPayload = {
      user_login_log_id: GetSessionItem("user-log-id")?.toString(),
      division_id: division_id?.toString(),
    };

    try {
      const response = await PostAddDivisionSession(addDivisionSessionPayload);
    } catch (error) {
      console.error("Error adding division session:", error);
    }
  };

  //============== handle division click from sidebar ==================//
  const handleDivisionClick = (division_name, division_id) => {
    setDivisionName(division_name);
    setDivisionId(division_id);
  };

  return (
    <>
      <div
        style={{
          fontSize: "14px",
          // fontWeight: "500",
          // position: "fixed",
          overflowX: "hidden !important",
          position: "relative",
          display: "inline-block",
          height: "100vh",
          backgroundColor: "#f9fafa",
          borderRight: "1px solid #f0f0f0",
          // width:"369px"
        }}
      >
        <Sidebar
          defaultCollapsed={width <= responseBreakpoint}
          width="240px"
          collapsedWidth="90px"
        >
          <StyledSidebarHeader
            style={{
              backgroundColor: "#F9fafa",
              borderBottom: "1px solid #F0F0F0",
              width: "100%",
              height: "120px",
            }}
          >
            {collapsed ? (
              <>
                <div style={{ marginBottom: "25px" }}>
                  <TabletLogo />
                </div>
              </>
            ) : (
              <div
                className="d-flex justify-content-center"
                style={{ width: "250px" }}
              >
                {/* <DesktopLogo/> */}
                <img
                  src={DesktopLogo}
                  style={{ width: "125px", height: "auto" }}
                />
              </div>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
              onMouseOver={onHover}
              onMouseLeave={onLeave}
              className={
                collapsed ? "button-placement-close" : "button-placement-open"
              }
              onClick={() => collapseSidebar()}
            >
              <div
                className={
                  collapsed ? "sidebar-button-open" : "sidebar-button-close"
                }
              >
                <div className="sidebar-button-logo mt-1">
                  {collapsed ? (
                    <FontAwesomeIcon
                      icon="fa-regular fa-chevron-left"
                      rotation={180}
                      style={{ color: "#000000" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon="fa-regular fa-chevron-left"
                      style={{ color: "#000000" }}
                    />
                  )}
                </div>
              </div>
              <div>
                {hover ? (
                  <div
                    className="fs-13"
                    style={{
                      backgroundColor: "#FFFFFF",
                      width: "max-content",
                      maxHeight: "34px",
                      padding: "7px 12px",
                      border: "1px solid #f0f0f0",
                      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.12)",
                      borderRadius: "6px",
                      fontFamily: "Cerebri sans-light",
                    }}
                  >
                    {collapsed ? "Open navigation" : "Close navigation"}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </StyledSidebarHeader>
        </Sidebar>

        <Sidebar backgroundColor="#F9fafa" collapsedWidth="90px">
          <div
            style={{
              height: "calc(100vh - 120px - 60px - 60px )", //if division dropdown is uncommented minus 60 px other wise do not do any thing
              overflowY: "scroll",
              marginTop: "15px",
              position: "relative",
            }}
            className="scrollable-container-y"
          >
            <Menu style={menuStyle}>
              <MenuItem
                size="small"
                icon={collapsed && "General"}
                style={{ fontFamily: "Cerebri sans-bold" }}
              >
                {!collapsed && "General"}
              </MenuItem>
            </Menu>
            <Menu
              style={{
                borderBottom: "1px solid #F0F0F0",
                fontFamily: "Cerebri sans-medium",
              }}
            >
              <MenuItem
                component={<Link to="/control-center" />}
                icon={
                  <FontAwesomeIcon
                    size="lg"
                    icon="fa-regular fa-chart-line"
                    style={{ color: "#000000" }}
                  />
                }
                onClick={() => updateBreadcrumbs(["Overview"])}
                style={menuStyle}
              >
                Dashboard
              </MenuItem>

              {/* ------- organisation -------- */}
              <MenuItem
                component={<Link to="/organization" />}
                icon={
                  <FontAwesomeIcon
                    size="lg"
                    icon="fa-regular fa-sitemap"
                    style={{ color: "#000000" }}
                  />
                }
                style={menuStyle}
              >
                Organisation
              </MenuItem>

              {/* ----------- Divisions ------------ */}
              <MenuItem
                component={
                  <Link
                    to="/organization/divisions"
                    state={{ Tab: "Details" }}
                  />
                }
                icon={
                  <FontAwesomeIcon
                    size="lg"
                    icon="fa-regular fa-people-group"
                    style={{ color: "#000000" }}
                  />
                }
                style={menuStyle}
              >
                Divisions
              </MenuItem>

              {/* ------------ Companies ------------ */}
              <MenuItem
                component={<Link to="/division/Companies" />}
                icon={
                  <FontAwesomeIcon
                    size="lg"
                    icon="fa-regular fa-landmark"
                    style={{ color: "#000000" }}
                  />
                }
                style={menuStyle}
              >
                Companies
              </MenuItem>

              <MenuItem
                component={<Link to="properties" />}
                icon={
                  <FontAwesomeIcon
                    size="lg"
                    icon="fa-regular fa-house-building"
                    style={{ color: "#000000" }}
                  />
                }
                onClick={() => setShowPage("PL")}
                style={menuStyle}
              >
                Properties
              </MenuItem>

              {collapsed ? (
                <>
                  <Menu closeOnClick={true}>
                    <SubMenu
                      style={{ width: "max-content !important" }}
                      icon={
                        <FontAwesomeIcon
                          size="lg"
                          icon="fa-regular fa-filter-circle-dollar"
                          style={{ color: "#000000" }}
                        />
                      }
                    >
                      <MenuItem
                        style={{ fontFamily: "Cerebri sans-bold" }}
                        // component={<Link to="/customer-funnel/leads" />}
                      >
                        Leads
                      </MenuItem>
                      <MenuItem
                      // component={<Link to="/customer-funnel/opportunities" />}
                      >
                        Opportunities
                      </MenuItem>
                      <MenuItem
                      // component={<Link to="/customer-funnel/screenings" />}
                      >
                        Screenings
                      </MenuItem>
                      <MenuItem
                      // component={<Link to="/customer-funnel/signings" />}
                      >
                        Signings
                      </MenuItem>
                      <MenuItem
                        component={
                          <Link
                          // to="/customer-funnel/follow-up-later"
                          />
                        }
                      >
                        Follow up later
                      </MenuItem>
                    </SubMenu>
                  </Menu>
                </>
              ) : (
                <Menu
                  onClick={() => setExpandRecentPropty(!expandRecentPropty)}
                  renderExpandIcon={() => (
                    <FontAwesomeIcon
                      icon={
                        expandRecentPropty
                          ? "fa-solid fa-chevron-right"
                          : "fa-solid fa-chevron-down"
                      }
                      style={{ color: "#000000" }}
                      size="xs"
                    />
                  )}
                >
                  <SubMenu
                    style={{ backgroundColor: "#F9FAFA", height: 40 }}
                    label="Customer funnel"
                    icon={
                      <FontAwesomeIcon
                        size="lg"
                        icon="fa-regular fa-filter-circle-dollar"
                        style={{ color: "#000000" }}
                      />
                    }
                  >
                    <MenuItem
                      // component={<Link to="/customer-funnel/leads" />}
                      style={subMenuOptionStyle}
                    >
                      Leads
                    </MenuItem>
                    <MenuItem
                      // component={<Link to="/customer-funnel/opportunities" />}
                      style={subMenuOptionStyle}
                    >
                      Opportunities
                    </MenuItem>
                    <MenuItem
                      // component={<Link to="/customer-funnel/screenings" />}
                      style={subMenuOptionStyle}
                    >
                      Screenings
                    </MenuItem>
                    <MenuItem
                      // component={<Link to="/customer-funnel/signings" />}
                      style={subMenuOptionStyle}
                    >
                      Signings
                    </MenuItem>
                    <MenuItem
                      // component={<Link to="/customer-funnel/follow-up-later" />}
                      style={subMenuOptionStyle}
                    >
                      Follow up later
                    </MenuItem>
                  </SubMenu>
                </Menu>
              )}

              <MenuItem
                component={<Link to="/customers" />}
                icon={
                  <FontAwesomeIcon
                    icon="fa-regular fa-address-card"
                    size="lg"
                    style={{ color: "#000000" }}
                  />
                }
                onClick={() => updateBreadcrumbs(["Overview"])}
                style={menuStyle}
              >
                Customer
              </MenuItem>

              <MenuItem
                icon={
                  <FontAwesomeIcon
                    icon="fa-regular fa-inbox"
                    size="lg"
                    style={{ color: "#000000" }}
                  />
                }
                style={menuStyle}
              >
                My activities
              </MenuItem>
              <MenuItem
                icon={
                  <FontAwesomeIcon
                    icon="fa-regular fa-bell"
                    size="lg"
                    style={{ color: "#000000" }}
                  />
                }
                style={menuStyle}
              >
                Notifications
              </MenuItem>
            </Menu>

            {collapsed ? (
              <>
                <Menu style={{ borderBottom: "1px solid #F0F0F0" }}>
                  <MenuItem
                    icon="Recent"
                    style={{ fontWeight: "700" }}
                  ></MenuItem>
                </Menu>
                <Menu closeOnClick={true}>
                  <SubMenu
                    style={{ width: "max-content !important" }}
                    icon={<Properties text={RecentProperties?.length} />}
                  >
                    <MenuItem style={{ fontFamily: "Cerebri sans-bold" }}>
                      Recent Properties
                    </MenuItem>
                    {/* {RecentProperties?.map((item, i) => {
                      return (
                        <MenuItem
                          key={i}
                          style={{
                            padding: "24px",
                            fontFamily: "Cerebri sans-medium",
                          }}
                          icon={<Properties />}
                        >
                          {item}
                        </MenuItem>
                      ); */}
                    {/* })} */}
                  </SubMenu>
                </Menu>
              </>
            ) : (
              <Menu
                onClick={() => setExpandRecentPropty(!expandRecentPropty)}
                renderExpandIcon={() => (
                  <FontAwesomeIcon
                    icon={
                      expandRecentPropty
                        ? "fa-solid fa-chevron-right"
                        : "fa-solid fa-chevron-down"
                    }
                    style={{ color: "#000000" }}
                    size="xs"
                  />
                )}
                style={{ borderBottom: "1px solid #F0F0F0" }}
              >
                <SubMenu
                  style={{ fontFamily: "Cerebri sans-bold" }}
                  label="Recent properties"
                >
                  {/* {RecentProperties?.map((item, i) => {
                    return (
                      <MenuItem
                        key={i}
                        style={{
                          padding: "20px",
                          backgroundColor: "#F9fafa",
                          fontFamily: "Cerebri sans-medium",
                        }}
                        icon={<Properties />}
                      >
                        {item}
                      </MenuItem>
                    );
                  })} */}
                </SubMenu>
              </Menu>
            )}

            {collapsed ? (
              <>
                <Menu>
                  <MenuItem
                    icon="Favorite"
                    style={{ fontFamily: "Cerebri sans-bold" }}
                  ></MenuItem>
                </Menu>
                <Menu
                // style={{ borderBottom: "1px solid #F0F0F0" }}
                >
                  <SubMenu
                    className="scroll"
                    icon={
                      <FontAwesomeIcon
                        icon="fa-solid fa-star"
                        style={{ color: "#f6e651" }}
                      />
                    }
                    style={{
                      maxWidth: "max-content !important",
                      // width: "300px",
                    }}
                  >
                    <MenuItem
                      style={{
                        fontFamily: "Cerebri sans-bold",
                        width: "250px",
                      }}
                    >
                      Favorite properties
                    </MenuItem>

                    {favoritePropertiesStoreData?.map((item, i) => {
                      return (
                        <SubMenu
                          key={i}
                          style={{
                            fontFamily: "Cerebri sans-medium",
                          }}
                          icon={
                            <FontAwesomeIcon
                              icon="fa-solid fa-star"
                              style={{ color: "#f6e651" }}
                            />
                          }
                          label={
                            item?.property_name?.length > 22
                              ? `${item?.property_name.substring(0, 22)}...`
                              : item?.property_name
                          }
                        >
                          <MenuItem
                            component={
                              <Link
                                to="property-units/overview"
                                state={{
                                  propId: item?.property_id,
                                  Tab: "Overview",
                                }}
                              />
                            }
                            style={{
                              fontFamily: "Cerebri sans-light",
                              marginLeft: "25px",
                            }}
                            onClick={() => {
                              // updateBreadcrumbs(['Staus']);
                              handlePropertyId(item?.property_id);
                            }}
                          >
                            Overview
                          </MenuItem>

                          <MenuItem
                            component={
                              <Link
                                to="property-units/units/residential"
                                state={{
                                  propId: item?.property_id,
                                  Tab: "Units",
                                }}
                              />
                            }
                            style={{
                              fontFamily: "Cerebri sans-light",
                              marginLeft: "25px",
                            }}
                            onClick={() => {
                              // updateBreadcrumbs(['Residential']);
                              handlePropertyId(item?.property_id);
                            }}
                          >
                            Units
                          </MenuItem>
                          {/* This is EI.DO tabs plz don't touch it necessary */}
                          {/* <MenuItem
                            component={
                              <Link
                                to="eido/color"
                                state={{
                                  propId: item?.property_id,
                                  Tab: "Eido",
                                }}
                              />
                            }
                            style={{ fontFamily: "Cerebri sans-light" }}
                            onClick={() => handlePropertyId(item?.property_id)}
                          >
                            Units
                          </MenuItem> */}
                        </SubMenu>
                      );
                    })}
                  </SubMenu>
                </Menu>
              </>
            ) : (
              <>
                <Menu
                  style={{
                    // borderBottom: "1px solid #F0F0F0",
                    paddingBottom: "20px",
                  }}
                  onClick={() => setExpandFavPropty(!expandFavPropty)}
                  renderExpandIcon={() => (
                    <FontAwesomeIcon
                      icon={
                        expandFavPropty
                          ? "fa-solid fa-chevron-right"
                          : "fa-solid fa-chevron-down"
                      }
                      style={{ color: "#000000" }}
                      size="xs"
                    />
                  )}
                >
                  <SubMenu
                    icon={collapsed ? "Favorite" : ""}
                    style={{
                      fontFamily: "Cerebri sans-bold",
                      backgroundColor: "#f9fafa",
                    }}
                    label="Favorite properties"
                  >
                    {favoritePropertiesStoreData?.map((item, i) => {
                      return (
                        <SubMenu
                          key={i}
                          style={{
                            padding: "0 20px",
                            backgroundColor: "#F9fafa",
                            fontFamily: "Cerebri sans-medium",
                          }}
                          icon={
                            <FontAwesomeIcon
                              icon="fa-solid fa-star"
                              style={{ color: "#f6e651" }}
                            />
                          }
                          label={item?.property_name}
                        >
                          <MenuItem
                            component={
                              <Link
                                to="property-units/overview"
                                state={{
                                  propId: item?.property_id,
                                  Tab: "Overview",
                                }}
                              />
                            }
                            style={{
                              fontWeight: "300",
                              backgroundColor: "#F9fafa",
                              paddingLeft: "60px",
                              paddingRight: "20px",
                              fontFamily: "Cerebri sans-light",
                            }}
                            className="ps-1"
                            onClick={() => handlePropertyId(item.property_id)}
                          >
                            Overview
                          </MenuItem>

                          <MenuItem
                            component={
                              <Link
                                to="property-units/units/residential"
                                state={{
                                  propId: item?.property_id,
                                  Tab: "Units",
                                }}
                              />
                            }
                            style={{
                              fontWeight: "300",
                              backgroundColor: "#F9fafa",
                              paddingLeft: "60px",
                              paddingRight: "20px",
                              fontFamily: "Cerebri sans-light",
                            }}
                            className="ps-1"
                            onClick={() => handlePropertyId(item.property_id)}
                          >
                            Units
                          </MenuItem>
                          {/* This is EI.DO tabs plz don't touch it and Call Akhil for any require changes */}
                          {/* <MenuItem
														component={
															<Link
																to="eido/color"
																state={{
																	propId: item?.property_id,
																	Tab: "Eido",
																}}
															/>
														}
														style={{
															fontWeight: "300",
															backgroundColor: "#F9fafa",
															paddingLeft: "60px",
															paddingRight: "20px",
															fontFamily: "Cerebri sans-light",
														}}
														className="ps-1"
														onClick={() => handlePropertyId(item?.property_id)}>
														Ei.do
													</MenuItem> */}
                        </SubMenu>
                      );
                    })}
                  </SubMenu>
                </Menu>
              </>
            )}
          </div>
        </Sidebar>

        {/* division drop down section */}
        <Sidebar
          defaultCollapsed={width <= responseBreakpoint}
          width="240px"
          collapsedWidth="90px"
          style={{
            marginBottom: "4px",
          }}
        >
          <StyledSidebarFooter>
            {collapsed ? (
              <>
                <Menu
                  closeOnClick={true}
                  menuItemStyles={{
                    button: {
                      background: "#f0f0f0",
                      "&:hover": {
                        background: "#F0F0F0",
                      },
                    },
                  }}
                >
                  <SubMenu
                    icon={
                      <FontAwesomeIcon
                        icon="fa-regular fa-sitemap"
                        style={{ color: "#000000" }}
                        size="lg"
                      />
                    }
                  >
                    <div
                      style={{
                        width: "190px ",
                        height: "240px ",
                        overflow: "scroll",
                      }}
                    >
                      {GetDivisionByUserIdData?.data?.map((val, i, arr) => (
                        <>
                          <MenuItem
                            key={val?.Id}
                            style={subMenuOptionForDivision}
                            onClick={() => {
                              handleDivisionClick(val?.Text, val?.Id);
                            }}
                          >
                            <div className="d-flex">
                              {val?.DataValue3 !== "" ? (
                                <Image
                                  rounded
                                  src={val?.DataValue3}
                                  width={20}
                                  height={20}
                                />
                              ) : (
                                <>
                                  {val?.DataValue2?.map((data, i) => (
                                    <div
                                      className="radius-4 light-font-text d-flex align-items-center justify-content-center fs-10"
                                      key={i}
                                      style={{
                                        height: "24px",
                                        width: "24px",
                                        color: data?.shortname_text_color,
                                        backgroundColor:
                                          data?.shortname_background_color,
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      {data?.shortname}
                                    </div>
                                  ))}
                                </>
                              )}
                              <span className="ps-3 light-font-text">
                                {val?.Text}
                              </span>
                            </div>
                          </MenuItem>
                          {i !== arr.length - 1 && <hr className="hr m-0" />}
                        </>
                      ))}
                    </div>
                  </SubMenu>
                </Menu>
              </>
            ) : (
              <Menu
                style={{
                  height: 50,
                  width: "100%",
                  backgroundColor: "#F0F0F0",
                }}
                menuItemStyles={{
                  button: {
                    width: "100%",
                    textAlign: "left",
                    "&:hover": {
                      backgroundColor: "#F0F0F0",
                    },
                  },
                }}
                onClick={() => {
                  setExpandDivName(!expandDivName);
                }}
              >
                <MUIButton
                  id="demo-positioned-button"
                  aria-controls={
                    divisionOpen ? "demo-positioned-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={divisionOpen ? "true" : undefined}
                  onClick={divisionHandleClick}
                  fullWidth
                  sx={{
                    color: "#000",
                    width: "100%",
                    float: "left",
                    height: 50,
                    textTransform: "inherit",
                    "&:hover": {
                      backgroundColor: "#F0F0F0",
                    },
                  }}
                >
                  <MenuItem
                    icon={
                      <FontAwesomeIcon
                        icon="fa-regular fa-sitemap"
                        style={{ color: "#000000" }}
                        size="lg"
                      />
                    }
                    style={{
                      paddingLeft: "14px",
                      float: "left",
                      fontFamily: "Cerebri sans-medium",
                      fontSize: "14px",
                    }}
                  >
                    {
                      GetDivisionByUserIdData?.data?.filter(
                        (x) => x?.Id?.toString() === divisionId?.toString()
                      )?.[0]?.Text
                    }
                  </MenuItem>
                  <FontAwesomeIcon
                    icon={
                      expandDivName
                        ? "fa-solid fa-chevron-right"
                        : "fa-solid fa-chevron-down"
                    }
                    style={{ color: "#000000", paddingRight: "22px" }}
                    size="xs"
                  />
                </MUIButton>
                <MUIMenu
                  sx={{
                    width: "300px !important",
                    height: "350px !important",
                    padding: "12px 16px",
                    marginLeft: "15px",
                    "& .MuiPaper-root": {
                      border: "1px solid #F0F0F0",
                      borderRadius: "8px",
                    },
                    "& .MuiMenuItem-root:hover": {
                      background: "none",
                    },
                  }}
                  elevation={0}
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={divisionAnchorEl}
                  open={divisionOpen}
                  onClose={handleDivisionClose}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                >
                  {GetDivisionByUserIdData?.data?.map((data, i, arr) => (
                    <>
                      <MUIMenuItem
                        key={data?.Id}
                        onClick={() => {
                          handleDivisionClick(data?.Text, data?.Id);
                          setDivisionAnchorEl(null);
                        }}
                        style={{ padding: "0px 16px", height: "40px" }}
                      >
                        <>
                          <div className="d-flex">
                            <ImageComponent
                              imageUrl={data?.DataValue3}
                              altText={"division image"}
                              borderRadius="4px"
                              height="24px"
                              width="24px"
                              shortNameComponent={
                                <ShortNameComponent
                                  height="24px"
                                  width="24px"
                                  fontSize="10px"
                                  borderRadius="4px"
                                  backgroundColor={
                                    data?.DataValue2?.[0]
                                      ?.shortname_background_color
                                  }
                                  fontColor={
                                    data?.DataValue2?.[0]?.shortname_text_color
                                  }
                                  shortNameText={
                                    data?.DataValue2?.[0]?.shortname
                                  }
                                />
                              }
                            />

                            <span className="ps-3 light-font-text">
                              {data?.Text}
                            </span>
                          </div>
                        </>
                      </MUIMenuItem>
                      {i !== arr.length - 1 && <hr className="hr m-0" />}
                    </>
                  ))}
                </MUIMenu>
              </Menu>
            )}
          </StyledSidebarFooter>
        </Sidebar>

        {/* user profile */}
        <Sidebar
          defaultCollapsed={width <= responseBreakpoint}
          width="240px"
          collapsedWidth="90px"
        >
          <StyledSidebarFooter>
            {collapsed ? (
              <Menu
                closeOnClick={true}
                menuItemStyles={{
                  button: {
                    background: "#f0f0f0",
                    "&:hover": {
                      background: "#f0f0f0",
                    },
                  },
                }}
              >
                <SubMenu
                  icon={
                    <>
                      {GetUserDetailsData?.data?.[0]?.image_url ? (
                        <img
                          src={GetUserDetailsData?.data?.[0]?.image_url}
                          style={{
                            height: "21px",
                            width: "21px",
                            borderRadius: "100%",
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon="fa-regular fa-circle-user"
                          size="lg"
                          style={{ color: "#000000" }}
                        />
                      )}
                    </>
                  }
                >
                  <MenuItem
                    style={{
                      padding: "0",
                      height: "max-content",
                      background: "#ffffff",
                    }}
                  >
                    <div
                      style={{
                        lineHeight: "20px",
                        padding: "24px 20px 24px 20px",
                      }}
                    >
                      <div style={{ fontWeight: "300" }}>
                        {GetUserDetailsData?.data?.[0]?.first_name}
                      </div>
                      <div style={{ marginBottom: "20px" }}>
                        {GetUserDetailsData?.data?.[0]?.organisation_name}{" "}
                        {/* |&nbsp;
                        {activeDivisionName} */}
                      </div>
                      <Link
                        to="/user-profile"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <div style={{ marginBottom: "10px" }}>
                          <>
                            {GetUserDetailsData?.data?.[0]?.image_url ? (
                              <img
                                src={GetUserDetailsData?.data?.[0]?.image_url}
                                style={{
                                  height: "14px",
                                  width: "14px",
                                  borderRadius: "100%",
                                }}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon="fa-regular fa-circle-user"
                                size="lg"
                                style={{ color: "#000000" }}
                              />
                            )}
                          </>
                          <span className="ms-1 light-font-text">
                            {" "}
                            My profile
                          </span>
                        </div>
                      </Link>

                      <div>
                        <button
                          className="ps-0"
                          onClick={logoutHandler}
                          style={{ border: "none", background: "white" }}
                        >
                          <FontAwesomeIcon
                            icon="fa-regular fa-arrow-right-from-bracket"
                            style={{ color: "#00040a" }}
                          />{" "}
                          <span className="ps-2 light-font-text"> Log out</span>
                        </button>
                      </div>
                    </div>
                  </MenuItem>
                </SubMenu>
              </Menu>
            ) : (
              <Menu
                style={{
                  height: 50,
                  width: "100%",
                  backgroundColor: "#F0F0F0",
                }}
                menuItemStyles={{
                  button: {
                    width: "100%",
                    textAlign: "left",
                    "&:hover": {
                      backgroundColor: "#F0F0F0",
                    },
                  },
                }}
              >
                <MUIButton
                  id="demo-positioned-button"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  fullWidth
                  sx={{
                    color: "#000",
                    width: "100%",
                    float: "left",
                    height: 50,
                    "&:hover": {
                      backgroundColor: "#F0F0F0",
                    },
                  }}
                >
                  <MenuItem
                    icon={
                      <>
                        {GetUserDetailsData?.data?.[0]?.image_url ? (
                          <img
                            src={GetUserDetailsData?.data?.[0]?.image_url}
                            style={{
                              height: "21px",
                              width: "21px",
                              borderRadius: "100%",
                            }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon="fa-regular fa-circle-user"
                            size="lg"
                            style={{ color: "#000000" }}
                          />
                        )}
                      </>
                    }
                    style={{
                      paddingLeft: "14px",
                      fontFamily: "Cerebri sans-medium",
                      float: "left",
                    }}
                  >
                    {GetUserDetailsData?.data?.[0]?.first_name}
                  </MenuItem>
                </MUIButton>

                <MUIMenu
                  sx={{
                    marginLeft: "15px",
                    "& .MuiPaper-root": {
                      border: "1px solid #F0F0F0",
                      borderRadius: "8px",
                    },
                    "& .MuiMenuItem-root:hover": {
                      background: "none",
                    },
                  }}
                  elevation={0}
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                >
                  <MUIMenuItem onClick={handleClose}>
                    <div
                      style={{
                        lineHeight: "20px",
                        backgroundColor: "#FFFFFF",
                        padding: "10px 4px 10px 4px",
                      }}
                    >
                      <div className="light-font-text">
                        {GetUserDetailsData?.data?.[0]?.first_name}
                      </div>
                      <div
                        className="light-font-text"
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        {GetUserDetailsData?.data?.[0]?.organisation_name}
                        {/* |&nbsp;
                        {activeDivisionName} */}
                      </div>

                      <Link
                        to="/user-profile"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <div
                          className="light-font-text"
                          style={{
                            marginBottom: "10px",
                          }}
                        >
                          <>
                            {GetUserDetailsData?.data?.[0]?.image_url ? (
                              <img
                                src={GetUserDetailsData?.data?.[0]?.image_url}
                                style={{
                                  height: "14px",
                                  width: "14px",
                                  borderRadius: "100%",
                                }}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon="fa-regular fa-circle-user"
                                size="lg"
                                style={{ color: "#000000" }}
                              />
                            )}
                          </>
                          <span className="ms-1">My profile</span>
                        </div>
                      </Link>

                      <div className="light-font-text">
                        <button
                          className="p-0"
                          onClick={logoutHandler}
                          style={{ border: "none", background: "white" }}
                        >
                          <FontAwesomeIcon
                            icon="fa-regular fa-arrow-right-from-bracket"
                            style={{ color: "#00040a" }}
                          />
                          <span className="ms-2">Log out</span>
                        </button>
                      </div>
                    </div>
                  </MUIMenuItem>
                </MUIMenu>
              </Menu>
            )}
          </StyledSidebarFooter>
        </Sidebar>
      </div>
    </>
  );
};
