import {
  useMutation,
  useQuery,
  QueryClient,
  useQueryClient,
} from "react-query";
import {
  GetUsers,
  PostSendResetMail,
  PutUserEditUser,
  GetUserByDivisionId,
  GetValidateUserSignUp,
  UpdateUserSignup,
  GetValidateResetPassword,
  GetUserDetails,
  PutUserChangeUserPassword,
  PutUpdateUserProfile,
  GetOwners,
  GetUserByLevel,
  GetUserDetailsById,
  GetUserRoles,
  UpdateUserStatus,
  DeleteUser,
  PostAddUser,
  UpdateUserDetails,
  ResendInvitation,
  PutUpdateMobile,
  GetRolesBySystemId,
} from "../../services/User";

//Get user details
export const useGetUserDetails = () => {
  return useQuery(["qGetUserDetails"], () => GetUserDetails(), {
    refetchOnWindowFocus: false,
  });
};

export const useGetUserByDivisionId = (divisionId) => {
  return useQuery(
    ["qGetUserByDivisionId", divisionId],
    () => GetUserByDivisionId(divisionId),
    {
      refetchOnWindowFocus: false,
      enabled: !!divisionId,
    }
  );
};
export const useGetUsers = () => {
  return useQuery("qGetUsers", () => GetUsers());
};

////Edit User Details///

export const usePutUserEditUser = () => {
  // const queryClient = useQueryClient();
  return useMutation(PutUserEditUser);
};

//Put Update User Profile
export const usePutUpdateUserProfile = () => {
  const queryClient = useQueryClient();
  return useMutation(PutUpdateUserProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries("qGetUserDetails");
    },
  });
};
// get user basic details

export const useValidateUserSignUp = (invite_id) => {
  return useQuery("qGetUserBasicDetails", () =>
    GetValidateUserSignUp(invite_id)
  );
};

// update user details

export const useUpdateUserSignup = () => {
  return useMutation(UpdateUserSignup);
};
// Get Validate Reset Password //
export const useGetValidateResetPassword = (reset_id) => {
  return useQuery(
    ["qGetValidateResetPassword", reset_id],
    () => GetValidateResetPassword(reset_id),
    { enabled: !!reset_id, retry: false }
  );
};

// Users- Send Reset Mail //
export const usePostSendResetMail = () => {
  return useMutation(PostSendResetMail);
};
// Users- Change user password //
export const usePutChangeUserPassword = () => {
  return useMutation(PutUserChangeUserPassword);
};
export const useGetOwners = (level_name, id) => {
  return useQuery(
    ["qGetOwners", level_name, id],
    () => GetOwners(level_name, id),
    {
      refetchOnWindowFocus: false,
      enabled: !!level_name,
    },
    {
      enabled: !!id,
    }
  );
};

// Get user by level
export const useGetUserByLevel = (level_name, level_id, team, status, type) => {
  return useQuery(
    ["qGetUserByLevel", level_name, level_id, team, status, type],
    () => GetUserByLevel(level_name, level_id, team, status, type),
    {
      enabled: !!level_name && !!level_id && !!team && !!status && !!type,
    }
    // {
    //   enabled: !!level_id,
    // },
    // {
    //   enabled: !!team,
    // },
    // {
    //   enabled: !!status,
    // },
    // {
    //   enabled: !!type,
    // }
  );
};

export const useGetUserDetailsById = (userId) => {
  return useQuery(
    ["qGetUserDetailsById", userId],
    () => GetUserDetailsById(userId),

    {
      enabled: !!userId,
    }
  );
};

export const useGetRolesBySystemId = () => {
  return useQuery(["qGetUserRoles"], () => GetRolesBySystemId());
};

export const useUpdateUserStatus = () => {
  // const queryClient = useQueryClient();

  return useMutation(UpdateUserStatus);
};

export const useDeleteUser = () => {
  // const queryClient = useQueryClient();

  return useMutation(DeleteUser);
};

export const usePostAddUser = () => {
  // const queryClient = useQueryClient();

  return useMutation(PostAddUser);
};

export const useUpdateUserDetails = () => {
  // const queryClient = useQueryClient();

  return useMutation(UpdateUserDetails);
};

export const useResendInvitation = () => {
  // const queryClient = useQueryClient();

  return useMutation(ResendInvitation);
};

// Put Update Mobile
export const usePutUpdateMobile = () => {
  return useMutation(PutUpdateMobile);
};

// Get user roles

export const useGetUserRoles = () => {
  return useQuery(["qGetUserRoles"], () => GetUserRoles());
};
