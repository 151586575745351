import React, { useEffect, useMemo, useState } from "react";
import { CircleMsg, H3, H4, H5 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import { Col, Row } from "react-bootstrap";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import Menus from "../../../../Components/Menus/Menus";
import { MenuItem } from "@mui/material";
import {
  SearchIcon,
  SearchStyle,
  paginationoptions,
  SortIcon,
} from "../../../../Components/table/MRTGlobalProps";
import MRTTheme from "../../../../Components/table/MRTTheme";
import CustomPopup from "../../../../Components/CustomPopup";
import UserInformation from "./usersComponents/UserInformation";
import ETButton from "../../../../Components/Buttons/ETButton";
import moment from "moment";
import ShowTooltip from "../../../../Components/Tooltip/ShowTooltip";
import { ActionsPopup } from "../../../customerFunnel/screenings/popups/ActionsPopup";
import { useUpdateUserDetails } from "../../../../hooks/queries/useUserQueries";
import { toast } from "react-toastify";
import {
  ErrorAlert,
  SucessAlert,
} from "../../../../Components/alerts/AlertsAndMessages";
import { useOrganizationId } from "../../../../hooks/stores/useOrganizationStore";
import { status } from "../../../../model/Constant";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";
import { UTCtoLocalTimeZone } from "../../../../utils/UTCtoLocalTimeZone";

const PendingUsers = ({
  pendingUsersData,
  GetPendingUsersDataRefetch = () => {},
  userDetailsByIdDataRemove = () => {},
  GetDivisionByOrganisationIdData,
  userDetailsByIdData,
  userRolesData,
  GetTeamsByLevelData,
  GetLanguagesData,
  userId,
  setUserId,
  teamId,
  setTeamId,
  usedFor,
  setUsedFor,
  handleUpdateUser,
  handleArchiveUser,
  handleResendInvitation,
  croppedImage,
  setCroppedImage,
  imageName,
  setImageName,
  uploadImgFile,
  setUploadImgFile,
  userDetailsbyIdDataRefetch,
  newUserId,
  setNewUserId,
}) => {
  // ----useState
  const [editNewUserPopup, setEditNewUserPopup] = useState(false);
  const [deleteUserPopup, setDeleteUserPopup] = useState(false);
  const [sorting, setSorting] = useState([]);
  const [resendInvitationPopup, setResendInvitationPopup] = useState(false);
  const [invitaionEmail, setInvitationEmail] = useState("");
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [userRolesOptions, setUserRoelsOptions] = useState([]);

  // -----zustand
  const { organizationId } = useOrganizationId((state) => state);
  // ------react-query
  const { mutateAsync: UpdateUserDetailsMutate } = useUpdateUserDetails();
  const userDetailsData = userDetailsByIdData?.data;

  useEffect(() => {
    const fetchImageAndSetFile = async () => {
      try {
        const response = await fetch(croppedImage);
        const blob = await response.blob();
        const file = new File([blob], `${userDetailsData?.[0]?.first_name}`, {
          type: blob.type,
        });
        setUploadImgFile(croppedImage ? file : "");
      } catch (error) {
        setUploadImgFile(error);
      }
    };

    fetchImageAndSetFile();
  }, [croppedImage, userDetailsData?.[0]?.first_name]);

  const { userDetails } = useUserDetails((state) => state);

  const handleEditUser = async (data) => {
    const getTeamIds = (teams) => {
      if (teams === "" || teams === undefined || teams === null) {
        // console.log("condition 1");

        return [];
      } else if (teams?.length === 1) {
        // console.log("condition 2");
        // console.log("teams", teams);
        return [teams?.[0]?.value?.toString()];
      } else if (teams?.length > 1) {
        // console.log("condition 3");

        const teamIds = teams?.map((team) => team?.value);
        return teamIds;
      }
    };
    const transformedUserRole = data?.userRoles?.map((item) => ({
      division_id: item?.division?.value?.toString(),
      role_id: item?.user_role?.value,
      user_team_id: getTeamIds(item?.teams),
    }));
    let formData = new FormData();
    formData?.append("user_id", userId);
    formData.append("first_name", data?.firstName);
    formData.append("last_name", data?.lastName);
    formData.append("designation", data?.title);
    formData.append("language_id", data?.language?.value);
    formData.append("isd_code", data?.isd_code);
    formData.append("contact_no", data?.phone);
    formData.append("company_name", data?.company);
    formData.append("work_area_id", 0);
    formData.append("profile_image_filename", uploadImgFile?.name);
    formData.append("profile_image_file", uploadImgFile);

    formData.append("system_id", "ETM");
    formData.append("roles", JSON.stringify(transformedUserRole));
    formData.append("new_user_id", 0);

    try {
      const response = await UpdateUserDetailsMutate(formData);
      if (response?.status === status?.s && response?.data?.bReturn) {
        toast.success(<SucessAlert />);
        GetPendingUsersDataRefetch();
        userDetailsbyIdDataRefetch();
        setCroppedImage("");
      } else {
        toast.error(<ErrorAlert />);
      }
    } catch (error) {}
  };

  const handleCommaSeparatedRoles = (row, type) => {
    let splittedRole;

    if (type === "roles") {
      splittedRole = row?.original?.roles?.split(",");
    } else if (type === "divisions") {
      splittedRole = row?.original?.division_details?.map((detail, index) => {
        return detail?.division_name;
      });
    }

    return (
      <ShowTooltip
        tooltopWidth={450}
        arrow={false}
        padding="0px"
        tooltipLabel={
          <div>
            <span>
              {splittedRole?.[0]?.length > 11
                ? splittedRole?.[0]?.slice(0, 11) + "..."
                : splittedRole?.[0]}
            </span>
            {splittedRole?.length > 1 && (
              <CircleMsg>
                <span>{splittedRole?.length}</span>
              </CircleMsg>
            )}
          </div>
        }
        tooltipContent={
          <div style={{ width: "450px" }}>
            <div className="form-label-title py-3 px-4">
              User roles for {row?.original?.first_name}{" "}
              {row?.original?.last_name}
            </div>

            <hr className="m-0"></hr>
            <div
              className="p-4"
              style={{ height: "200px", overflowY: "scroll" }}
            >
              <Row style={{ paddingBottom: "11px" }}>
                <Col lg={5}>
                  <div className="form-label-title">Role Type</div>
                </Col>
                <Col>
                  <div className="form-label-title">Division</div>
                </Col>
                <Col>
                  <div className="form-label-title">Joined</div>
                </Col>
              </Row>

              {row?.original?.division_details?.map((divisionDetail) => {
                return (
                  <Row
                    key={divisionDetail?.division_id}
                    style={{ paddingBottom: "11px" }}
                  >
                    <Col lg={5}>
                      <div className="light-font-text">
                        {divisionDetail?.role_name}
                      </div>
                    </Col>
                    <Col>
                      <div className="light-font-text">
                        {divisionDetail?.division_name}
                      </div>
                    </Col>
                    <Col>
                      <div className="light-font-text">
                        {divisionDetail?.assigned_on
                          ? moment(
                              divisionDetail?.assigned_on,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("DD MMM, YYYY")
                          : "-"}
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </div>
        }
      />
    );
  };

  useEffect(() => {
    const divisions = GetDivisionByOrganisationIdData?.data?.map(
      (division, index) => {
        return {
          value: division?.division_id,
          label: division?.name,
        };
      }
    );
    setDivisionOptions(divisions);
  }, [GetDivisionByOrganisationIdData]);

  useEffect(() => {
    const userRoels = userRolesData?.data?.map((role, index) => {
      return {
        value: role?.Id,
        label: role?.Text,
      };
    });
    setUserRoelsOptions(userRoels);
  }, []);
  const assignedToTeamsOptions = GetTeamsByLevelData?.data?.map(
    (team, index) => {
      return {
        value: team?.user_team_id,
        label: team?.team_name,
      };
    }
  );
  const languageOptions = GetLanguagesData?.data?.map((language) => {
    return {
      value: language?.Id,
      label: language?.Text,
    };
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "user_no",
        header: "No.",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: true,
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "16px !important",
          },
        },
        Cell: ({ row }) => (
          <div>{row.original?.user_no ? row.original?.user_no : "-"}</div>
        ),
      },
      {
        accessorKey: "first_name",
        header: "First name",
        size: 50,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div>{row.original?.first_name ? row.original?.first_name : "-"}</div>
        ),
      },
      {
        accessorKey: "last_name",
        header: "Last name",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div>{row.original?.last_name ? row.original?.last_name : "-"}</div>
        ),
      },
      {
        accessorKey: "roles",
        header: "Roles",
        enableHiding: false,
        size: 150,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div>
            {row?.original?.roles?.includes(",")
              ? handleCommaSeparatedRoles(row, "roles")
              : row?.original?.roles
              ? row?.original?.roles
              : "-"}
          </div>
        ),
      },
      {
        accessorFn: (row) => `${row?.division_details}`,
        header: "Divisions",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,
        Cell: ({ row }) => handleCommaSeparatedRoles(row, "divisions"),
      },
      {
        accessorKey: "invited_on",
        header: "Invitation sent",
        enableHiding: false,
        size: 200,
        enableGlobalFilter: false,
        Cell: ({ row }) => {
          const { convertedDate: invited_on } = UTCtoLocalTimeZone({
            timestamp: row?.original?.invited_on,
            offset_mins: useUserDetails?.timezone_offset_mins,
            timeFormat: "DD MMM, YYYY [at] HH:mm:ss",
          }); // Get current Time zone

          return (
            <div className="light-font-text d-flex gap-2">{invited_on}</div>
          );
        },
      },
      {
        accessorKey: "reinvited_on",
        header: "Reminder sent",
        enableHiding: false,
        size: 200,
        enableGlobalFilter: false,
        Cell: ({ row }) => {
          const { convertedDate: reinvited_on } = UTCtoLocalTimeZone({
            timestamp: row?.original?.reinvited_on,
            offset_mins: useUserDetails?.timezone_offset_mins,
            timeFormat: "DD MMM, YYYY [at] HH:mm:ss",
          }); // Get current Time zone

          return (
            <div className="light-font-text d-flex gap-2">{reinvited_on}</div>
          );
        },
      },
    ],
    []
  );

  const renderEmptyRowsFallback = () => {
    return (
      <div
        className="d-flex justify-content-center w-100 grey-text py-4 radius-4"
        style={{ border: "1px solid #d1d1d1", backgroundColor: "#F9FAFA" }}
      >
        <>
          <div className="fs-14">Sorry! No result found. Please try again!</div>
        </>
      </div>
    );
  };
  return (
    <div className="pb-2 pt-2 px-4">
      {" "}
      <MRTTheme
        colorVariantsForTableRow={["gray"]}
        colorVariantsForTableHead={["white"]}
        table={
          <MaterialReactTable
            columns={columns?.map((columns) => {
              return {
                ...columns,
                sortDescFirst: false,
              };
            })}
            data={pendingUsersData || []} //data is undefined on first render
            // data={inactiveUsersData}
            icons={{
              ArrowDownwardIcon: () => SortIcon?.ArrowDownwardIcon(sorting),
              SyncAltIcon: () => SortIcon?.SyncAltIcon(),
            }}
            state={{
              sorting: sorting,
            }}
            renderEmptyRowsFallback={renderEmptyRowsFallback}
            enableFilterMatchHighlighting={true}
            enableGlobalFilter
            globalFilterFn="contains"
            enableColumnActions={false}
            enableBottomToolbar={true}
            onSortingChange={setSorting}
            muiSearchTextFieldProps={{
              ...SearchStyle,
              placeholder: "Search for a user...",
              InputProps: {
                ...SearchIcon,
              },
            }}
            getRowId={(originalRow) => originalRow?.company_id}
            //give each row a more useful id
            enableSelectAll={false}
            enableRowActions
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "",
                size: 20,
                muiTableBodyCellProps: {
                  sx: {
                    paddingRight: "16px !important",
                  },
                },
              },
            }}
            muiTablePaperProps={{
              sx: {
                backgroundColor: "transparent",
                table: {
                  borderCollapse: "separate",
                  borderSpacing: "0 5px",
                },
              },
            }}
            renderTopToolbar={({ table }) => (
              <>
                <Row>
                  <Col md={8} lg={9} className="mb-sm-3 mb-lg-0 pe-lg-5">
                    <div className="d-flex align-items-center gap-2 mb-3">
                      <H3>Pending users</H3>
                      <CustomToolTip size="sm" text="Pending users" />
                    </div>
                    <div className="light-font-text">
                      Below is a list of all pending users who have not accepted
                      the user creation invitation. It is possible to resend the
                      invitation to the user in the context menu.
                    </div>
                  </Col>
                  <Col md={4} lg={3} className="d-flex justify-content-end">
                    <MRT_GlobalFilterTextField table={table} />
                  </Col>
                </Row>
              </>
            )}
            muiTableContainerProps={{
              elevation: 0,
              sx: {
                background: "white",
                padding: "0px !important",
                paddingBottom: "10px !important",
                paddingTop: "24px !important",
              },
            }}
            positionActionsColumn="last"
            initialState={{
              showGlobalFilter: true,
              showColumnFilters: false,
            }}
            muiPaginationProps={{
              ...paginationoptions,
            }}
            renderRowActions={({ row, table }) => (
              <>
                <div className="d-flex justify-content-end">
                  <Menus
                    itemheight={24}
                    itemwidth={24}
                    iconborderradius="4px"
                    itemcontainerwidth="150px"
                    component={
                      <>
                        <MenuItem
                          sx={fontStyle}
                          onClick={() => {
                            setUserId(row?.original?.user_id);
                            setInvitationEmail(row?.original?.email);
                            setResendInvitationPopup(true);
                          }}
                        >
                          Resend invitation
                        </MenuItem>
                        <hr className="hr m-0"></hr>
                        <MenuItem
                          sx={fontStyle}
                          onClick={() => {
                            setEditNewUserPopup(true);
                            setUserId(row?.original?.user_id);
                            setUsedFor("UserDetails");
                          }}
                        >
                          Edit user
                        </MenuItem>
                        <hr className="hr m-0"></hr>
                        <MenuItem
                          sx={fontStyle}
                          onClick={() => {
                            setDeleteUserPopup(true);
                            setUserId(row?.original?.user_id);
                          }}
                        >
                          Archive user
                        </MenuItem>
                      </>
                    }
                  />
                </div>
              </>
            )}
          />
        }
      />
      {/*------------- edit user popup -------------------*/}
      <CustomPopup
        open={editNewUserPopup}
        onHide={() => setEditNewUserPopup(false)}
        title="Edit user"
        title_color="black"
        size={"xl"}
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Edit user"}
      >
        {/* <UserInformation
          setClosePopup={setEditNewUserPopup}
          userDetailsData={userDetailsData}
          usedFor={usedFor}
          setUsedFor={setUsedFor}
          divisionOptions={divisionOptions}
          useRoelsOptions={useRoelsOptions}
          assignedToTeamsOptions={assignedToTeamsOptions}
          languageOptions={languageOptions}
          formSubmit={handleEditUser}
          croppedImage={croppedImage}
          setCroppedImage={setCroppedImage}
          imageName={imageName}
          setImageName={setImageName}
        /> */}
        <UserInformation
          setClosePopup={setEditNewUserPopup}
          userDetailsData={userDetailsData}
          usedFor={usedFor}
          divisionOptions={divisionOptions}
          setDivisionOptions={setDivisionOptions}
          userRolesOptions={userRolesOptions}
          setUserRoelsOptions={setUserRoelsOptions}
          userDetailsByIdDataRemove={userDetailsByIdDataRemove}
          // assignedToTeamsOptions={assignedToTeamsOptions}
          formSubmit={handleEditUser}
          languageOptions={languageOptions}
          croppedImage={croppedImage}
          setCroppedImage={setCroppedImage}
          imageName={imageName}
          setImageName={setImageName}
          newUserId={newUserId}
          setNewUserId={setNewUserId}
        />
      </CustomPopup>
      {/*---------------- delete user popup --------------------*/}
      <CustomPopup
        open={deleteUserPopup}
        onHide={() => setDeleteUserPopup(false)}
        title="Delete user"
        title_color="black"
        size={"md"}
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Delete user"}
      >
        {" "}
        <H5 className="p-4 light-font-text my-2" style={{ width: "79%" }}>
          Are you sure you want to delete this user?
        </H5>
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET?"
            onClick={() => {
              {
                setDeleteUserPopup(false);
              }
            }}
          />
          <ETButton
            variant={"primary"}
            buttonText="Delete"
            onClick={() => {
              handleArchiveUser(userId);
              setDeleteUserPopup(false);
            }}
          />
        </div>
      </CustomPopup>
      {/* Resend invitation popup */}
      <ActionsPopup
        open={resendInvitationPopup}
        onHide={() => setResendInvitationPopup(false)}
        whiteClick={() => {
          setResendInvitationPopup(false);
        }}
        title={"Resend Invitation"}
        titleTooltipText={"Resend Invitation"}
        firstText={"Do you want to resend the invitation to this customer?"}
        secondText={"We send the invitation to : "}
        email={invitaionEmail}
        secondNextShow={true}
        ThridTextShow={true}
        ThridText={
          "To update user details or change the email, take this shortcut"
        }
        primaryButton={"Confirm"}
        primaryClick={() => {
          handleResendInvitation(userId);
          setResendInvitationPopup(false);
        }}
      />
    </div>
  );
};

export default PendingUsers;

const fontStyle = {
  fontSize: "13px",
  fontFamily: "Cerebri sans-light !important",
};

export const inactiveUsersData = [
  {
    user_no: 1,
    // no: "9999",
    first_name: "Kasper Sybo",
    Last_name: "Olsen",
    roles: "Generic user",
    division_details: ["Juliliving"],
    invited_on: "14 Dec, 2023 at 11:33:56",
    reinvited_on: "12 Dec, 2023",
  },
  {
    id: 2,
    // no: "9999",
    first_name: "Birgitte",
    Last_name: "Nymark",
    roles: "Division Admin",
    division_details: ["Juliliving"],
    invited_on: "11 Dec, 2023 at 18:22:21",
    reinvited_on: "12 Dec, 2023",
  },
];
