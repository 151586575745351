/* eslint-disable react/jsx-pascal-case */

import React, { useEffect } from "react";
import {
  Col,
  Container,
  Dropdown,
  Image,
  Placeholder,
  Row,
} from "react-bootstrap";

import ETButton from "../../../../Components/Buttons/ETButton";
import Menus from "../../../../Components/Menus/Menus";
// import { Menu, MenuItem } from "@mui/material";
import { useState, useMemo } from "react";
// import photoComingSoon from "../../../../assets/images/photo-coming-soon.jpg";
import {
  GetUnitStatuses,
  // GetUnitsByPropertyId,
  PutSetUnitStatus,
} from "../../../../services/Units";
// import MultipleUnits from "../unitsCrudOperation/MultipleUnits";
import CustomPopup from "../../../../Components/CustomPopup";
import Trophy from "../../../../assets/images/mediamodifier_cropped_image.png";
import {
  // useGetUnitsByPropertyId,
  useGetUnitsByPropertyIdUpdated,
  useDeleteSingleUnit,
  // useGetEditUnitsTextField,
  useSetFavoriteUnits,
  useUpdateUnitAnnounceStatus,
  usePutUnitSetUnitStatus,
} from "../../../../hooks/queries/useUnitQueries";
import { useQuery } from "react-query";

import { useGetStatusLabelCategory } from "../../../../hooks/queries/useStatusLabelQueries";

import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
// import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import {
  useUnitId,
  useUnitIdToEdit,
  // useUnitType,
} from "../../../../hooks/stores/useUnitStore";
import { useReservationStore } from "../../../../hooks/stores/useReservationStore";
import MRTTheme from "../../../../Components/table/MRTTheme";
// import { status } from "../../../../model/Constant";
import {
  SearchIcon,
  // TableRowsStyle,
  paginationoptions,
  SearchStyle,
  SortIcon,
} from "../../../../Components/table/MRTGlobalProps";
// import CSVWidget from "../../../../Components/DownloadWidget/CSVWidget";
import { GreenSpan, H2 } from "../../../../styles/Common.style";
// import Checkbox from "../../../../Components/Checkbox/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
// import { Settings } from "../../../../assets/icons/icons";
// import { TabsButtonIcon } from "../../../../Components/Tabs/Tabs.style";
import PropertyOverview from "./PropertyOverview";
import AddUnits from "./AddUnits";
import TagFilterDropDown from "../../../settings/Tags/TagFilterDropDown";
import DropdownModalMui from "../../../../Components/DropdownModal/DropdownModalMui";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import Heart from "../../../../Components/Star/Heart";
import { TableDataBorderRight } from "../../../divisions/DivisionsNewTable";
// import { status } from "../../../model/Constant";

const UnitsTable = ({
  propertyOverviw,
  propertyId,
  RefetchGetPropertyWithOverviewByPropertyIdData,
}) => {
  //------------------Zustand------------------------//
  const { setUnitId } = useUnitId((state) => state);
  // const { unitType } = useUnitType((state) => state);

  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(false);
  // const [show, setShow] = useState(false);
  // const [editmMultipleShow, setEditmMultipleShow] = useState(false);
  const [reservedToLeasedPage, setReservedToLeasedPage] = useState(false);
  const [becomeChampPage, setBecomeChampPage] = useState(false);
  const [availableToReseverdPop, setAvailableToReseverdPop] = useState(false);
  const [rotateAddIcon, setRotateAddIcon] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [rowId, setRowId] = useState("");
  const [showAnnounce, setShowAnnounce] = useState(false);
  const [row, setRow] = useState({});
  const rotate = rotateAddIcon ? "rotate(45deg)" : "rotate(0deg)";
  const { unitToEdit, setUnitToEdit } = useUnitIdToEdit((state) => state);

  // const [exportCsv, setExportCsv] = useState(false);
  // const [eyeShow, setEyeShow] = useState(true);

  const handleClickLeased = () => {
    setBecomeChampPage(false);
    setReservedToLeasedPage(true);
  };
  const championPage = () => {
    setBecomeChampPage(true);
  };

  // Get units by propertyId
  // const {
  //   data: GetUnitsByPropertyIdData,
  //   refetch: GetUnitsByPropertyIdDataRefetch,

  // } = useGetUnitsByPropertyId(propertyId, 0, 0);

  const {
    data: GetUnitsByPropertyIdData,
    refetch: GetUnitsByPropertyIdDataRefetch,
    isLoading,
  } = useGetUnitsByPropertyIdUpdated(propertyId, 0);
  // const [unitId, setUnitId] = useState("");

  const {
    mutateAsync: deleteSingleUnitMutate,
    isLoading: deleteSingleUnitMutateIsLoading,
  } = useDeleteSingleUnit(propertyId, rowId);
  const { mutateAsync: setFavouriteUnitMutate } = useSetFavoriteUnits();
  const {
    mutateAsync: UpdateUnitAnnounceStatusMutate,
    isLoading: UpdateUnitAnnounceStatusMutateIsLoading,
  } = useUpdateUnitAnnounceStatus();
  const [tableData, setTableData] = useState(GetUnitsByPropertyIdData?.data);
  const [newData, setNewData] = useState([]);
  const [originalTagData, setOriginalTagData] = useState([]);
  useEffect(() => {
    setTableData(GetUnitsByPropertyIdData?.data);
    setNewData(GetUnitsByPropertyIdData?.data);
    setOriginalTagData(GetUnitsByPropertyIdData?.data);
  }, [GetUnitsByPropertyIdData]);

  const { mutateAsync: PutUnitSetUnitStatus } = usePutUnitSetUnitStatus();

  // let filteredData;

  const handleSetFavoriteUnit = async (unitId, favouriteFlag) => {
    const setFavoriteUnitPayload = {
      unit_id: String(unitId),
      is_favorite: favouriteFlag === 1 ? "0" : "1",
    };
    try {
      const response = await setFavouriteUnitMutate(setFavoriteUnitPayload);
      if (response.status === "success" && response?.data?.bReturn) {
        toast.success(response?.data?.message, { toastId: unitId });
        GetUnitsByPropertyIdDataRefetch();
        // setTableData(GetUnitsByPropertyIdData?.data);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {}
  };
  const handleUpdateUnitAnnounceStatus = async (unitId, announceFlag) => {
    setShowAnnounce(false);

    const UnitAnnounceStatusPayload = {
      unit_id: String(unitId),
      is_listed: announceFlag == 1 ? "0" : "1",
    };
    try {
      const response = await UpdateUnitAnnounceStatusMutate(
        UnitAnnounceStatusPayload
      );
      if (response.status === "success" && response?.data?.bReturn) {
        toast.success(response?.data?.message, { toastId: unitId });
        GetUnitsByPropertyIdDataRefetch();
        // setTableData(GetUnitsByPropertyIdData?.data);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {}
  };
  // const handleStatusFilter = (e, ActionTypes) => {
  // 	if (ActionTypes.action === "clear") {
  // 		setTableData(GetUnitsByPropertyIdData?.data);
  // 	} else {
  // 		filteredData = GetUnitsByPropertyIdData?.data.filter(
  // 			(unit) => unit.status_type_id === e?.value
  // 		);
  // 		setTableData(filteredData);
  // 	}
  // };

  const ChooseFilter = [
    { Id: "1", Text: "Unit No" },
    { Id: "2", Text: "Status" },
  ];

  const tags = [
    { Id: "1", Text: "Not active", parent: "2" },
    { Id: "2", Text: "Available", parent: "2" },
    { Id: "3", Text: "Reserved", parent: "2" },
    { Id: "4", Text: "In process", parent: "2" },
    { Id: "5", Text: "Completed", parent: "2" },
    { Id: "6", Text: "Available", parent: "2" },
    { Id: "7", Text: "Total", parent: "2" },
  ];

  const dumnmyDropDownData = {
    "Unit No": "calculated_unit_id",
    Status: "tags",
  };
  const [propertag, setPropertytag] = useState("");
  const [filter, setFilter] = useState(null);
  const [resetCondition, setResetCondition] = useState(false);
  useEffect(() => {
    if (resetCondition == true) {
      setTableData(GetUnitsByPropertyIdData?.data);
    }
  }, [resetCondition]);
  const selectdata = [];
  const [filterDropdowon, setFilterDropdowon] = useState("");
  const [filterDropData, setFilterDropData] = useState("");
  const [selectedDropdownData, setSelectedDropDownData] = useState([]);
  const [show, setShow] = useState();
  const [status, setStatus] = useState(false);
  const handleChange = (filter, ActionTypes) => {
    if (ActionTypes.action === "clear") {
      setFilter("");
    } else {
      setFilter(filter);
    }
    setPropertytag(true);
    setShow(false);
    let a = dumnmyDropDownData[filter?.label];
    let b = [...new Set(newData?.map((data) => data[a]))];
    setSelectedDropDownData(b);
    // setFilter(filter.label);
    setFilterDropdowon(ChooseFilter);

    const checkSelectedLabel = filter?.label;
    if (checkSelectedLabel === "Status") {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };
  // const test = filter;
  const filteredTagdata = filter
    ? tags.filter((tag) => tag.parent === filter.value)
    : [];
  const filteredTag = selectedDropdownData;
  const [selecteddata, setSelectedData] = useState([]);
  const [closeDropdown, setCloseDropdown] = useState(false);

  const [filterKeys, setFilterKeys] = useState({
    calculated_unit_id: [],
    mainstatus_name: [],
  });
  // Function to push data into a specific filter key
  const pushDataToFilterKey = (key, value) => {
    if (filterKeys[key]?.includes(value)) {
    } else {
      setFilterKeys((prevFilterKeys) => ({
        ...prevFilterKeys,
        [key]: [...prevFilterKeys[key], value],
      }));
    }
  };
  const multiPropsFilter = (unitsTableData, filters) => {
    const filterKeys = Object?.keys(filters);
    const filterdata = originalTagData?.filter((product) => {
      return filterKeys?.every((key) => {
        if (!filters[key].length) return true;
        // Loops again if product[key] is an array (for material attribute).
        if (Array?.isArray(product[key])) {
          return product[key]?.some((keyEle) => filters[key]?.includes(keyEle));
        }
        return filters[key]?.includes(product[key]);
      });
    });
    setTableData(filterdata);
  };

  const handleFilterData = (i) => {
    const selectfilterdata = filter?.label;
    const parent = ChooseFilter.find(
      (filter) => filter.Id === selectfilterdata
    )?.parent;

    if (selectfilterdata == "Unit No") {
      const key = "calculated_unit_id";
      const value = i;
      pushDataToFilterKey(key, value);
    } else if (selectfilterdata === "Status") {
      const key = "mainstatus_name";
      const value = i;
      pushDataToFilterKey(key, value);
    } else {
    }

    setFilterDropData(i);

    selectdata.push({ lable: filterDropdowon, value: i });
    setSelectedData((prev) => [...prev, ...selectdata]);
  };

  // const AddUnitClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  //   setDropdownOpen(true);
  //   setRotateAddIcon(!rotateAddIcon);
  // };
  // const AddUnitClose = () => {
  //   setAnchorEl(null);
  //   setDropdownOpen(false);
  //   setRotateAddIcon(!rotateAddIcon);
  // };
  // const addMultipleunits = () => {
  //   setAnchorEl(null);
  //   setDropdownOpen(false);
  //   setShow(true);
  //   setRotateAddIcon(!rotateAddIcon);
  // };
  // const editMultipleunits = () => {
  //   setAnchorEl(null);
  //   setDropdownOpen(false);
  //   setEditmMultipleShow(true);
  //   setRotateAddIcon(!rotateAddIcon);
  // };

  // const deletedUnitConformation = async (id) => {
  //   const result = await deleteSingleUnitMutate(id);
  //   if (result.data.bReturn) {
  //     toast.success(result.data.message + "!");
  //     GetUnitsByPropertyIdDataRefetch();
  //     setDeletePopUp(false);
  //   } else {
  //     toast.error(result.data.message + "!");
  //   }
  // };

  const deletedUnitConformation = async () => {
    try {
      const result = await deleteSingleUnitMutate(propertyId, rowId);
      if (result?.status === "success" && result?.data?.bReturn) {
        toast.success(result?.data?.message + "!");
        GetUnitsByPropertyIdDataRefetch();
        setDeletePopUp(false);
        RefetchGetPropertyWithOverviewByPropertyIdData();
      } else {
        toast.error(result?.data?.message);
        setDeletePopUp(false);
      }
    } catch (error) {}

    //   // if (response.status == "success") {
    //   // 	toast.success(response.data.message + "!");
    //   // }
  };
  const handleSuffix = (floor) => {
    if (floor > 3 && floor < 21) return "th";
    switch (floor % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const handleAnnounce = (row) => {
    setRow(row);
    setShowAnnounce(true);
  };
  const handleAddressColumn = (streetName, houseNo, floorNumber) => {
    if (streetName !== "" && houseNo !== "" && floorNumber !== "") {
      return `${streetName}, ${houseNo}, ${floorNumber}`;
    } else if (streetName === "" && houseNo !== "" && floorNumber !== "") {
      return `-, ${houseNo}, ${floorNumber}`;
    } else if (streetName !== "" && houseNo === "" && floorNumber !== "") {
      return `${streetName}, -, ${floorNumber}`;
    } else if (streetName !== "" && houseNo !== "" && floorNumber === "") {
      return `${streetName}, ${houseNo}, -`;
    } else if (streetName === "" && houseNo === "" && floorNumber !== "") {
      return `-, -, ${floorNumber}`;
    } else if (streetName === "" && houseNo !== "" && floorNumber === "") {
      return `-, ${houseNo}, -`;
    } else if (streetName !== "" && houseNo === "" && floorNumber === "") {
      return `${streetName}, -, -`;
    } else {
      return `-, -, -`;
    }
  };

  const columns = useMemo(
    () => [
      //   {
      //     accessorFn: (row) => `${row?.mainstatus_name}${row?.substatus_name}`,
      //     header: " ",
      //     size: 15,
      //     muiTableBodyCellProps: {
      //       align: "left",
      //       border: "1px solid #888",
      //     },
      //     Cell: ({ row }) => (
      //       <>
      //         <div
      //           style={{
      //             width: "100%",
      //             height: "100%",
      //             backgroundColor: `${row?.original?.mainstatus_background_color}`,
      //           }}
      //         ></div>
      //       </>
      //     ),
      //   },
      {
        accessorFn: (row) => `${row?.is_lisetd}${row?.is_favorite}`,
        id: "star",
        header: "",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div className="d-flex ">
            <div
              className=" me-4"
              style={{
                height: "74px",
                padding: "5px",
                backgroundColor: "#888888",
              }}
            ></div>
            <div className="d-flex flex-column justify-content-center">
              <div className="pb-2">
                <FontAwesomeIcon
                  icon={
                    row?.original?.is_listed == 1
                      ? "fa-regular fa-eye"
                      : "fa-regular fa-eye-slash"
                  }
                  size="lg"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleUpdateUnitAnnounceStatus(
                      row?.original?.unit_id,
                      row?.original?.is_listed
                    );
                  }}
                />
              </div>
              <div>
                <Heart isFavorite="0" />
              </div>
            </div>
          </div>
        ),
        //center align the header and body cells
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "center",
          sx: {
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
            backgroundColor: "#F0F0F0",
            padding: "0px !important",
          },
        },
      },
      {
        accessorFn: (row) => `${row?.calculated_unit_id}${row?.alt_unit_id}`,
        header: "Unit no.",
        enableHiding: false,
        size: 130,

        muiTableBodyCellProps: {
          sx: {
            borderTop: "1px solid #D1D1D1 !important",
            borderRight: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
            backgroundColor: "#F0F0F0",
          },
        },
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div
            style={{
              backgroundColor: "#F0F0F0",
              width: "100%",
            }}
          >
            <div>{row?.original?.calculated_unit_id}</div>
            <div>{row?.original?.alt_unit_id}</div>
          </div>
        ),
      },

      {
        accessorFn: (row) =>
          `${row?.street_name}${row?.zipcode_id}${row?.house_number}${row?.zipcode_id}${row?.city}`,
        header: "Address",
        size: 180,
        enableGlobalFilter: true,
        muiTableHeadCellProps: {
          align: "left",
          sx: {
            paddingLeft: "24px!important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
            paddingLeft: "24px!important",
            paddingRight: "2px !important",
          },
        },
        Cell: ({ row }) => (
          <div className="d-flex justify-content-between align-items-center ">
            <NavLink
              state={{
                unit_id: row.original.unit_id,
                propId: propertyId,
                unit_no: row?.original?.calculated_unit_id,
                street_name: row?.original?.street_name,
                house_number: row?.original?.house_number,
                zip_code: row?.original?.zipcode_id,
              }}
              to="/property-units-edit/overview"
              style={{ textDecoration: "none", color: "black" }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setUnitId(row.original.unit_id)}
              >
                {handleAddressColumn(
                  row?.original?.street_name,
                  row?.original?.house_number,
                  row?.original?.floor_number
                )}
              </div>
              <div>{`${row?.original?.zipcode_id} ${row?.original?.city}`}</div>
            </NavLink>
            <TableDataBorderRight />
          </div>
        ),
      },
      {
        accessorFn: (row) =>
          `${row?.no_of_rooms}${row?.floor_number}${row?.area}`,
        header: "Unit details",
        size: 180,
        muiTableHeadCellProps: {
          align: "left",
          sx: {
            paddingLeft: "24px!important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
            paddingLeft: "24px!important",
            paddingRight: "2px !important",
          },
        },
        Cell: ({ row }) => (
          <div className="d-flex justify-content-between align-items-center ">
            <div>
              <div className="d-flex justify-content-between">
                <div>{`${row?.original?.no_of_rooms}-room`}</div>{" "}
                <div>{`${row?.original?.floor_number}${handleSuffix(
                  row?.original?.floor_number
                )} floor`}</div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="d-flex gap-1">
                  {row?.original?.area}{" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: row?.original?.square_measure_unit,
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "97px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    textAlign: "end",
                  }}
                >
                  {row?.original?.featured_facility == ""
                    ? "-"
                    : row?.original?.featured_facility}
                </div>
              </div>
            </div>
            <TableDataBorderRight />
          </div>
        ),
      },
      {
        accessorFn: (row) => `${row?.rent}`,
        header: "Rent",
        size: 200,
        muiTableHeadCellProps: {
          align: "left",
          sx: {
            paddingLeft: "24px!important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
            paddingLeft: "24px!important",
            paddingRight: "2px !important",
          },
        },
        Cell: ({ row }) => (
          <div className="d-flex justify-content-between align-items-center ">
            <div>
              <div className="d-flex justify-content-between">
                <div>
                  Rent/
                  {row?.original?.payment_frequency === 1
                    ? "mthly."
                    : row?.original?.payment_frequency === 3 && "qtrly."}
                </div>{" "}
                <div
                  style={{
                    width: "100px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    textAlign: "end",
                  }}
                >{`${row?.original?.rent} ${row?.original?.currency_id}`}</div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  Rent/
                  <div
                    dangerouslySetInnerHTML={{
                      __html: row?.original?.square_measure_unit,
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    width: "100px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    textAlign: "end",
                  }}
                >
                  {`${
                    row?.original?.area !== 0
                      ? Math.ceil(row?.original?.rent / row?.original?.area)
                      : 0
                  } 
							${row?.original?.currency_id}`}
                </div>
              </div>
            </div>
            <TableDataBorderRight />
          </div>
        ),
      },
      {
        accessorFn: (row) => `${row?.actor_name}${row?.date}`,
        header: "Contract",
        size: 240,
        muiTableHeadCellProps: {
          align: "left",
          sx: {
            paddingLeft: "24px!important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
            paddingLeft: "24px!important",
            paddingRight: "2px !important",
          },
        },
        Cell: ({ row }) => (
          <div className="d-flex justify-content-between align-items-center ">
            <div>
              <div>
                {row?.original?.actor_name == ""
                  ? "-"
                  : row?.original?.actor_name}
              </div>
              <div>
                {row?.original?.date == "" ? (
                  "-"
                ) : (
                  <div className="d-flex align-items-center">
                    <div>{row?.original?.date_text}</div>&nbsp;:&nbsp;
                    <div>
                      {moment(row?.original?.date).format("D MMM, YYYY")}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <TableDataBorderRight />
          </div>
        ),
      },
      {
        accessorFn: (row) => "",
        header: "Current Customer",
        size: 240,
        muiTableHeadCellProps: {
          align: "left",
          sx: {
            paddingLeft: "24px!important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
            paddingLeft: "24px!important",
            paddingRight: "2px !important",
          },
        },
        Cell: ({ row }) => (
          <div className="d-flex justify-content-between align-items-center ">
            <div className="d-flex justify-content-center ">-</div>
            <TableDataBorderRight />
          </div>
        ),
      },
      {
        accessorFn: (row) => "",
        header: "Current Customer Contract Status",
        size: 290,
        muiTableHeadCellProps: {
          align: "left",
          sx: {
            paddingLeft: "24px!important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
            paddingLeft: "24px!important",
            paddingRight: "2px !important",
          },
        },
        Cell: ({ row }) => (
          <div className="d-flex justify-content-between align-items-center ">
            <div className="d-flex justify-content-center ">-</div>
            <TableDataBorderRight />
          </div>
        ),
      },
      {
        accessorFn: "",
        header: "Future Customer",
        size: 240,
        muiTableHeadCellProps: {
          align: "left",
          sx: {
            paddingLeft: "24px!important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
            paddingLeft: "24px!important",
            paddingRight: "2px !important",
          },
        },
        Cell: ({ row }) => (
          <div className="d-flex justify-content-between align-items-center ">
            <div className="d-flex justify-content-center ">-</div>
            <TableDataBorderRight />
          </div>
        ),
      },
      {
        accessorFn: "",
        header: "Future Customer Contract Status",
        size: 240,
        muiTableHeadCellProps: {
          align: "left",
          sx: {
            paddingLeft: "24px!important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
            paddingLeft: "24px!important",
            paddingRight: "2px !important",
          },
        },
        Cell: ({ row }) => (
          <>
            <div className="d-flex justify-content-center ">-</div>
          </>
        ),
      },

      {
        accessorFn: (row) => `${row?.mainstatus_name}${row?.substatus_name}`,
        header: "Status",
        size: 120,
        muiTableHeadCellProps: {
          align: "left",
          sx: {
            paddingLeft: "24px!important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            // borderTop: "1px solid #D1D1D1 !important",
            borderLeft: "1px solid #D1D1D1 !important",
            // borderBottom: "1px solid #D1D1D1 !important",
            paddingLeft: "24px!important",
            backgroundColor: "#F0F0F0",
          },
        },
        Cell: ({ row }) => (
          <>
            {/* <DropDown
							propertyId={propertyId}
							value={row?.original}
							getTableUnitsByPropertyId={getTableUnitsByPropertyId}
							setReservedToLeasedPage={setReservedToLeasedPage}
							setBecomeChampPage={setBecomeChampPage}
							setAvailableToReseverdPop={setAvailableToReseverdPop}
						/> */}
            <div
              style={{
                whiteSpace: "nowrap",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                fontFamily: "Cerebri sans-medium",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              <div
                className="fs-13 "
                style={{
                  backgroundColor: `${row?.original?.mainstatus_background_color}`,
                  // height: "18px",
                  width: "186px",
                  padding: "2px 16px",
                  borderRadius: "4px 4px 0px 0px",
                  textTransform: "uppercase",
                  color: row?.original?.mainstatus_font_color,
                }}
              >
                {row?.original?.mainstatus_name}
              </div>
              <div
                className="fs-12"
                style={{
                  backgroundColor: row?.original?.substatus_background_color,
                  // height: "18px",
                  width: "186px",
                  padding: "2px 16px",
                  borderRadius: "0px 0px 4px 4px",
                  color: row?.original?.substatus_font_color,
                }}
              >
                {row?.original?.substatus_name}
              </div>
            </div>
          </>
        ),
      },
    ],
    []
  );

  // Get units by propertyId
  const [UnitsData, setUnitsData] = useState([]);

  useEffect(() => {
    GetUnitsByPropertyIdDataRefetch();
    RefetchGetPropertyWithOverviewByPropertyIdData();
    GetUnitsByPropertyIdDataRefetchForExport();
  }, [UnitsData]);

  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);
  // const getTableUnitsByPropertyId = () => {
  // 	try {
  // 		GetUnitsByPropertyId(propertyId, 0)
  // 			.then((data) => {
  // 				if (data != null && data != undefined) {
  // 					setUnitsData(data.data);
  // 				} else {
  // 					setUnitsData([null]);
  // 				}
  // 			})
  // 			.catch((error) => {
  // 				setError({ error: error.message, status: error.status });
  // 				setUnitsData([null]);
  // 			});
  // 	} catch (e) {
  // 		setUnitsData([null]);
  // 		setError({
  // 			error: true,
  // 			status: "",
  // 			message: "Failed to retrieve data",
  // 		});
  // 	}
  // };

  // useEffect(() => {
  // 	getTableUnitsByPropertyId();
  // }, [propertyId]);
  // console.log(propertyId, "propertyId");

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      style={{
        backgroundColor: "#FFF",
        color: "#000",
        width: "100%",
        display: "flex",
        height: "41px",
        border: "1px solid #D1D1D1",
        fontWeight: "300",
        justifyContent: "space-between",
        alignItems: "center",
        textDecoration: "none",
        fontSize: "14px",
        borderRadius: "8px",
      }}
      className="px-2 py-2 dropdownInput "
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        setCloseDropdown(!closeDropdown);
      }}
    >
      <div className="d-flex justify-content-between w-100">
        <span> {children}</span>
        <span>
          <FontAwesomeIcon
            icon="fa-regular fa-chevron-down"
            size="sm"
            style={{ color: "#000000" }}
          />
        </span>
      </div>
    </a>
  ));
  // //Units data for exports
  const {
    data: GetUnitsByPropertyIdDataForExport,
    refetch: GetUnitsByPropertyIdDataRefetchForExport,
  } = useGetUnitsByPropertyIdUpdated(propertyId, 1);

  // const [fieldChecked, setFieldChecked] = useState([]);

  // const { data: getUnitTextFields } = useGetEditUnitsTextField(propertyId);

  // const filteredTextfieldsData = getUnitTextFields?.data?.filter(
  //   (item) =>
  //     item.column_name !== "unit_calculated_number" &&
  //     item.column_name !== "number_in_property"
  // );
  // // console.log(filteredTextfieldsData, "filteredTextfieldsData")

  // function getFieldsArray(fieldNames) {
  //   return fieldNames.map((fieldName) => ({ field: fieldName }));
  // }

  // const fieldsArray = getFieldsArray(fieldChecked);
  // const mandatoryColumnsForCsv = [{ field: "unit_id" }];

  // const options = {
  //   keys: mandatoryColumnsForCsv.concat(fieldsArray),
  // };

  // const fileName = `${propertyOverviw?.[0]?.propertyName} Units`;
  // const data = GetUnitsByPropertyIdDataForExport?.data;
  // const CSVFunction = CSVWidget({ fileName, options, data });

  // const handleAllFields = (e) => {
  //   if (e.target.checked) {
  //     const allUnitTypes = filteredTextfieldsData.map(
  //       (item) => item.column_name
  //     );
  //     setFieldChecked(allUnitTypes);
  //   } else {
  //     setFieldChecked([]);
  //   }
  // };

  // const handleCheckedTextfield = (e, item) => {
  //   if (e.target.checked) {
  //     setFieldChecked([...fieldChecked, item.column_name]);
  //   } else {
  //     setFieldChecked(fieldChecked.filter((el) => el !== item.column_name));
  //   }
  // };

  const handleChangeUnitActive = async (unitId) => {
    const UnitActiveChange = {
      unit_id: unitId,
      mainstatus_id: "2",
      substatus_id: "3",
    };

    try {
      const response = await PutUnitSetUnitStatus(UnitActiveChange);
      if (response?.status === "success" && response?.data?.bReturn) {
        toast.success(response?.data?.message);
        GetUnitsByPropertyIdDataRefetch();
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {}
  };
  // const renderEmptyRowsFallback = () => (
  //   <div className="d-flex justify-content-center w-100 py-4 grey-text">
  //     <div>
  //       You do not have any unit yet. Click on “+ ADD UNITS ” to create your
  //       first.
  //     </div>
  //   </div>
  // );
  const renderEmptyRowsFallback = () => {
    if (tableData && tableData?.length === 0) {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div>
              You do not have any unit yet. Click on “+ ADD UNITS ” to create
              your first.
            </div>
          </>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div style={{ fontSize: "17px" }}>Sorry! No result found:(</div>
          </>
        </div>
      );
    }
  };
  return (
    // <div
    //   style={{ backgroundColor: "#F1F5F0", borderRadius: "10px 10px 0px 0px" }}
    //   className="mt-2"

    // >
    <div className="whiteContainerStyle " style={{ borderRadius: "10px" }}>
      <div className="pb-3 ">
        {/* Header and overview table */}
        <div className=" d-flex flex-column">
          <div className="border-bottom px-4 py-3">
            <div className="d-flex align-items-center">
              <H2 className="me-2">Residential</H2>
              <CustomToolTip size="sm" text="Residential" />{" "}
            </div>
            {/* <span className="light-font-text fs-12">
              Last updated by Jesper Thorkildsen, 19. Jan. 2023 | 16:21:03
            </span> */}
          </div>
          <div className="p-4 d-flex flex-column ">
            <PropertyOverview
              propertyOverviw={propertyOverviw}
              isLoading={isLoading}
              isResidentalPage={true}
            />
          </div>
        </div>

        {/* Unit's table */}
        <div className="px-4">
          <MRTTheme
            table={
              <MaterialReactTable
                columns={columns}
                data={tableData ?? []}
                icons={{ ...SortIcon }}
                renderEmptyRowsFallback={renderEmptyRowsFallback}
                enableFilterMatchHighlighting={true}
                enableGlobalFilter
                // globalFilterFn="contains" //turn off fuzzy matching and use simple contains filter function

                state={{ isLoading: !tableData }}
                // enableRowNumbers={false}
                enableRowActions
                enablePinning
                enableColumnActions={false}
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    header: "",
                    size: 50,
                    muiTableBodyCellProps: {
                      sx: {
                        // padding: "0px !important",
                        backgroundColor: "#F0F0F0 !important",
                        border: "none !important",
                      },
                    },
                  },
                }}
                muiTableContainerProps={{
                  sx: {
                    padding: "0px !important",
                  },
                }}
                muiSearchTextFieldProps={{
                  ...SearchStyle,

                  InputProps: {
                    ...SearchIcon,
                  },
                }}
                getRowId={(row) => row?.unit_id}
                renderRowActions={({ row }) => (
                  <Menus
                    itemheight={23}
                    itemwidth={23}
                    iconborderradius="4px"
                    itemcontainerwidth="150px"
                    component={
                      <div className="light-font-text">
                        {row?.original?.mainstatus_name !== "Not active" && (
                          <>
                            <NavLink
                              to="/create-new-reservation"
                              state={{ unit_id: row.id, propId: propertyId }}
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <div
                                className="p-2"
                                onClick={() => setUnitId(row?.id)}
                              >
                                Add a contract{" "}
                                <FontAwesomeIcon
                                  className="ms-1"
                                  icon="fa-regular fa-user-plus"
                                  size="sm"
                                  style={{ color: "#000000" }}
                                />
                              </div>
                            </NavLink>
                            <hr className="hr m-0"></hr>
                          </>
                        )}

                        {/* <NavLink
                        to="/actor"
                        state={{
                          unit_id: row.id,
                          propId: propertyId,
                          unit_no: row?.original?.calculated_unit_id,
                          street_name: row?.original?.street_name,
                          house_number: row?.original?.house_number,
                          zip_code: row?.original?.zipcode_id,
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <div
                          key="overview"
                          onClick={() => setUnitId(row?.id)}
                          className="p-2"
                        >
                          Overview{" "}
                        </div>
                      </NavLink>
                      <hr className="hr m-0"></hr> */}
                        <NavLink
                          state={{
                            // unit_id: row.id,
                            unit_id: row.original.unit_id,
                            propId: propertyId,
                            unit_no: row?.original?.calculated_unit_id,
                            street_name: row?.original?.street_name,
                            house_number: row?.original?.house_number,
                            zip_code: row?.original?.zipcode_id,
                          }}
                          to="/property-units-edit/unit-details"
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <div
                            onClick={() => setUnitId(row?.id)}
                            key="edit"
                            className="p-2"
                          >
                            Unit details
                          </div>
                        </NavLink>
                        <hr className="hr m-0"></hr>

                        <div
                          key="favorite"
                          onClick={() => {
                            handleSetFavoriteUnit(
                              row?.original?.unit_id,
                              row?.original?.is_favorite
                            );
                          }}
                          style={{ cursor: "pointer" }}
                          className="p-2"
                        >
                          {row?.original?.is_favorite == 1
                            ? "Remove from favorites"
                            : "Add as favorite"}
                        </div>
                        <hr className="hr m-0"></hr>

                        <div
                          key="favorite"
                          onClick={() => {
                            // handleUpdateUnitAnnounceStatus(
                            // 	row?.original?.unit_id,
                            // 	row?.original?.is_listed
                            // );
                            handleAnnounce(row);
                          }}
                          style={{ cursor: "pointer" }}
                          className="p-2"
                        >
                          {row?.original?.is_listed == 1
                            ? "Announced"
                            : "Add as announced"}
                        </div>
                        <hr className="hr m-0"></hr>

                        <div
                          key="delete"
                          onClick={() => {
                            setDeletePopUp(!deletePopUp);
                            setRowId(row?.id);
                          }}
                          style={{ cursor: "pointer" }}
                          className="p-2"
                        >
                          Delete
                        </div>
                        <hr className="hr m-0"></hr>

                        {row?.original?.substatus_id == 2 && (
                          <div
                            key="ChangeUnitToActive"
                            onClick={() => {
                              handleChangeUnitActive(row?.original?.unit_id);
                            }}
                            style={{ cursor: "pointer" }}
                            className="p-2"
                          >
                            Set Unit Available
                          </div>
                        )}
                      </div>
                    }
                  />
                )}
                positionActionsColumn="last"
                muiTableHeadCellProps={{
                  // elevation: 0,
                  sx: {
                    // boxShadow: "none",
                  },
                }}
                muiTableBodyProps={{
                  zindex: "1",
                  sx: {
                    td: {
                      // overflow: "visible",
                      // backgroundColor: "#F9FAFA",
                    },
                  },
                }}
                muiTablePaperProps={{
                  elevation: 0,
                  sx: {
                    fontFamily: "Cerebri sans !important",
                    borderRadius: "0",
                    border: "none",
                    background: "#FFFFFF",
                    fontWeight: "300",
                    ".css-1f2qhs8": {
                      minWidth: "auto",
                      marginBottom: "5px",
                    },
                    table: {
                      borderCollapse: "separate",
                      borderSpacing: "0 5px",
                    },
                    // thead: {
                    //   // display: "none",
                    // },
                    // th: {
                    //   background: "#FFFFFF",
                    //   fontFamily: "Cerebri sans-medium !important",
                    // },
                    // td: {
                    //   fontFamily: "Cerebri sans-light !important",
                    //   fontSize: "14px",
                    //   borderRight: "1px solid #D1D1D1 !important",
                    //   borderImage:
                    //     "linear-gradient(to bottom, rgba(0,0,0,0) 25%,rgba(209,209,209) 25%,rgba(209,209,209) 75%,rgba(0,0,0,0) 75%) !important",
                    //   borderImageSlice: "1 !important",
                    // },
                    // tr: {
                    //   borderRadius: "4px",
                    //   // background: "#F9FAFA",
                    //   margin: "4px",
                    // },
                    // "& td:nth-last-child(3)": {
                    //   borderRight: "none !important",
                    // },
                    // "& td:nth-last-child(2)": {
                    //   border: "none !important",
                    //   borderLeft: "1px solid #d1d1d1 !important",
                    //   backgroundColor: "#F0F0F0",
                    // },
                    // "& td:nth-last-child(11)": {
                    //   border: "none !important",
                    //   // borderLeft: "1px solid #d1d1d1 !important",
                    //   backgroundColor: "#F0F0F0",
                    // },
                    // "& td:nth-last-child(12)": {
                    //   border: "none !important",
                    //   // borderLeft: "8px solid #888 !important",
                    //   backgroundColor: "#F0F0F0",
                    //   padding: "0px",
                    //   // borderTopLeftRadius: "8px",
                    // },
                    // "& td:nth-last-child(1)": {
                    //   border: "none !important",
                    // },
                  },
                }}
                muiTableHeadRowProps={{
                  sx: {
                    // ...TableRowsStyle,
                    background: "#FFFFFF",
                  },
                }}
                muiTableBodyRowProps={{
                  sx: {
                    height: "70px",
                  },
                }}
                muiTablePaginationProps={{
                  ...paginationoptions,
                }}
                initialState={{
                  showGlobalFilter: true,
                  showColumnFilters: false,
                  columnPinning: {
                    // left: ["id", "Status"],
                    right: ["Status", "mrt-row-actions"],
                  },
                }}
                renderTopToolbar={({ table }) => (
                  <>
                    {/* <div className="d-flex justify-content-end">
                      <div className="d-flex w-100 justify-content-end">
                        <div className="pe-3">
                          {isLoading ? (
                            <div className="">
                              <Placeholder animation="glow">
                                <div className="placeholder col-10  radius-8 padding-20 bg-secondary"></div>
                              </Placeholder>
                            </div>
                          ) : (
                            <div
                              className="fs-14"
                              style={{ color: "#D3D3D3 !important" }}
                            >
                              <DropdownModalMui
                                text="Status"
                                width="561px"
                                closeDropdown={closeDropdown}
                                component={
                                  <TagFilterDropDown
                                    propertag={propertag}
                                    filteredTag={filteredTag}
                                    filteredTagdata={filteredTagdata}
                                    status={status}
                                    setUnitsTableData={setUnitsData}
                                    multiPropsFilter={multiPropsFilter}
                                    selectedDropdownData={selectedDropdownData}
                                    dumnmyDropDownData={dumnmyDropDownData}
                                    filterDropdowon={filterDropdowon}
                                    closeDropdown={closeDropdown}
                                    // handleDropData={handleDropData}
                                    filterDropData={filterDropData}
                                    setFilterDropData={setFilterDropData}
                                    ChooseFilter={ChooseFilter}
                                    handleFilterData={handleFilterData}
                                    filter={filter}
                                    selecteddata={selecteddata}
                                    setFilter={setFilter}
                                    handleChange={handleChange}
                                    selectdata={selectdata}
                                    filterKeys={filterKeys}
                                    setFilterKeys={setFilterKeys}
                                    setCloseDropdown={setCloseDropdown}
                                    setResetCondition={setResetCondition}
                                  />
                                }
                              />
                            </div>
                          )}
                        </div>
                        <div className="pe-3 w-25">
                          {isLoading ? (
                            <div className="">
                              <Placeholder animation="glow">
                                <div className="placeholder col-10 radius-8 padding-20 bg-secondary"></div>
                              </Placeholder>
                            </div>
                          ) : (
                            // eslint-disable-next-line react/jsx-pascal-case
                            <MRT_GlobalFilterTextField table={table} />
                          )}
                        </div>
                        <div>
                          <AddUnits
                            isLoading={isLoading}
                            propertyId={propertyId}
                            propertyOverviw={propertyOverviw}
                          />
                        </div>
                      </div>
                    </div> */}
                    <Row className="pt-3">
                      <Col></Col>
                      <Col xl={2} md={3} className="cursor-pointer pe-1">
                        {isLoading ? (
                          <div className="">
                            <Placeholder animation="glow">
                              <div className="placeholder col-10  radius-8 padding-20 bg-secondary"></div>
                            </Placeholder>
                          </div>
                        ) : (
                          <div
                            className="fs-14"
                            style={{ color: "#D3D3D3 !important" }}
                          >
                            <DropdownModalMui
                              text="Status"
                              width="561px"
                              closeDropdown={closeDropdown}
                              component={
                                <TagFilterDropDown
                                  propertag={propertag}
                                  filteredTag={filteredTag}
                                  filteredTagdata={filteredTagdata}
                                  status={status}
                                  setUnitsTableData={setUnitsData}
                                  multiPropsFilter={multiPropsFilter}
                                  selectedDropdownData={selectedDropdownData}
                                  dumnmyDropDownData={dumnmyDropDownData}
                                  filterDropdowon={filterDropdowon}
                                  closeDropdown={closeDropdown}
                                  filterDropData={filterDropData}
                                  setFilterDropData={setFilterDropData}
                                  ChooseFilter={ChooseFilter}
                                  handleFilterData={handleFilterData}
                                  filter={filter}
                                  selecteddata={selecteddata}
                                  setFilter={setFilter}
                                  handleChange={handleChange}
                                  selectdata={selectdata}
                                  filterKeys={filterKeys}
                                  setFilterKeys={setFilterKeys}
                                  setCloseDropdown={setCloseDropdown}
                                  setResetCondition={setResetCondition}
                                />
                              }
                            />
                          </div>
                        )}
                      </Col>

                      <Col xl={3} md={7} className="pe-1">
                        {isLoading ? (
                          <div className="">
                            <Placeholder animation="glow">
                              <div className="placeholder col-10 radius-8 padding-20 bg-secondary"></div>
                            </Placeholder>
                          </div>
                        ) : (
                          <MRT_GlobalFilterTextField table={table} />
                        )}
                      </Col>

                      <Col xl={3} md={2}>
                        <AddUnits
                          isLoading={isLoading}
                          propertyId={propertyId}
                          propertyOverviw={propertyOverviw}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                positionGlobalFilter="right"
              />
            }
          />
        </div>
        <CustomPopup
          // modalHeadingStyle={modalHeadingStyle}
          style={{ position: "relative" }}
          size="md    "
          open={deletePopUp}
          onHide={() => setDeletePopUp(false)}
          title="Are you carrying out the trash?"
          title_color="black"
          enableCrossButton={false}
          onCloseAction={() => {
            setDeletePopUp(false);
          }}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
        >
          <div className="p-4">
            <div className="fs-14 fw-300 text-left mb-3">
              <p>Are you sure you want to delete the unit?</p>
            </div>
          </div>

          <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="COLD FEET"
              onClick={() => setDeletePopUp(false)}
            />

            <ETButton
              variant={"primary"}
              buttonText="I'M SURE"
              disabled={deleteSingleUnitMutateIsLoading}
              onClick={() => deletedUnitConformation()}
            />
          </div>
        </CustomPopup>
      </div>
      {/* )} */}
      {/* status changed to leased */}
      <CustomPopup
        open={reservedToLeasedPage}
        onHide={() => setReservedToLeasedPage(false)}
        title="Unit status change"
        title_color="black"
        titleFont="14px"
        titleTooltip={true}
        titleTooltipText="Unit status change"
        size="xl"
        enableCrossButton={true}
        onCloseAction={() => {
          setReservedToLeasedPage(false);
          alert("Action Peformed Successfuly.");
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={false}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <>
          {becomeChampPage === false ? (
            <Container
              fluid
              className="px-0"
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "0 0 8px 8px",
                // border: "1px solid #F4F5F5",
              }}
            >
              <Row style={{ textAlign: "center" }} className=" px-0 pb-5">
                <Col md={{ span: 8, offset: 2 }} className="pb-5">
                  <Row className="d-flex justify-content-center">
                    <Col xl={10}>
                      <div className="pt-5 fs-32 form-label-title">
                        Hang in there! The finish line is ahead!
                      </div>

                      <div className="mx-5 px-lg-2 pb-2 light-font-text">
                        Keep up the pace. The unit is almost leased, and the
                        automatic screening process of the customers has now
                        started. The unit has also changed its status to leased.
                        <p className="pt-4 ">
                          Become a champion by leasing this unit to the
                          customers below:
                        </p>
                      </div>

                      {/* ------------------data showing after creating Property---------------------------- */}

                      <div className="mt-3 mx-4 px-lg-4">
                        <div
                          className="py-1"
                          style={{
                            backgroundColor: "#F9FAFA",
                            textAlign: "center",
                            borderRadius: "8px",
                          }}
                        >
                          <GreenSpan className=" pt-3 light-font-text fs-20 ">
                            <span>
                              Mads Thorkildsen and Caroline Thorkildsen{" "}
                            </span>
                          </GreenSpan>
                          <p className="created-by">
                            <span>
                              Created Sep 1, 2020 by Johannes Schmidt Larsen
                            </span>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* //........................Image Part ......................................// */}

                  {/* <div style={{ alignItems: "center" }}> */}
                  {/* <Image src={twohand} responsive fluid /> */}
                  {/* <Image src={twoHandPng} responsive fluid /> */}
                  {/* </div> */}
                </Col>
              </Row>
              <div className="mt-5 py-2 px-3 d-sm-flex justify-content-between footer-section-button-container">
                <div>
                  <ETButton
                    variant={"white"}
                    buttonText="COLD FEET"
                    //  onclick ={()=>gotoUnitsPage}
                    onClick={() => setReservedToLeasedPage(false)}
                  />
                </div>
                <div className="m-1">
                  <ETButton
                    variant={"primary"}
                    buttonText="BECOME A CHAMPION"
                    onClick={championPage}
                  />
                </div>
              </div>
            </Container>
          ) : (
            <Container
              fluid
              className="px-0"
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: " 0 0 8px 8px",
                // border: "1px solid #F4F5F5",
              }}
            >
              <Row style={{ textAlign: "center" }} className="px-0">
                <Col md={{ span: 8, offset: 2 }}>
                  <Row className="d-flex justify-content-center">
                    <Col xl={10}>
                      <h1 className="pt-5 fs-32 form-label-title">
                        Yay! Pop the champagne, champion!{" "}
                      </h1>
                      <div className="mx-3 px-lg-5 light-font-text">
                        <p>
                          Congratulations. You made it! The unit has now been
                          leased by the customers! There are few better things
                          than reaching your goals. Now on to the next one...
                        </p>
                      </div>

                      {/* ------------------data showing after creating Property---------------------------- */}

                      <div className=" mx-2 px-lg-5  ">
                        <div style={{ textAlign: "center" }}>
                          <div className="fs-18 light-font-text">
                            <GreenSpan className="fs-20 form-label-title ">
                              You are on a strike.
                            </GreenSpan>{" "}
                            You are on a strike. This is your{" "}
                            <span className="form-label-title fs-18">
                              3rd rental
                            </span>{" "}
                            this month.In total you have made{" "}
                            <span className="form-label-title fs-18">
                              12 rentals{" "}
                            </span>
                            this year!
                          </div>
                        </div>
                      </div>

                      {/* //........................Image Part ......................................// */}

                      <div style={{ alignItems: "center" }}>
                        <Image src={Trophy} responsive fluid />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="py-2 px-3 d-sm-flex justify-content-end footer-section-button-container">
                <div
                  className="d-flex"
                  style={{ alignItems: "center", fontWeight: "300" }}
                >
                  {" "}
                  <span>To see the status of the unit, click</span>
                </div>

                <div className="m-1">
                  <ETButton
                    variant={"primary"}
                    buttonText="GO TO UNITS"
                    onClick={() => setReservedToLeasedPage(false)}
                  />
                </div>
              </div>
            </Container>
          )}
        </>
      </CustomPopup>
      {/* status changes to reseverd */}
      <CustomPopup
        open={availableToReseverdPop}
        onHide={() => setAvailableToReseverdPop(false)}
        headerRequired={false}
        size="lg"
      >
        <Row className=" mt-5">
          <Col className="text-center">
            <div className="fs-32 fw-600 ">
              Hey! Congratulation on the new reservation
            </div>
            <Row className="d-flex justify-content-center mt-4">
              <Col lg={8}>
                <div className="fs-18 fw-300" style={{ height: "300px" }}>
                  Before you can reserve this unit you have to fill in some
                  customer information and details about the reservation.
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setAvailableToReseverdPop(false)}
          />
          <NavLink
            to="/create-new-reservation"
            state={{ propId: propertyId }}
            // style={{ textDecoration: "none", color: "black" }}
          >
            <ETButton
              variant={"primary"}
              buttonText="CONTINUE"
              onClick={() => setAvailableToReseverdPop(false)}
            />
          </NavLink>
        </div>
      </CustomPopup>
      {/* delete single unit */}
      <CustomPopup
        open={false}
        // onHide={() => setShow(false)}
        title="Delete portfolio"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-3 fs-14 fw-300">
          <p>You are deleting this property.</p>
          <p>Are you sure you want to delete?</p>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton variant={"white"} buttonText="CANCEL" onClick={false} />
          <ETButton variant={"primary"} buttonText="DELETE" onClick={false} />
        </div>
      </CustomPopup>
      {/* {annouonce popup} */}
      <CustomPopup
        open={showAnnounce}
        onHide={() => setShowAnnounce(false)}
        title="Announced"
        title_color="black"
        titleFont="14px"
        titleTooltip={true}
        titleTooltipText=""
        size="lg"
        enableCrossButton={true}
        onCloseAction={() => {
          setShowAnnounce(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="py-3 px-4 fs-14 fw-300">
          <div className="form-label-title">Headline</div>
          <p className="light-font-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setShowAnnounce(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="SAVE"
            disabled={UpdateUnitAnnounceStatusMutateIsLoading}
            onClick={() =>
              handleUpdateUnitAnnounceStatus(
                row?.original?.unit_id,
                row?.original?.is_listed
              )
            }
          />
        </div>
      </CustomPopup>
    </div>
    // </div>
  );
};

export default UnitsTable;

//Drop down component
const DropDown = ({
  propertyId,
  value,
  getTableUnitsByPropertyId,
  setReservedToLeasedPage,
  setBecomeChampPage,
  setAvailableToReseverdPop,
}) => {
  //------------------ZuuStand-------------------------//
  const { setUnitId } = useUnitId((state) => state);
  const { setStatusIdsForReservation } = useReservationStore((state) => state);

  const [statusesData, setStatusesData] = useState([]);
  //-----------react query-------------//
  const { data: unitStatusesData } = useQuery(
    ["unitStatuses", propertyId, value?.property_type_id],
    () => GetUnitStatuses(propertyId, value?.property_type_id)
  );
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{
        color: value.font_color,
        whiteSpace: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        fontFamily: "Cerebri sans-medium",
        cursor: "pointer",
        textAlign: "center",
        fontSize: "12px",
      }}
    >
      <div
        style={{
          backgroundColor: `${value.label_color}77`,
          height: "16px",
          padding: "0px 10px",
          borderRadius: "4px 4px 0px 0px",
          textTransform: "uppercase",
        }}
      >
        {children}
      </div>
      <div
        style={{
          backgroundColor: value.label_color,
          height: "16px",
          padding: "0px 10px",
          borderRadius: "0px 0px 4px 4px",
        }}
      >
        Waiting for landlord signing
      </div>
    </div>
  ));
  const handleDropdownClick = (statusId, e) => {
    const newData = [...unitStatusesData?.data];
    const filteredData = newData
      ?.filter((item) => item?.Id === statusId)[0]
      ?.DataValue1.split(",");
    const dropDownData = newData?.filter((el) =>
      filteredData?.includes(el.Id.toString())
    );
    setStatusesData(dropDownData);
  };

  const handleStatusClick = async (statusId, statusName) => {
    setStatusIdsForReservation({
      from_status_id: value?.status_id,
      to_status_id: statusId,
    });
    setBecomeChampPage(false);

    if (statusName !== "Reserved" && statusName !== "Hidden") {
      const data = {
        unit_id: value?.unit_id,
        status_id: statusId,
      };
      const response = await PutSetUnitStatus(data);
      if (response.status == "success") {
        // getTableUnitsByPropertyId();
        toast.success(response.data.message);
      }
    } else if (statusName == "Hidden") {
      setReservedToLeasedPage(true);
    } else {
      setAvailableToReseverdPop(true);
      setUnitId(value?.unit_id);
    }
  };
  const popperConfig = {
    strategy: "fixed",
  };

  return (
    <>
      <Dropdown
        onClick={(e) => handleDropdownClick(value?.status_id, e)}
        drop="up"
      >
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {value.status}
        </Dropdown.Toggle>

        {/* {value.status === "Reserved" && ( */}
        <Dropdown.Menu
          className="fs-12 light-font-text"
          align={"end"}
          popperConfig={popperConfig}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {statusesData?.map((item, i) => (
            <Dropdown.Item
              key={i}
              eventKey={item?.Id}
              onClick={() => handleStatusClick(item?.Id, item?.Text)}
            >
              {item?.Text}
            </Dropdown.Item>
          ))}

          {/* <Dropdown.Item eventKey="2">AVAILABLE</Dropdown.Item>
              <Dropdown.Item eventKey="3" onClick={handleClickLeased}>
                LEASED
              </Dropdown.Item> */}
        </Dropdown.Menu>
        {/* )} */}
      </Dropdown>
    </>
  );
};
