import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useCompanyId,
  useEditCompany,
} from "../../hooks/stores/useCompanyStore";
import { useAllPropertiesData } from "../../hooks/stores/usePropertyStore";
import { useOrganizationId } from "../../hooks/stores/useOrganizationStore";
import {
  useDeleteCompany,
  useGetCompanyByCompanyId,
  useGetCompanyByLevel,
  usePostAddCompany,
  useUpdateCompany,
} from "../../hooks/queries/useCompanyQueries";
import { capitalizeEachWord } from "../../utils/TextTransform";
import {
  ErrorAlert,
  LoadingAlert,
  SucessAlert,
} from "../../Components/alerts/AlertsAndMessages";
import { status } from "../../model/Constant";
import CreateNewInformation from "../../Components/wizard/CreateNewInformation";
import CreateBillingDetails from "../../Components/wizard/CreateBillingDetails";
import { billingDetailsDropdownData } from "../../model/commonDropdownData";
import { toast } from "react-toastify";
import NewColorBox from "../../Components/ColorCombinationbox/NewColorBox";
import { H2, StyledLoadingBar } from "../../styles/Common.style";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import MRTTheme from "../../Components/table/MRTTheme";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import {
  SearchIcon,
  SearchStyle,
  paginationoptions,
  SortIcon,
} from "../../Components/table/MRTGlobalProps";
import { Col, Row } from "react-bootstrap";
import DropdownModalMui from "../../Components/DropdownModal/DropdownModalMui";
import GeographyDropdown from "../properties/geographyDropdown/GeographyDropdown";
import ETButton from "../../Components/Buttons/ETButton";
import Menus from "../../Components/Menus/Menus";
import CustomPopup from "../../Components/CustomPopup";
import LoadingBar from "react-top-loading-bar";
import { customproperties } from "../../styles/Theme";
import { MenuItem } from "@mui/material";
import { useDivisionId } from "../../hooks/stores/useDivisionsStore";
import SelectDivision from "./SelectDivision";
import { useQueryClient } from "react-query";

const CompaniesMainTable = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  //---------------------------states---------------------//

  const [openDeleteCompany, setOpenDeleteCompany] = useState(false);
  const [deleteCompanyData, setDeleteCompanyData] = useState("");
  const [deletePropertiesCount, setDeletePropertiesCount] = useState(false);
  const [closeDropdown, setCloseDropdown] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  // console.log(rowCount,"rowCount")

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [checkedCountry, setCheckedCountry] = useState([]);
  const [optionCheckedZipcode, setOptionCheckedZipcode] = useState([]);
  const [zipCode, setZipCode] = useState([]);

  const [selectedRegionID, setSelectedRegionID] = useState([]);
  const [allCheckedRegions, setAllCheckedRegions] = useState(false);
  const [allCheckedZipcode, setAllCheckedZipcode] = useState(false);

  const [showWizard, setShowWizard] = useState(false);

  // const [enableButton , setEnableButton] = useState(false)
  const [progress, setProgress] = useState(55);
  const [stepWizard, setStepWizard] = useState("SelectDivision");
  const [isSameDetailAsAboveActive, setIsSameDetailAsAboveActive] =
    useState(false);
  const [companyData, setCompanyData] = useState({});
  const [isCompanyEdited, setIsCompanyEdited] = useState(false);
  const [duplicateTableData, setDuplicateTableData] = useState([]);
  const [selectedZipCode, setSelectedZipCode] = useState([]);
  const [sorting, setSorting] = useState([]);
  //---------------------------Zustand---------------------//
  const { divisionId } = useDivisionId((state) => state);
  const setCompaniesName = useCompanyId((state) => state.setCompaniesId);
  const { setAllPropertiesStoreData, allPropertiesStoreData } =
    useAllPropertiesData((state) => state);

  useEffect(() => {
    setCloseDropdown(false);
  }, [closeDropdown]);
  let filteredData;
  let noOfRows;
  // ----------------- zustand -------------------------- ----//
  const { organizationId } = useOrganizationId((state) => state);

  //---------------------------react query---------------------//
  const { mutateAsync: DeleteCompany, isLoading: DeleteCompanyIsLoading } =
    useDeleteCompany();
  const { setCompanyId } = useEditCompany((state) => state);
  const { data: GetCompanyByLevelData, refetch: GetCompanyByLevelRefetch } =
    useGetCompanyByLevel("d", divisionId, "details");
  const {
    data: GetCompanyByCompanyIdData,
    refetch: GetCompanyByCompanyIdRefetch,
  } = useGetCompanyByCompanyId(deleteCompanyData);
  const { mutateAsync: PostCompanyData, isLoading: IsLoadingPostCompanyData } =
    usePostAddCompany();
  const { mutateAsync: updateCompany, isLoading: IsLoadingUpdateCompany } =
    useUpdateCompany();

  // console.log(deleteCompanyData, 'GetCompanyByCompanyId')
  //--------------------------------useeffect-----------------------------//
  useEffect(() => {
    setTableData(allPropertiesStoreData);
    setRowCount(allPropertiesStoreData?.length);
  }, [allPropertiesStoreData]);

  useEffect(() => {
    if (
      isCompanyEdited &&
      Object.keys(GetCompanyByCompanyIdData ?? {}).length
    ) {
      const APIcompanyData = GetCompanyByCompanyIdData?.data?.[0];
      setCompanyData({
        ...companyData,
        div_name: "",
        company: APIcompanyData?.company_name,
        company_id: APIcompanyData?.company_id,
        billing_method: {
          value: APIcompanyData?.billing_method_applicable_id,
          label: billingDetailsDropdownData?.filter(
            (data) =>
              data?.Id?.toString() ===
              APIcompanyData?.billing_method_applicable_id?.toString()
          )?.[0]?.Text,
        },
        care_of: APIcompanyData?.care_of,
        short_name: APIcompanyData?.shortname,
        vat_number: APIcompanyData?.public_id1,
        gst_number: APIcompanyData?.public_id2,
        cvr_number: APIcompanyData?.public_id3,
        street: APIcompanyData?.street_name,
        house_number: APIcompanyData?.house_number,
        floor_number: {
          value: APIcompanyData?.floor_id,
          label: APIcompanyData?.floor_name,
          isDisabled: true,
        },
        division_id: {
          value: APIcompanyData?.division_id,
          label: APIcompanyData?.division_name,
          isDisabled: true,
        },
        side: {
          value: APIcompanyData?.side_id,
          label: APIcompanyData?.side_name,
          isDisabled: true,
        },
        country: {
          value: APIcompanyData?.country_id,
          label: APIcompanyData?.country,
          isDisabled: true,
        },
        door: APIcompanyData?.door,
        zipcode_id: {
          value: APIcompanyData?.zipcode_id,
          label: APIcompanyData?.city_name,
          isDisabled: true,
        },
        contact_person_name: APIcompanyData?.contact_person_name,
        website: APIcompanyData?.website_url,
        country_code: APIcompanyData?.contact_person_isd_code,

        city: APIcompanyData?.city_name,
        contact_person_phone_no: APIcompanyData?.contact_person_phone_no,
        contact_person_email: APIcompanyData?.contact_person_email,
        bInfo_company: APIcompanyData?.billing_details[0]?.company_name,
        bInfo_care_of: APIcompanyData?.billing_details[0]?.care_of,
        bInfo_cvr_number: APIcompanyData?.billing_details[0]?.public_id3,
        bInfo_vat_number: APIcompanyData?.billing_details[0]?.public_id1,
        bInfo_gst_number: APIcompanyData?.billing_details[0]?.public_id2,
        bInfo_street: APIcompanyData?.billing_details[0]?.street_name,
        bInfo_house_number: APIcompanyData?.billing_details[0]?.house_number,
        bInfo_floor_number: {
          value: APIcompanyData?.billing_details[0]?.floor_id,
          label: APIcompanyData?.billing_details[0]?.floor_name,
          disabled: true,
        },
        bInfo_side: {
          value: APIcompanyData?.billing_details?.[0]?.side_id,
          label: APIcompanyData?.billing_details?.[0]?.side_name,
          disabled: true,
        },
        bInfo_door: APIcompanyData?.billing_details[0]?.door,
        bInfo_country: {
          value: APIcompanyData?.billing_details?.[0]?.country_id,
          label: APIcompanyData?.billing_details?.[0]?.country,
          disabled: true,
        },
        bInfo_city: APIcompanyData?.billing_details[0]?.city,
        invoice_email: APIcompanyData?.billing_details[0]?.invoice_email,
        attention_person:
          APIcompanyData?.billing_details[0]?.attention_person_name,
        ean_number: APIcompanyData?.billing_details[0]?.ean,
        bInfo_zipCode: {
          value: APIcompanyData?.billing_details[0]?.zipcode_id,
          label: APIcompanyData?.billing_details[0]?.city,
          disabled: true,
        },
      });
      setIsSameDetailAsAboveActive(
        APIcompanyData?.is_same_detail_as_defined_general === "1" ? 1 : 0
      );
    } else {
      setCompanyData({});
    }
  }, [isCompanyEdited, GetCompanyByCompanyIdData, showWizard]);

  useEffect(() => {
    // setAllPropertiesStoreData(
    //   GetCompanyByLevelData?.data
    //     .sort((a, b) => a.propertyName?.localeCompare(b.propertyName))
    //     .map((item, i) => item)

    // );
    setAllPropertiesStoreData(GetCompanyByLevelData?.data);
  }, [GetCompanyByLevelData]);

  const renderEmptyRowsFallback = () => {
    if (tableData && tableData?.length === 0) {
      return (
        <div
          className="d-flex justify-content-center w-100 grey-text py-4 radius-4"
          style={{
            backgroundColor: "#F9FAFA",
            border: "1px solid #d1d1d1",
          }}
        >
          <>
            <div className="table-message-text">
              {" "}
              You do not have any companies added yet. Click on “+ CREATE
              COMPANY” to create your first company!
            </div>
          </>
        </div>
      );
    } else {
      return (
        <div
          className="d-flex justify-content-center w-100 grey-text py-4 radius-4"
          style={{
            backgroundColor: "#F9FAFA",
            border: "1px solid #d1d1d1",
          }}
        >
          <>
            <div className="table-message-text">
              {" "}
              Sorry! No result found. Please try again!
            </div>
          </>
        </div>
      );
    }
  };
  const handleAddCompany = () => {
    // setShowCompanyPage("AC");
    setIsCompanyEdited(false);
    setCompanyId(0);
    setIsSameDetailAsAboveActive(false);
    setCompanyData({});
    setShowWizard(true);
    setProgress(25);
  };

  const handleLinkCompany = (company_name, companyId) => {
    setCompanyId(companyId);
    setCompaniesName(company_name);
    navigate("/company/details", {
      state: {
        Tab: "Details",
        companyName: company_name,
        editCompanyId: companyId,
        editOrganizationId: organizationId,
      },
    });
  };

  const handleEditCompany = (companyId, company_name, flag) => {
    GetCompanyByCompanyIdRefetch();
    setIsSameDetailAsAboveActive(false);
    setStepWizard("CreateNewInfo");
    setShowWizard(true);
    setProgress(55);
    setCompanyId(companyId);
    setCompaniesName(company_name);
    // navigate("/company/details", {
    //   state: {
    //     editCompanyId: companyId,
    //     companyName: company_name,
    //     editOrganizationId: organizationId,
    //     Tab: "Details",
    //   },
    // });
    setIsCompanyEdited(true);
  };

  const handleDeleteCompanies = async () => {
    let id;
    try {
      const response = await DeleteCompany(deleteCompanyData, {onSuccess:()=>{
        queryClient.invalidateQueries("qGetDivisionOverviewByDivisionId")
      }});
      if (!DeleteCompanyIsLoading) {
        id = toast.loading(<LoadingAlert />);
      }
      if (response.status == status.s && response.data.bReturn) {
        GetCompanyByLevelRefetch();
        toast.update(id, {
          render: <SucessAlert />,
          isLoading: false,
          type: status.s,
          autoClose: 2000,
        });
      } else {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.update(id, {
        render: <ErrorAlert />,
        isLoading: false,
        type: status.e,
        autoClose: 2000,
      });
    }
  };

  //APPLY GEOGRAPHY DROPDOWN LOGIC //
  const propertiesTableData = useMemo(() => {
    const zipcodeToCheck = String(selectedZipCode); // Convert to string if not already
    if (zipcodeToCheck !== "") {
      return tableData?.filter((data) =>
        selectedZipCode?.includes(data?.zipcode_id)
      );
    } else {
      return tableData;
    }
  }, [tableData, selectedZipCode]);

  const applyGeography = (optionCheckedZipcode) => {
    setSelectedZipCode(optionCheckedZipcode);
    setCloseDropdown(true);
  };

  // Reset geography dropdown //
  const ResetAll = () => {
    setCheckedCountry([]);
    setOptionCheckedZipcode([]);
    setSelectedRegionID([]);
    setZipCode([]);
    setAllCheckedZipcode(false);
    setAllCheckedRegions(false);
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) =>
          `${row.company_name} ${row.zipcode_id} ${row.city_name}`,
        header: "Company",
        enableHiding: false,
        size: 140,
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "24px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "0px !important",
            borderLeft: "1px solid #D1D1D1 !important",
            borderRight: "1px solid #D1D1D1 !important",
            paddingLeft: "24px !important",
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
          },
        },
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div
            className="d-flex gap-3 cursor-pointer"
            style={{
              width: "140px",
            }}
          >
            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <div className="d-flex flex-row justify-content-start align-items-start gap-3 ">
              <div
                style={{ width: "180px" }}
                className="d-flex flex-column pt-1"
                onClick={() => {
                  handleLinkCompany(
                    row?.original?.company_name,
                    row.original.company_id
                  );
                }}
              >
                <span className="table-property-name ellipsis" id="property">
                  {row.original.company_name}
                  {/* {row.original.company_name?.length > 20
                      ? `${row.original.company_name.substring(0, 18)}...`
                      : row.original.company_name} */}
                </span>
                <span className="fs-11 blackColor">
                  {row.original.zipcode_id} {row.original.city_name}
                </span>
              </div>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "properties_count",
        header: "Properties",
        size: 110,
        enableGlobalFilter: true,
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "2px !important",
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
            paddingLeft: "24px !important",
          },
        },
        muiTableHeadCellProps: {
          sx: {
            paddingRight: "2px !important",
            paddingBottom: "0px !important",
            paddingLeft: "24px !important",
          },
        },
        Cell: ({ row }) => {
          return (
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex  align-items-center gap-2">
                {row.original.properties_count?.toString() !== "0" ? (
                  <div>{row.original?.properties_count}</div>
                ) : (
                  <span style={{ color: "#888888" }}>No properties</span>
                )}
                {parseInt(row.original?.properties_count) > 1
                  ? "properties"
                  : row.original?.properties_count?.toString() === "1"
                  ? "property"
                  : " "}
              </div>
              <TableDataBorderRight />
            </div>
          );
        },
      },
      {
        accessorKey: "unit_count",
        header: "Units",
        enableHiding: false,
        size: 90,
        enableGlobalFilter: false,
        muiTableHeadCellProps: {
          align: "left",
          sx: {
            paddingLeft: "24px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "24px !important",
            paddingRight: "2px !important",
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
          },
        },
        Cell: ({ row }) => {
          return (
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex  align-items-center gap-2">
                {row.original.unit_count?.toString() !== "0" ? (
                  row.original.unit_count
                ) : (
                  <span style={{ color: "#888888" }}>No units</span>
                )}
              </div>
              <TableDataBorderRight />
            </div>
          );
        },
      },
      {
        accessorFn: (row) => `${row.available} ${row.available_percentage} `,
        header: "Availibility",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: false,
        muiTableHeadCellProps: {
          align: "left",
          sx: {
            paddingLeft: "24px !important",
            paddingRight: "2px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "24px !important",
            paddingRight: "2px !important",
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
          },
        },
        Cell: ({ row }) => (
          <div className="d-flex justify-content-between align-items-center">
            <div>
              {row?.original?.available?.toString() !== "0"
                ? row?.original?.available
                : "0"}{" "}
              |{" "}
              {row?.original?.available_percentage?.toString() !== "0"
                ? row?.original?.available_percentage
                : "0"}
              %
            </div>
            <TableDataBorderRight />
          </div>
        ),
      },
      {
        accessorFn: (row) => `${row.idleness} ${row.idleness_percentage} `,
        header: "Idleness",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,
        muiTableHeadCellProps: {
          align: "left",
          sx: {
            paddingLeft: "24px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "24px !important",
            paddingRight: "0px !important",
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
          },
        },
        Cell: ({ row }) => (
          <div className="d-flex justify-content-between align-items-center">
            <div>
              {row?.original?.idleness?.toString() !== "0"
                ? row?.original?.idleness
                : "0"}{" "}
              |{" "}
              {row?.original?.idleness_percentage?.toString() !== "0"
                ? row?.original?.idleness_percentage
                : "0"}
              %
            </div>
            <TableDataBorderRight />
          </div>
        ),
      },
      {
        accessorFn: (row) =>
          `${row.not_active} ${row.available} ${row.reserved}${row.in_process} ${row.completed}${row.units}`,
        header: "Units overview",
        disableSortBy: true,
        enableGlobalFilter: false,
        enableSorting: false,
        size: 180,
        muiTableHeadCellProps: {
          align: "left",
          sx: {
            paddingLeft: "24px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "24px !important",
            paddingRight: "0px !important",
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
          },
        },
        Cell: ({ row }) => (
          <NewColorBox
            unitsOverview={row?.original?.overall_status}
            TotalUnits={row?.original?.unit_count}
          />
        ),
      },
    ],
    [tableData]
  );

  // ------------------- handle select company ------------//
  // const handleSelectCompanyNext = () => {
  //   setStepWizard("CreateNewInfo");
  //   setProgress(55);
  // };

  // const handleSelectCompanyClose = () => {
  //   setShowWizard(false);
  //   setProgress(55);
  // };

  //---------------------handlers------------------------//
  const handleCreateNewInfoNext = (data) => {
    setStepWizard("CreateNewBillingDetails");
    setProgress(99.99);
    setCompanyData({
      ...companyData,
      ...data,
    });
  };

  const handleCreateNewInfoBack = () => {
    if (isCompanyEdited) {
      setIsSameDetailAsAboveActive(false);
      setCompanyData({});
      setStepWizard("SelectDivision");
      return setShowWizard(false);
    } // if edit mode is on we don't won't to show select division popup
    setProgress(33.33);
    setStepWizard("SelectDivision");
  };

  // -------------- handle create new billing details -----------
  const handleCreateNewBillingDetailsNext = async (data) => {
    let id;
    let companyDetailsPlayload = {
      name: capitalizeEachWord(data?.company),
      shortname: data?.short_name?.toUpperCase(),
      care_of: capitalizeEachWord(data?.care_of),
      public_type_id1: "1",
      public_id1: data?.vat_number,
      public_type_id2: "1",
      public_id2: data?.gst_number,
      public_type_id3: "1",
      public_id3: data?.cvr_number,
      street_name: data?.street,
      house_number: data?.house_number,
      floor_id: data?.floor_number?.value?.toString(),
      side_id: data?.side?.value?.toString() ?? "",
      door: data?.door,
      zipcode_id: data?.zipcode_id?.value || "",
      country_id: data?.country?.value || "",
      contact_person_name: capitalizeEachWord(data?.contact_person_name),
      website_url: data?.website,
      contact_person_email: data?.contact_person_email?.toLowerCase(),
      contact_person_isd_code: data?.country_code || "",
      contact_person_phone_no: data?.contact_person_phone_no,
      is_same_detail_as_defined_general: isSameDetailAsAboveActive ? "1" : "0",
      billing_method_applicable_id: data?.billing_method?.value || "",
      billing_details:
        data?.billing_method?.value?.toString() === "C"
          ? {
              company_name: capitalizeEachWord(data?.bInfo_company),
              care_of: capitalizeEachWord(data?.bInfo_care_of),
              street_name: data?.bInfo_street,
              house_number: data?.bInfo_house_number,
              door: data?.bInfo_door,
              floor_id: data?.bInfo_floor_number?.value?.toString() || "",
              side_id: data?.bInfo_country?.value || "", // Fix syntax error here
              zipcode_id: data?.bInfo_zipCode?.value || "",
              country_id: data?.bInfo_country?.value || "",
              public_id1: data?.bInfo_vat_number,
              public_id2: data?.bInfo_gst_number,
              public_id3: data?.bInfo_cvr_number,
              public_type_id1: "1",
              public_type_id2: "1",
              public_type_id3: "1",
              ean_number: data?.ean_number,
              invoice_email: data?.invoice_email?.toLowerCase(),
              attention_person: capitalizeEachWord(data?.attention_person),
            }
          : {},
    };

    if (isCompanyEdited) {
      const editCompany = {
        company_id:
          GetCompanyByCompanyIdData?.data?.[0]?.company_id?.toString(),
        ...companyDetailsPlayload,
      };

      if (!IsLoadingUpdateCompany) {
        id = toast.loading(<LoadingAlert />);
      }
      try {
        const response = await updateCompany(editCompany);
        if (response.status === status.s && response.data.bReturn) {
          toast.update(id, {
            // render: <SucessAlert />,
            isLoading: false,
            type: status.s,
            autoClose: 200,
          });
          setStepWizard("SelectDivision");
          setShowWizard(false);
          setProgress(0);
          GetCompanyByLevelRefetch();
          setIsSameDetailAsAboveActive(false);
        } else {
          toast.update(id, {
            render: <ErrorAlert />,
            isLoading: false,
            type: status.e,
            autoClose: 2000,
          });
        }
      } catch (error) {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    } else {
      const createCompany = {
        division_id: divisionId?.toString(),
        ...companyDetailsPlayload,
      };

      if (!IsLoadingPostCompanyData) {
        id = toast.loading(<LoadingAlert />);
      }
      try {
        const response = await PostCompanyData(createCompany, {
          onSuccess: () => {
            queryClient.invalidateQueries("qGetDivisionOverviewByDivisionId");
          },
        });
        if (response.status === status.s && response.data.bReturn) {
          toast.update(id, {
            // render: <SucessAlert />,
            isLoading: false,
            type: status.s,
            autoClose: 200,
          });

          setStepWizard("SelectDivision");
          setProgress(0);
          setShowWizard(false);
          // setCompanyData({});
          GetCompanyByLevelRefetch();
          setIsSameDetailAsAboveActive(false);
        } else {
          toast.update(id, {
            render: <ErrorAlert />,
            isLoading: false,
            type: status.e,
            autoClose: 2000,
          });
        }
      } catch (error) {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    }
  };

  const handleCreateNewBillingDetailsBack = () => {
    setStepWizard("CreateNewInfo");
    setProgress(66.66);
  };

  //----------- handle create company ---------------//
  const handleCreateCompanyClose = () => {
    setProgress(55);
    setStepWizard("CreateNewInfo");
    setCompanyData({});
    setShowWizard(false);
  };

  const props = {
    flag: "Company",
    setShow: setShowWizard,
    setStepWizard: setStepWizard,
    setProgress,

    progress,
    handleNext: handleCreateNewInfoNext,
    handleClose: handleCreateNewInfoBack,
    formData: companyData,
    setFormData: setCompanyData,
    isSameDetailAsAboveActive,
    setIsSameDetailAsAboveActive,
    handlePrevious: handleCreateNewBillingDetailsBack,
    handleAddDataFun: handleCreateNewBillingDetailsNext,
    createButtonDisable: IsLoadingPostCompanyData || IsLoadingUpdateCompany,

    flagEdit: isCompanyEdited,
    handleCloseButton: handleCreateCompanyClose,
    name: companyData?.company,
    address: `${companyData?.street || ""}, ${
      companyData?.zipcode_id?.value || ""
    }, ${companyData?.city || ""}`,
  };

  // -------------------handle Select Company Close------------//
  const handleSelectCompanyClose = () => {
    setShowWizard(false);
    setProgress(0);
  };

  // ------------------- handle select company ------------//
  const handleSelectCompanyNext = () => {
    setStepWizard("CreateNewInfo");
    setProgress(66.66);
  };
  const WizardsPages = {
    SelectDivision: (
      <SelectDivision
        handleNext={handleSelectCompanyNext}
        handleClose={handleSelectCompanyClose}
        formData={companyData}
        setFormData={setCompanyData}
        flag="Company"
      />
    ),
    CreateNewInfo: <CreateNewInformation {...props} />,
    CreateNewBillingDetails: <CreateBillingDetails {...props} />,
    // CreateClientStepFour: <CreateClientStepFour {...props} />,
  };

  return (
    <div className="p-4 mb-4 containerStyle mt-1">
      <div className="whiteContainerStyle">
        <div className="p-4 border-bottom">
          <div className="d-flex align-items-center  gap-2">
            <H2>Companies </H2>
            <CustomToolTip size="sm" text="Companies" />
          </div>
        </div>{" "}
        <div className="p-4">
          <MRTTheme
            colorVariantsForTableRow={["gray"]}
            colorVariantsForTableHead={["white"]}
            table={
              <MaterialReactTable
                autoResetPageIndex={false}
                columns={columns?.map((columns) => {
                  return {
                    ...columns,
                    sortDescFirst: false,
                  };
                })}
                renderEmptyRowsFallback={renderEmptyRowsFallback}
                data={propertiesTableData || []} //data is undefined on first render
                icons={{
                  ArrowDownwardIcon: () => SortIcon?.ArrowDownwardIcon(sorting),
                  SyncAltIcon: () => SortIcon?.SyncAltIcon(),
                }}
                state={{
                  sorting: sorting,
                }}
                onSortingChange={setSorting}
                enableColumnActions={false}
                enableBottomToolbar={true}
                globalFilterFn="contains"
                muiSearchTextFieldProps={{
                  ...SearchStyle,

                  InputProps: {
                    ...SearchIcon,
                  },
                }}
                getRowId={(originalRow) => originalRow?.company_id}
                //give each row a more useful id
                enableSelectAll={false}
                enableRowActions
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    header: "",
                    size: 40,
                    muiTableBodyCellProps: {
                      sx: {
                        paddingRight: "0px !important",
                        borderTop: "1px solid #D1D1D1 !important",
                        borderBottom: "1px solid #D1D1D1 !important",
                        borderRight: "1px solid #D1D1D1 !important",
                        // alignItems: "right",
                      },
                    },
                  },
                }}
                muiTablePaperProps={{
                  elevation: 0,
                  sx: {
                    table: {
                      borderCollapse: "separate",
                      borderSpacing: "0 8px",
                    },

                    td: {
                      fontFamily: "Cerebri sans-light !important",
                      fontSize: "14px",
                    },
                  },
                }}
                muiTableHeadRowProps={{
                  sx: {
                    // ...TableRowsStyle,
                    background: "#FFFFFF",
                  },
                }}
                muiTableContainerProps={{
                  elevation: 0,
                  sx: {
                    background: "white",
                    padding: "0px !important",
                  },
                }}
                renderTopToolbar={({ table }) => (
                  <>
                    <div className=" bg-white">
                      <Row className="justify-content-between">
                        <Col xs={4} lg={2} xxl={2} className="pe-1">
                          <DropdownModalMui
                            text="Geography"
                            width="700px"
                            closeDropdown={closeDropdown}
                            component={
                              <GeographyDropdown
                                applyGeography={applyGeography}
                                ResetAll={ResetAll}
                                checkedCountry={checkedCountry}
                                setCheckedCountry={setCheckedCountry}
                                optionCheckedZipcode={optionCheckedZipcode}
                                setOptionCheckedZipcode={
                                  setOptionCheckedZipcode
                                }
                                selectedRegionID={selectedRegionID}
                                setSelectedRegionID={setSelectedRegionID}
                              />
                            }
                          />
                        </Col>
                        <Col className="d-flex gap-3 justify-content-end">
                          <Row>
                            <Col lg={{ span: 10, offset: 2 }}>
                              <MRT_GlobalFilterTextField table={table} />
                            </Col>
                          </Row>
                          <div className="d-flex justify-content-end">
                            <div className="">
                              <ETButton
                                variant={"primary"}
                                buttonText="CREATE COMPANY"
                                icon={true}
                                onClick={handleAddCompany}
                                width="auto"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
                // muiTableContainerProps={{
                //   elevation: 0,
                //   sx: {
                //     background: "white",
                //   },
                // }}
                positionActionsColumn="last"
                initialState={{
                  showGlobalFilter: true,
                  showColumnFilters: false,
                }}
                muiTablePaginationProps={{
                  ...paginationoptions,
                }}
                renderRowActions={({ row, table }) => (
                  <>
                    <Menus
                      itemheight={24}
                      itemwidth={24}
                      iconborderradius="4px"
                      itemcontainerwidth="150px"
                      component={
                        <>
                          <MenuItem
                            key="edit"
                            sx={FontStyleOnMenuItem}
                            onClick={() => {
                              setDeleteCompanyData(row?.original?.company_id);

                              handleEditCompany(
                                row.original.company_id,
                                row.original.company_name,
                                "edit"
                              );
                            }}
                          >
                            Edit company
                          </MenuItem>
                          <hr className="hr m-0"></hr>
                          <MenuItem
                            key="delete"
                            sx={FontStyleOnMenuItem}
                            onClick={() => {
                              handleLinkCompany(
                                row?.original?.company_name,
                                row.original.company_id
                              );
                            }}
                          >
                            Go to company
                          </MenuItem>
                          <hr className="hr m-0"></hr>
                          <MenuItem
                            key="delete"
                            sx={FontStyleOnMenuItem}
                            onClick={() => {
                              setOpenDeleteCompany(true);
                              setDeleteCompanyData(row?.original?.company_id);
                              setDeletePropertiesCount(
                                row?.original?.properties_count > 0
                              );
                            }}
                          >
                            Delete company
                          </MenuItem>
                        </>
                      }
                    />
                  </>
                )}
              />
            }
          />
        </div>
        <div>
          {" "}
          <CustomPopup
            style={{ position: "relative" }}
            size="xl"
            open={showWizard}
            title={
              `${isCompanyEdited ? "Edit company" : "Create company"}`
              // (flag == "organization" && "Create division") ||
              // (flag == "division" && "Create company") ||
              // (flag == "company" && "Create property")
            }
            title_color="black"
            titleTooltipText={`${
              isCompanyEdited ? "Edit company" : "Create company"
            }`}
            titleTooltip={true}
            enableCrossButton={true}
            onClickCrossButton={() => {
              setStepWizard("SelectDivision");
              setProgress(0);
              setShowWizard(false);
              setIsSameDetailAsAboveActive(false);
              setCompanyData({});
            }}
            action_button_label="YES"
            action_button_label_color="white"
            action_button_color="#619130"
            cancel_button={true}
            cancel_button_bordercolor="grey"
            cancel_button_label="NO"
            cancel_button_label_color="black"
            cancel_button_color="#fff"
            footer_color={"#F9FAFA"}
          >
            <div>
              <StyledLoadingBar>
                <LoadingBar
                  color={customproperties.primaryColor}
                  progress={progress}
                  containerStyle={{
                    position: "absolute",
                    height: "2.5px",
                  }}
                  shadow={false}
                  onLoaderFinished={() => setProgress(55)}
                />
              </StyledLoadingBar>
            </div>
            <div>{WizardsPages[stepWizard]}</div>
          </CustomPopup>
        </div>
        <CustomPopup
          open={openDeleteCompany}
          onHide={() => setOpenDeleteCompany(false)}
          title="Delete company?"
          title_color="black"
          enableCrossButton={false}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
          deletePopUpStyle={true}
          titleTooltip={false}
        >
          <div className="p-3">
            <div className="px-2">
              {deletePropertiesCount ? (
                <div
                  className="light-font-text my-1"
                  style={{ height: "60px" }}
                >
                  <p>Since this company contains property,</p>
                  <p>It can't deleted.</p>
                </div>
              ) : (
                <div
                  className="light-font-text my-1"
                  style={{ height: "60px" }}
                >
                  <p>You are deleting this company.</p>
                  <p>Are you sure you want to delete?</p>
                </div>
              )}
            </div>
          </div>
          <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => {
                setOpenDeleteCompany(false);
                setDeletePropertiesCount(false);
              }}
            />
            {!deletePropertiesCount && (
              <ETButton
                variant={"primary"}
                buttonText="I'M SURE"
                disabled={DeleteCompanyIsLoading}
                onClick={() => {
                  handleDeleteCompanies();
                  setOpenDeleteCompany(false);
                }}
              />
            )}
          </div>
        </CustomPopup>
      </div>
    </div>
  );
};

export default CompaniesMainTable;

export const TableDataBorderRight = () => {
  return (
    <div
      style={{
        paddingLeft: "1px",
        backgroundColor: "#d1d1d1",
        width: "1px",
        height: "34px",
      }}
    ></div>
  );
};

const FontStyleOnMenuItem = {
  fontSize: "13px",
  fontFamily: "Cerebri sans-light !important",
};
