import React, { useEffect, useState } from "react";
import CreateNewTeam from "../CreateNewTeam";
import AssignUserToTeams from "./AssignUserToTeams";
import CustomPopup from "../../../../../Components/CustomPopup";
import { StyledLoadingBar } from "../../../../../styles/Common.style";
import LoadingBar from "react-top-loading-bar";
import AssignProperties from "./AssignProperties";
import { customproperties } from "../../../../../styles/Theme";
import AssignLeaderToTeams from "./AssignLeaderToTeams";
import { useOrganizationId } from "../../../../../hooks/stores/useOrganizationStore";
import { useGetDivisionByOrganisationId } from "../../../../../hooks/queries/useDivisionQueries";
import { useGetUserByLevel } from "../../../../../hooks/queries/useUserQueries";
import { useGetPropertiesByLevel } from "../../../../../hooks/queries/usePropertyQueries";
import {
  useAddTeam,
  useGetTeamByTeamId,
  useGetTeamsByLevelDetails,
  useUpdateTeam,
} from "../../../../../hooks/queries/useTeam";
import { toast } from "react-toastify";
import { status } from "../../../../../model/Constant";
import {
  ErrorAlert,
  SucessAlert,
} from "../../../../../Components/alerts/AlertsAndMessages";

const CreateNewTeamWizard = ({
  show,
  setShow,
  progress,
  setProgress,
  flag = false,
  teamId,
  assignUsersToTeamData,
  setAssignUsersToTeamData,
  setStepWizard,
  stepWizard,
  setStepOneTeamData,
  stepOneTeamsData,
  GetTeamsByLevelBasicDataRefetch = () => { },
}) => {
  const [propertyScreenHandler, setPropertyScreenHandler] = useState(false);
  // const [stepWizard, setStepWizard] = useState("CreateNewTeam");
  const [teamLeader, setTeamLeader] = useState([]);
  const [uploadImgFile, setUploadImgFile] = useState("");
  const [croppedImage, setCroppedImage] = useState();
  const [teamProfile, setTeamProfile] = useState();
  const [teamMember, setTeamMember] = useState([]);
  const [UserName, setUserName] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [proviousRowSelection, setPreviousRowSelection] = useState({});
  const [teamData, setTeamData] = useState([]);
  const [selectedTeamManager, setSelectedTeamManager] = useState([]);
  const [previousSelectedTeamManager, setPreviousSelectedTeamManager] =
    useState({});
  const [selectedTeamleader, setSelectedTeamleader] = useState([]);
  const [previousSelectedTeamleader, setPreviousSelectedTeamleader] = useState(
    {}
  );
  const [getTeamLeaderOnBack, setTeamLeaderOnBack] = useState({});
  const [assignedPropertyToTeam, setAssignedPropertyToTeam] = useState([]);
  //==============================Get the 1st step of wizard data on the onChange event===================//
  const [selectedTeamDataValue, setSelectedTeamDataValue] = useState({
    division: "",
    teamName: "",
    manager: "",
  });
  // const [stepOneTeamsData, setStepOneTeamData] = useState({})
  //====================================ZUSTAND========================//
  const organizationId = useOrganizationId((state) => state.organizationId);
  //====================================REACT QUERY==========================//
  const { data: GetTeamByTeamIdData } = useGetTeamByTeamId(teamId);
  const EditTeamData = GetTeamByTeamIdData?.data;

  const { data: GetUserBylevelData } = useGetUserByLevel(
    "d",
    selectedTeamDataValue?.division,
    "0",
    "1,2",
    "basic"
  );
  const changeManager = GetUserBylevelData?.data?.filter((data) => data?.Id == Object?.keys(selectedTeamManager))
  // console.log("🚀 ~ file: CreateNewTeamWizard.js:81 ~ changeManager:", changeManager.length)
  // const {
  //   data: GetDivisionByOrganisationId,
  // } = useGetDivisionByOrganisationId(organizationId, "basic");
  const { data: GetTeamsByLevelData, refetch: GetTeamsByLevelRefetch } =
    useGetTeamsByLevelDetails("o", organizationId, "details");

  const { data: GetUserByDetailslevelData } = useGetUserByLevel(
    "d",
    selectedTeamDataValue?.division,
    "0",
    "1,2",
    "details"
  );
  const { data: GetPropertiesByLevelData } = useGetPropertiesByLevel(
    "d",
    selectedTeamDataValue?.division,
    "details",
    "all"
  );

  const { mutateAsync: PostAddTeam, isLoading: addTeamLoading } = useAddTeam();
  const { mutateAsync: PutUpdateTeam, isLoading: updateTeamLoading } =
    useUpdateTeam();

  //===========================Division dropdown=====================//
  // const seletedDivisionOption = GetDivisionByOrganisationId?.data?.map(
  //   (text, index) => ({
  //     value: text?.Id,
  //     label: text?.Text,
  //     isDisabled: selectedTeamDataValue?.division == text?.Id,
  //   })
  // );

  //===================================useeffect=============================//
  useEffect(
    () => {
      const teamManager = EditTeamData?.[0]?.members?.filter(
        (data) => data?.is_team_manager == 1
      );
      const teamProfileUrl = EditTeamData?.[0]?.team_image_filename_url;
      if (flag && GetTeamByTeamIdData?.data.length) {
        // console.log("first")
        setStepOneTeamData({
          ...stepOneTeamsData,
          division: {
            value: EditTeamData?.[0]?.division_id,
            label: EditTeamData?.[0]?.division_name,
          },
          teamName: String(EditTeamData?.map((data) => data?.name)),
          teamManager: {
            value: teamManager?.map?.((data) => data?.user_id),
            label: teamManager?.map?.((data) => data?.manager_name),
          },
        });

        if (flag && changeManager?.length > 0) {
          // console.log("first1")
          setStepOneTeamData({
            ...stepOneTeamsData,
            teamManager: {
              value: changeManager?.[0]?.Id,
              label: changeManager?.[0]?.Text,
            },
          });
        }
        if (teamProfileUrl != "") {
          setCroppedImage(teamProfileUrl);
        }
      } else {
        setStepOneTeamData({});
        setCroppedImage();
      }
    },
    [flag, GetTeamByTeamIdData, EditTeamData]
    // [flag, GetTeamByTeamIdData, EditTeamData, changeManager]
  );

  useEffect(() => {
    setAssignUsersToTeamData(GetPropertiesByLevelData?.data);
  }, [GetPropertiesByLevelData]);

  //=============================Form submit============================================//
  const handleCreateNewTeam = async (propertyId) => {
    let createNewTeamPayload = new FormData();
    const uploadedTeamFile = EditTeamData?.[0]?.team_image_filename;
    if (flag) {
      createNewTeamPayload.append("user_team_id", teamId);
      createNewTeamPayload.append("name", selectedTeamDataValue?.teamName);
      createNewTeamPayload.append(
        "team_image_filename",
        uploadImgFile ? uploadImgFile?.name : uploadedTeamFile
      );
      createNewTeamPayload.append(
        "team_image_file",
        uploadImgFile ? uploadImgFile : ""
      );
      createNewTeamPayload.append(
        "division_id",
        selectedTeamDataValue?.division
      );
      createNewTeamPayload.append("members", JSON.stringify(teamMember)); // Convert to JSON string when you pass array of object in FormData
      createNewTeamPayload.append("properties", JSON.stringify(propertyId)); // Convert to JSON string
    } else {
      createNewTeamPayload.append("name", selectedTeamDataValue?.teamName);
      createNewTeamPayload.append(
        "team_image_file",
        uploadImgFile ? uploadImgFile : selectedTeamDataValue?.teamName
      );
      createNewTeamPayload.append(
        "division_id",
        selectedTeamDataValue?.division
      );
      createNewTeamPayload.append("members", JSON.stringify(teamMember)); // Convert to JSON string when you pass array of object in FormData
      createNewTeamPayload.append("properties", JSON.stringify(propertyId)); // Convert to JSON string
    }
    if (flag) {
      try {
        const result = await PutUpdateTeam(createNewTeamPayload);

        if (result.status === status.s && result.data.bReturn) {
          toast.success(<SucessAlert />);
          // setStepWizard("CreateNewTeam");
          GetTeamsByLevelRefetch();
          setProgress(0);
          setShow(false);
          setSelectedTeamDataValue({
            division: "",
            teamName: "",
            manager: "",
          });
          setUploadImgFile("");
          setCroppedImage();
          setPreviousRowSelection({});
          setPreviousSelectedTeamManager({});
          // setStepOneTeamData({})
          setPreviousSelectedTeamleader({});
          setSelectedTeamleader([]);
          setSelectedTeamManager([]);
          setAssignedPropertyToTeam([]);
        } else {
          toast.error(<ErrorAlert />);
        }
      } catch (error) {
        toast.error(<ErrorAlert />);
      }
    } else {
      try {
        const result = await PostAddTeam(createNewTeamPayload);

        if (result.status === status.s && result.data.bReturn) {
          toast.success(<SucessAlert />);
          // setStepWizard("CreateNewTeam");
          GetTeamsByLevelRefetch();
          GetTeamsByLevelBasicDataRefetch();
          setProgress(0);
          setShow(false);
          setPreviousRowSelection({});
          setPreviousSelectedTeamManager({});
          setPreviousSelectedTeamleader({});
          // setStepOneTeamData({})
          setCroppedImage();
          setUploadImgFile("");
          setSelectedTeamDataValue({});
          setSelectedTeamleader([]);
          setSelectedTeamManager([]);
          setAssignedPropertyToTeam([]);
        } else {
          toast.error(<ErrorAlert />);
        }
      } catch (error) {
        toast.error(<ErrorAlert />);
      }
    }
  };

  //=======================Create team screen======================//
  useEffect(() => {
    const members = EditTeamData?.[0]?.members;
    const memberUserIds = members?.map((member) => member?.user_id);
    const userExistsMap = { ...proviousRowSelection };
    if (flag) {
      GetUserByDetailslevelData?.data?.forEach((user) => {
        const userIdString = user.user_id.toString(); // Convert user_id to string for comparison
        if (
          memberUserIds?.includes(userIdString) ||
          userIdString == selectedTeamDataValue?.manager
        ) {
          userExistsMap[userIdString] = true;
        }
      });

      setRowSelection(userExistsMap);
    } else {
      GetUserByDetailslevelData?.data?.forEach((user) => {
        // Add default selection logic here
        const userIdString = user.user_id.toString();
        if (selectedTeamDataValue?.manager?.includes(userIdString)) {

          userExistsMap[user.user_id] = true;
        }
      });
      setRowSelection(userExistsMap);
    }
  }, [flag, GetUserByDetailslevelData]);

  const handleNextCreateScreen = (data, userProfile, username) => {
    setStepOneTeamData({
      ...stepOneTeamsData,
      ...data,
    });
    setStepWizard("AssignUserToTeam");
    setProgress(50);
    setTeamData(data);
    setUserName(username);
    setTeamProfile(userProfile);
    setSelectedTeamDataValue({
      ...selectedTeamDataValue,
      division: data?.division?.value,
      teamName: data?.teamName,
      manager: data?.teamManager?.value,
    });
  };

  const handleCloseCreateScreen = () => {
    setShow(false);
    setStepWizard("CreateNewTeam");
    setProgress(0);
    setTeamData([]);
    setCroppedImage();
    setSelectedTeamDataValue({
      division: "",
      teamName: "",
      manager: "",
    });
    setCroppedImage();
    setPreviousRowSelection({});
    setPreviousSelectedTeamManager({});
    setPreviousSelectedTeamleader({});
    setSelectedTeamleader([]);
    setSelectedTeamManager([]);
    setAssignedPropertyToTeam([]);

    // setStepOneTeamData({})
  };

  //=======================Add users screen=======================//

  const handleUserScreen = (user_id) => {
    const teamLeaderData = GetUserByDetailslevelData?.data?.filter((item) =>
      user_id?.includes(String(item.user_id))
    );
    const isMembers = EditTeamData?.map((data) => data?.members)?.[0]
      ?.filter((data) => data?.is_team_manager == 1)
      ?.map((user) => user?.user_id);
    const isLeader = EditTeamData?.map((data) => data?.members)?.[0]
      ?.filter((data) => data?.is_team_leader == 1)
      ?.map((user) => user?.user_id);
    const memberlist = EditTeamData?.map((data) => data?.members)?.flat();
    // const settheMemberList = memberlist?.map((data) => data?.user_id)
    // const filteredMembers = isLeader.filter((id) => selectedTeamleader[id] !== false);
    // console.log("🚀 ~ file: CreateNewTeamWizard.js:269 ~ handleUserScreen ~ filteredMembers:", filteredMembers)

    // console.log("🚀 ~ file: CreateNewTeamWizard.js:268 ~ handleUserScreen ~ settheMemberList:", settheMemberList)
    // console.log("🚀 ~ file: CreateNewTeamWizard.js:267 ~ handleUserScreen ~ memberlist:", memberlist)
    setTeamLeader(teamLeaderData);
    const defaultManagerSelection = {};
    teamLeaderData?.forEach((user) => {
      const userIdString = user.user_id; // Convert user_id to string for comparison
      if (selectedTeamDataValue?.manager?.includes(userIdString)) {
        defaultManagerSelection[userIdString] = true;
      }
    });
    setSelectedTeamManager(defaultManagerSelection);

    if (flag) {
      const defaultSelection = {};
      if (isMembers && isMembers.length > 0) {
        // console.log("first")
        memberlist?.forEach((item) => {
          if (isMembers?.includes(item.user_id)) {
            defaultSelection[item.user_id] = true;
          }
        });
      }
      // else if (changeManager && changeManager?.length > 0) {
      //   // console.log("first1")
      //   teamLeaderData?.forEach((user) => {
      //     const userIdString = user.user_id; // Convert user_id to string for comparison
      //     if (selectedTeamDataValue?.manager?.includes(userIdString)) {
      //       defaultManagerSelection[userIdString] = true;
      //     }
      //   });
      // }
      setSelectedTeamManager(defaultSelection);

      // const defaultTeamLeaderSelection = { ...previousSelectedTeamleader };
      // if (isLeader && isLeader.length > 0) {

      //   memberlist.forEach((item) => {
      //     if (isLeader.includes(item.user_id)) {
      //       defaultTeamLeaderSelection[item.user_id] = true;
      //     }
      //   });
      //   setSelectedTeamleader(defaultTeamLeaderSelection);

      // }
      // else if (selectedTeamleader) {
      //   const defaultTeamLeaderSelectionOnBack = { ...selectedTeamleader };
      //   setSelectedTeamleader(defaultTeamLeaderSelectionOnBack);

      // }
    }
    setStepWizard("AssignLeadersToTeam");
    setPropertyScreenHandler(true);
    setProgress(75);
  };
  const handleBackOnUserScreen = () => {
    // console.log(Object.keys(rowSelection), "rowselection")
    setStepWizard("CreateNewTeam");
    setProgress(25);
  };

  //=======================Add leader screen======================//
  useEffect(() => {
    if (flag) {
      const propertiesString = EditTeamData?.[0]?.properties || [];
      const userExistsMap = {};
      assignUsersToTeamData?.forEach((user) => {
        const userIdString = user.property_id; // Convert user_id to string for comparison
        if (propertiesString?.includes(userIdString)) {
          userExistsMap[userIdString] = true;
        }
      });
      setAssignedPropertyToTeam(userExistsMap);
    }
  }, [flag, EditTeamData]);

  const handleLeaderScreen = (teamMemberData) => {
    setTeamMember(teamMemberData);
    // if (flag) {
    //   const propertiesString = EditTeamData?.[0]?.properties || [];
    //   const userExistsMap = {};
    //   console.log("🚀 ~ file: CreateNewTeamWizard.js:381 ~ handleLeaderScreen ~ userExistsMap:", userExistsMap)
    //   assignUsersToTeamData?.forEach((user) => {
    //     const userIdString = user.property_id; // Convert user_id to string for comparison
    //     if (propertiesString?.includes(userIdString)) {
    //       userExistsMap[userIdString] = true;
    //     }
    //   });
    //   setAssignedPropertyToTeam(userExistsMap);
    // }
    setStepWizard("AssignProperties");
    setProgress(99.99);
  };
  const handleBackOnLeaderScreen = () => {
    setTeamLeaderOnBack(selectedTeamleader);
    setStepWizard("AssignUserToTeam");
    setProgress(50);
  };
  //=======================Add property screen====================//
  // const handlePropertyScreens = () => {
  //   setStepWizard("CreateNewTeam");
  //   setProgress(0);
  //   setShow(false);
  // };
  const handlePreviousPropertyScreens = () => {
    setStepWizard("AssignLeadersToTeam");
    setProgress(75);
  };
  //=======================Screens======================//
  const WizardsPages = {
    CreateNewTeam: (
      <CreateNewTeam
        handleNextCreateScreen={handleNextCreateScreen}
        handleCloseCreateScreen={handleCloseCreateScreen}
        GetTeamByTeamIdData={GetTeamByTeamIdData}
        // DivisionOption={seletedDivisionOption}
        // userByLevel={userByLevel}
        teamData={teamData}
        flag={flag}
        // setSelectedDivisionId={setSelectedDivisionId}
        EditTeamData={EditTeamData}
        setSelectedTeamDataValue={setSelectedTeamDataValue}
        selectedTeamDataValue={selectedTeamDataValue}
        croppedImage={croppedImage}
        setCroppedImage={setCroppedImage}
        setUploadImgFile={setUploadImgFile}
        uploadImgFile={uploadImgFile}
        setFormData={setStepOneTeamData}
        formData={stepOneTeamsData}
        selectedTeamManager={selectedTeamManager}

      // GetUserBylevelData={GetUserBylevelData}
      />
    ),
    AssignUserToTeam: (
      <AssignUserToTeams
        handleUserScreen={handleUserScreen}
        handleBackOnUserScreen={handleBackOnUserScreen}
        usedFor="teams"
        GetUserByDetailslevelData={GetUserByDetailslevelData?.data}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        teamData={teamData}
        setPreviousRowSelection={setPreviousRowSelection}
        selectedTeamDataValue={selectedTeamDataValue}
        flag={flag}
        proviousRowSelection={proviousRowSelection}
        EditTeamData={EditTeamData}
      />
    ),

    AssignLeadersToTeam: (
      <AssignLeaderToTeams
        handleBackOnLeaderScreen={handleBackOnLeaderScreen}
        handleLeaderScreen={handleLeaderScreen}
        usedFor="teams"
        teamLeaderData={teamLeader}
        setSelectedTeamleader={setSelectedTeamleader}
        selectedTeamleader={selectedTeamleader}
        rowSelection={selectedTeamManager}
        setRowSelection={setSelectedTeamManager}
        setPreviousSelectedTeamManager={setPreviousSelectedTeamManager}
        setPreviousSelectedTeamleader={setPreviousSelectedTeamleader}
        EditTeamData={EditTeamData}
        previousSelectedTeamleader={previousSelectedTeamleader}
        flag={flag}

      // customeCheckBoxHandler={customeCheckBoxHandler}
      />
    ),

    AssignProperties: (
      <AssignProperties
        goBack={propertyScreenHandler}
        handlePreviousPropertyScreens={handlePreviousPropertyScreens}
        // handlePropertyScreens={handlePropertyScreens}
        usedFor="teams"
        flag={flag}
        assignUsersToTeamData={GetPropertiesByLevelData?.data}
        // assignUsersToTeamData={assignUsersToTeamData}
        rowSelection={assignedPropertyToTeam}
        setRowSelection={setAssignedPropertyToTeam}
        handleCreateNewTeam={handleCreateNewTeam}
        addTeamLoading={addTeamLoading}
        updateTeamLoading={updateTeamLoading}
      />
    ),
  };
  return (
    <div>
      {" "}
      <CustomPopup
        size="xl"
        open={show}
        // onHide={() => setShow(false)}
        title={!flag ? "Create a team" : "Edit team"}
        title_color="black"
        titleTooltipText={!flag ? "Create a team" : "Edit team"}
        titleTooltip={true}
        // enableCrossButton={true}
        // onClickCrossButton={() => {
        //   setShow(false);
        //   setStepWizard("CreateNewTeam");
        // }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div>
          <StyledLoadingBar>
            <LoadingBar
              color={customproperties.primaryColor}
              progress={progress}
              containerStyle={{
                position: "absolute",
                height: "2.5px",
              }}
              shadow={false}
              onLoaderFinished={() => setProgress(0)}
            />
          </StyledLoadingBar>
        </div>
        <div>{WizardsPages[stepWizard]}</div>
      </CustomPopup>
    </div>
  );
};

export default CreateNewTeamWizard;
