import { useEffect, useMemo, useState } from "react";
import { useOrganizationId } from "../../hooks/stores/useOrganizationStore";
import {
  useDeleteDivision,
  useGetDivisionByDivisionId,
  useGetDivisionByOrganisationId,
  usePostAddDivision,
  usePostEditDivision,
} from "../../hooks/queries/useDivisionQueries";
import { Col, Image, Row } from "react-bootstrap";
import NewColorBox from "../../Components/ColorCombinationbox/NewColorBox";
import { H2, StyledLoadingBar } from "../../styles/Common.style";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import MRTTheme from "../../Components/table/MRTTheme";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import {
  SearchIcon,
  SearchStyle,
  paginationoptions,
  SortIcon,
} from "../../Components/table/MRTGlobalProps";
import Menus from "../../Components/Menus/Menus";
import { MenuItem } from "@mui/material";
import CustomPopup from "../../Components/CustomPopup";
import LoadingBar from "react-top-loading-bar";
import { customproperties } from "../../styles/Theme";
import ETButton from "../../Components/Buttons/ETButton";
import { billingDetailsDropdownData } from "../../model/commonDropdownData";
import { capitalizeEachWord } from "../../utils/TextTransform";
import {
  ErrorAlert,
  LoadingAlert,
  SucessAlert,
} from "../../Components/alerts/AlertsAndMessages";
import { toast } from "react-toastify";
import { UTCtoLocalTimeZone } from "../../utils/UTCtoLocalTimeZone";
import { status } from "../../model/Constant";
import CreateNewInformation from "../../Components/wizard/CreateNewInformation";
import CreateBillingDetails from "../../Components/wizard/CreateBillingDetails";
import { useNavigate } from "react-router-dom";
import {
  useDivisionId,
  useDivisionName,
} from "../../hooks/stores/useDivisionsStore";
import { useUserDetails } from "../../hooks/stores/useUserDetailsStore";
import { ImageComponent, ShortNameComponent } from "../../widget/Widget";
import { useQueryClient } from "react-query";
import UploadDivisionThumbnails from "./UploadDivisionThumbnails";

const DivisionsNewTable = () => {
  // ----------------- zustand -------------------------- ----//
  const { organizationId } = useOrganizationId((state) => state);
  const { divisionId, setDivisionId } = useDivisionId((state) => state);
  const { setDivisionName } = useDivisionName((state) => state);
  const { userDetails } = useUserDetails((state) => state);

  // ----------------- State -------------------------- ----//
  const [tableData, setTableData] = useState("");

  const [selectedValue, setSelectedValue] = useState(null);
  const [showWizard, setShowWizard] = useState(false);
  const [progress, setProgress] = useState(55);
  const [stepWizard, setStepWizard] = useState("CreateNewInfo");
  const [isSameDetailAsAboveActive, setIsSameDetailAsAboveActive] =
    useState(false);
  const [divisionData, setDivisionData] = useState("");
  const [openDeleteDivision, setOpenDeleteDivision] = useState(false);
  const [division_id, setDivision_id] = useState("");
  const [isDivisionEdited, setIsDivisionEdited] = useState(false);
  const [sorting, setSorting] = useState([]);

  // --------------------- used for file thumbnail --------------------//
  const [thumbnailFile, setThumbnailFile] = useState("");
  const [thumbNailFilename, setThumbnailFileName] = useState({
    name: "",
    url: "",
    fileSize: "",
  });

  // ----------- navigate-------------
  const navigate = useNavigate();

  // ----------------- React Query ------------------------------//
  const queryClient = useQueryClient();
  // const {
  //   data: GetDivisionByOrganisationId,
  //   refetch: GetDivisionByOrganisationIdRefetch,
  // } = useGetDivisionByOrganisationId(organizationId, "details");

  const {
    data: GetDivisionByOrganisationId,
    refetch: GetDivisionByOrganisationIdRefetch,
  } = useGetDivisionByOrganisationId(organizationId, "details");
  const { mutateAsync: PostAddDivision, isLoading: PostAddDivisionIsLoading } =
    usePostAddDivision();
  const { mutateAsync: DeleteDivision, isLoading: DeleteDivisionIsLoading } =
    useDeleteDivision();
  const {
    data: GetDivisionByDivisionId,
    refetch: GetDivisionByDivisionIdRefetch,
  } = useGetDivisionByDivisionId(division_id);

  const { mutateAsync: PostEditDivision, isLoading: isEditDivisionIsLoading } =
    usePostEditDivision();

  // ----------------- useEffect ------------------------------ //

  // below useEffects created for refreshing states//
  useEffect(() => {
    if (showWizard === true) {
      setThumbnailFile("");
      setThumbnailFileName({
        name: "",
        url: "",
        fileSize: "",
      });
    }
  }, [showWizard]);

  useEffect(() => {
    setTableData(GetDivisionByOrganisationId?.data);
  }, [GetDivisionByOrganisationId]);

  useEffect(() => {
    if (isDivisionEdited && Object.keys(GetDivisionByDivisionId ?? {}).length) {
      const APIcompanyData = GetDivisionByDivisionId?.data?.[0];
      setDivisionData({
        ...divisionData,
        div_name: APIcompanyData?.name,
        company: APIcompanyData?.company_name,
        company_id: APIcompanyData?.company_id,
        short_name: APIcompanyData?.shortname,
        billing_method: {
          value: APIcompanyData?.billing_method_applicable_id,
          label: billingDetailsDropdownData?.filter(
            (data) =>
              data?.Id?.toString() ===
              APIcompanyData?.billing_method_applicable_id?.toString()
          )?.[0]?.Text,
        },
        care_of: APIcompanyData?.care_of,
        vat_number: APIcompanyData?.public_id1,
        gst_number: APIcompanyData?.public_id2,
        cvr_number: APIcompanyData?.public_id3,
        street: APIcompanyData?.street_name,
        house_number: APIcompanyData?.house_number,
        division_owner: {
          value: APIcompanyData?.owner_id,
          label: APIcompanyData?.owner_name,
          isDisabled: true,
        },
        floor_number: {
          value: APIcompanyData?.floor_id,
          label: APIcompanyData?.floor_name,
          isDisabled: true,
        },
        side: {
          value: APIcompanyData?.side_id,
          label: APIcompanyData?.side_name,
          isDisabled: true,
        },
        country: {
          value: APIcompanyData?.country_id,
          label: APIcompanyData?.country,
          isDisabled: true,
        },
        door: APIcompanyData?.door,
        zipcode_id: {
          value: APIcompanyData?.zipcode_id,
          label: APIcompanyData?.city,
          isDisabled: true,
        },
        contact_person_name: APIcompanyData?.contact_person_name,
        website: APIcompanyData?.website_url,
        country_code: APIcompanyData?.contact_person_isd_code,
        // country_code: {
        //   value: APIcompanyData?.contact_person_isd_code,
        //   label: APIcompanyData?.contact_person_isd_code,
        //   isDisabled: true,
        // },
        city: APIcompanyData?.city,
        contact_person_phone_no: APIcompanyData?.contact_person_phone_no,
        contact_person_email: APIcompanyData?.contact_person_email,
        bInfo_company: APIcompanyData?.billing_details[0]?.company_name,
        bInfo_care_of: APIcompanyData?.billing_details[0]?.care_of,
        bInfo_cvr_number: APIcompanyData?.billing_details[0]?.public_id3,
        bInfo_vat_number: APIcompanyData?.billing_details[0]?.public_id1,
        bInfo_gst_number: APIcompanyData?.billing_details[0]?.public_id2,
        bInfo_street: APIcompanyData?.billing_details[0]?.street_name,
        bInfo_house_number: APIcompanyData?.billing_details[0]?.house_number,
        bInfo_floor_number: {
          value: APIcompanyData?.billing_details[0]?.floor_id,
          label: APIcompanyData?.billing_details[0]?.floor_name,
          disabled: true,
        },
        bInfo_side: {
          value: APIcompanyData?.billing_details?.[0]?.side_id,
          label: APIcompanyData?.billing_details?.[0]?.side_name,
          disabled: true,
        },
        bInfo_door: APIcompanyData?.billing_details[0]?.door,
        bInfo_country: {
          value: APIcompanyData?.billing_details?.[0]?.country_id,
          label: APIcompanyData?.billing_details?.[0]?.country,
          disabled: true,
        },
        bInfo_city: APIcompanyData?.billing_details[0]?.city,
        invoice_email: APIcompanyData?.billing_details[0]?.invoice_email,
        attention_person:
          APIcompanyData?.billing_details[0]?.attention_person_name,
        ean_number: APIcompanyData?.billing_details[0]?.ean,
        bInfo_zipCode: {
          value: APIcompanyData?.billing_details[0]?.zipcode_id,
          label: APIcompanyData?.billing_details[0]?.city,
          disabled: true,
        },
      });
      setIsSameDetailAsAboveActive(
        APIcompanyData?.is_same_detail_as_defined_general === "1" ? 1 : 0
      );
      setThumbnailFile(APIcompanyData?.logo_thumb_url);
      setThumbnailFileName({
        name: APIcompanyData?.logo_thumb_filename,
        url: APIcompanyData?.logo_thumb_url,
        fileSize: APIcompanyData?.logo_thumb_filesize,
      });
    } else {
      setDivisionData({});
    }
  }, [isDivisionEdited, GetDivisionByDivisionId, showWizard]);

  const handleEditDivision = (division_id, name) => {
    setDivisionId(division_id);
    setDivisionName(name);
    navigate("/division/details", {
      state: {
        Tab: "Details",
        divisionName: name,
        divisionId: division_id,
      },
    });
  };

  const columns = useMemo(
    () => [
      // {
      //   id: "image",
      //   header: "",
      //   enableHiding: false,
      //   size: 70,
      //   Cell: ({ row }) => (
      //     <>
      //       {row.original.logo_thumb_url ? (
      //         <div className="d-flex align-items-center justify-content-center">
      //           <Image
      //             className="rounded"
      //             src={row.original.logo_thumb_url}
      //             height={60}
      //             width={60}
      //           />
      //         </div>
      //       ) : (
      //         <div
      //           className="radius-8 d-flex align-items-center justify-content-center fs-20"
      //           style={{
      //             width: "60px",
      //             height: "60px",
      //             backgroundColor: row?.original?.shortname_background_color
      //               ? row?.original?.shortname_background_color
      //               : "#d1d1d1",
      //             color: row?.original?.shortname_text_color
      //               ? row?.original?.shortname_text_color
      //               : "#000",
      //             fontFamily: "Cerebri sans",
      //             textTransform: "uppercase",
      //           }}
      //         >
      //           {row.original.shortname}
      //         </div>
      //       )}
      //     </>
      //   ),
      //   //center align the header and body cells
      //   muiTableHeadCellProps: {
      //     align: "right",
      //     sx: {
      //       paddingBottom: "0px !important",
      //     },
      //   },
      //   muiTableBodyCellProps: {
      //     sx: {
      //       paddingLeft: "16px!important",
      //       paddingRight: "0px!important",
      //       borderTop: "1px solid #D1D1D1 !important",
      //       borderBottom: "1px solid #D1D1D1 !important",
      //       borderLeft: "1px solid #D1D1D1 !important",
      //     },
      //   },
      // },
      {
        accessorFn: (row) => `${row.name}`,
        // accessorKey: "name",
        header: "Division",
        enableHiding: false,
        size: 200,
        sortingFn: "textCaseSensitive",
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div className="d-flex">
            <ImageComponent
              imageUrl={row.original.logo_thumb_url}
              altText={"division image"}
              shortNameComponent={
                <ShortNameComponent
                  backgroundColor={row?.original?.shortname_background_color}
                  fontColor={row?.original?.shortname_text_color}
                  shortNameText={row.original.shortname}
                />
              }
            />

            <div
              className="d-flex align-items-center gap-3 "
              style={{ paddingLeft: "16px" }}
            >
              <div
                className="cursor-pointer d-flex flex-row justify-content-start  gap-3 "
                onClick={() => {
                  handleEditDivision(
                    row?.original?.division_id,
                    row?.original?.name
                  );
                }}
              >
                <div
                  style={{ textAlign: "left" }}
                  className="d-flex flex-column pt-1"
                >
                  <span className="table-property-name" id="property">
                    {row.original.name == "" ? "-" : row.original.name}
                  </span>
                  <span className="fs-11 blackColor">
                    Owner:
                    {row?.original?.owner_name == ""
                      ? "-"
                      : row?.original?.owner_name}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ),
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "24px!important",
            border: "1px solid #D1D1D1 !important",
            paddingTop: "8px!important",
            paddingBottom: "8px!important",
          },
        },
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "100px!important",
            paddingBottom: "0px !important",
          },
        },
      },
      {
        accessorKey: "property_count",
        header: "Properties",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,
        muiTableBodyCellProps: {
          align: "center",
          sx: {
            paddingRight: "2px !important",
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
            paddingLeft: "24px !important",
          },
        },
        muiTableHeadCellProps: {
          sx: {
            paddingRight: "0px !important",
            paddingBottom: "0px !important",
            paddingLeft: "24px !important",
          },
        },
        Cell: ({ row }) => (
          <div className="d-flex justify-content-between align-items-center ">
            <div className="d-flex  align-items-center">
              {row.original?.properties_count?.toString() !== "0" ? (
                <div>{row.original?.properties_count}&nbsp;</div>
              ) : (
                <span style={{ color: "#888888" }}>No properties</span>
              )}
              {parseInt(row.original?.properties_count) > 1
                ? "properties"
                : row.original?.properties_count?.toString() === "1"
                ? "property"
                : " "}
            </div>
            <TableDataBorderRight />
          </div>
        ),
      },
      {
        accessorKey: "unit_count",
        header: "Units",
        enableHiding: false,
        size: 90,
        enableGlobalFilter: false,
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "2px !important",
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
            paddingLeft: "24px !important",
          },
        },
        muiTableHeadCellProps: {
          sx: {
            paddingRight: "0px !important",
            paddingBottom: "0px !important",
            paddingLeft: "24px !important",
          },
        },
        Cell: ({ row }) => (
          <div className=" d-flex  justify-content-between align-items-center">
            <div className="d-flex  align-items-center gap-2">
              {row.original?.unit_count?.toString() !== "0" ? (
                row.original?.unit_count
              ) : (
                <span style={{ color: "#888888" }}>No units</span>
              )}
            </div>
            <TableDataBorderRight />
          </div>
        ),
      },
      {
        accessorKey: "active_users",
        header: "Active users",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "0px !important",
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
            paddingLeft: "24px !important",
          },
        },
        muiTableHeadCellProps: {
          sx: {
            paddingRight: "0px !important",
            paddingBottom: "0px !important",
            paddingLeft: "24px !important",
          },
        },
        Cell: ({ row }) => (
          <div className="d-flex justify-content-between align-items-center">
            {row.original?.active_users?.toString() !== "0"
              ? row.original?.active_users
              : "0"}{" "}
            <TableDataBorderRight />
          </div>
        ),
      },
      {
        accessorKey: "created",
        header: "Created",
        enableHiding: false,
        size: 140,
        enableGlobalFilter: false,
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "0px !important",
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
            paddingLeft: "24px !important",
          },
        },
        muiTableHeadCellProps: {
          sx: {
            paddingRight: "0px !important",
            paddingBottom: "0px !important",
            paddingLeft: "24px !important",
          },
        },
        Cell: ({ row }) => {
          const { convertedDate: created_date } = UTCtoLocalTimeZone({
            timestamp: row.original?.created,
            offset_mins: userDetails?.timezone_offset_mins,
            timeFormat: "DD MMM, YYYY",
          }); // Get current Time zone
          return (
            <div className="d-flex justify-content-between align-items-center">
              {row.original?.created ? created_date : "0"}{" "}
              <TableDataBorderRight />
            </div>
          );
        },
      },
      {
        accessorFn: (row) =>
          `${row.not_active} ${row.available} ${row.reserved}${row.in_process} ${row.completed}${row.units}`,
        header: "Units overview",
        disableSortBy: true,
        enableGlobalFilter: false,
        size: 140,
        enableSorting: false,
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "24px !important",
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
          },
        },

        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "24px !important",
            paddingBottom: "0px !important",
          },
        },
        Cell: ({ row }) => (
          <NewColorBox
            unitsOverview={row?.original?.overall_status}
            TotalUnits={row?.original?.unit_count}
          />
        ),
      },
    ],
    []
  );

  const renderEmptyRowsFallback = () => {
    if (tableData && tableData?.length === 0) {
      return (
        <div
          className="d-flex justify-content-center w-100 grey-text py-4 radius-4"
          style={{ border: "1px solid #d1d1d1", backgroundColor: "#F9FAFA" }}
        >
          <>
            <div className="fs-14">
              You do not have any divisions added yet. Click on “+ CREATE NEW
              DIVISION” to add your very first division!
            </div>
          </>
        </div>
      );
    } else {
      return (
        <div
          className="d-flex justify-content-center w-100 grey-text py-4 radius-4"
          style={{ border: "1px solid #d1d1d1", backgroundColor: "#F9FAFA" }}
        >
          <>
            <div className="fs-14">
              Sorry! No result found. Please try again!
            </div>
          </>
        </div>
      );
    }
  };

  const handleFilterOrganization = (selectedOption) => {
    setSelectedValue(selectedOption);

    if (selectedOption !== null) {
      const filtered = GetDivisionByOrganisationId?.data?.filter(
        (item) => item?.organisation_id === selectedOption.value
      );
      setTableData(filtered);
    } else {
      setTableData(GetDivisionByOrganisationId?.data);
    }
  };

  const handleNext = (data) => {
    setProgress(99.66);
    setStepWizard("CreateNewBillingDetails");
    setDivisionData({
      ...divisionData,
      ...data,
    });
  };

  const handleCreateNewInfoBack = () => {
    setProgress(55);
    setShowWizard(false);
  };

  // -------------- handle create new billing details -----------
  const handleCreateNewDivisionPost = async () => {

    let id;
    let formData = new FormData();
    let divisionDetailsPlayload = {
      name: capitalizeEachWord(divisionData?.div_name),
      company_name: capitalizeEachWord(divisionData?.company),
      shortname: divisionData?.short_name?.toUpperCase(),
      care_of: capitalizeEachWord(divisionData?.care_of),
      street_name: divisionData?.street,
      house_number: divisionData?.house_number,
      zipcode_id: divisionData?.zipcode_id?.value,
      country_id: divisionData?.country?.value,
      owner_id: divisionData?.division_owner?.value,
      public_type_id1: "1",
      public_id1: divisionData?.vat_number || "",
      public_type_id2: "1",
      public_id2: divisionData?.gst_number || "",
      public_type_id3: "1",
      public_id3: divisionData?.cvr_number || "",
      contact_person_name: capitalizeEachWord(
        divisionData?.contact_person_name
      ),
      website_url: divisionData?.website,
      contact_person_email: divisionData?.contact_person_email?.toLowerCase(),
      contact_person_isd_code: divisionData?.country_code,
      contact_person_phone_no: divisionData?.contact_person_phone_no,
      door: divisionData?.door || "",
      side_id: divisionData?.side?.value?.toString(),
      floor_id: divisionData?.floor_number?.value?.toString(),
      is_same_detail_as_defined_general: isSameDetailAsAboveActive ? "1" : "0",
      billing_method_applicable_id: divisionData?.billing_method?.value,
      billing_details:
        divisionData?.billing_method?.value?.toString() === "D"
          ? JSON.stringify({
              company_name: capitalizeEachWord(divisionData?.bInfo_company),
              care_of: capitalizeEachWord(divisionData?.bInfo_care_of),
              street_name: divisionData?.bInfo_street,
              house_number: divisionData?.bInfo_house_number,
              door: divisionData?.bInfo_door,
              floor_id:
                divisionData?.bInfo_floor_number?.value?.toString() || "",
              side_id: divisionData?.bInfo_side?.value?.toString() || "",
              zipcode_id: divisionData?.bInfo_zipCode?.value || "",
              country_id: divisionData?.bInfo_country?.value,
              public_id1: divisionData?.bInfo_vat_number || "",
              public_id2: divisionData?.bInfo_gst_number || "",
              public_id3: divisionData?.bInfo_cvr_number || "",
              public_type_id1: "1",
              public_type_id2: "1",
              public_type_id3: "1",
              ean_number: divisionData?.ean_number,
              invoice_email: divisionData?.invoice_email?.toLowerCase(),
              attention_person: capitalizeEachWord(
                divisionData?.attention_person
              ),
            })
          : JSON.stringify({}),
    };
    for (const [key, value] of Object.entries(divisionDetailsPlayload)) {
      formData.append(key, value);
    }
    // if (
    //   (data?.vat_number === "" &&
    //     data?.gst_number === "" &&
    //     data?.cvr_number === "") ||
    //   (data?.bInfo_gst_number === "" &&
    //     data?.bInfo_cvr_number === "" &&
    //     data?.bInfo_vat_number === "")
    // ) {
    //   toast.info(
    //     <ErrorAlert
    //       errorAlertMessage={
    //         "Either a GST number, a VAT number, or a CVR number is required."
    //       }
    //     />,
    //     { autoClose: 3000 }
    //   );
    // } else {
    if (isDivisionEdited) {
      formData.append("division_id", division_id?.toString());
      // By default I am saving the url in string format, if the string contains thumbnail url then it will send " " otherwise it will send the updated file as the file is always in object format. : Suraj
      formData.append(
        "logo_thumb_file",
        typeof thumbnailFile !== "string" ? thumbnailFile?.[0] : ""
      );
      formData.append(
        "logo_thumb_filename",
        typeof thumbnailFile !== "string"
          ? thumbnailFile?.[0]?.name
          : thumbNailFilename?.name
      );

      if (!isEditDivisionIsLoading) {
        id = toast.loading(<LoadingAlert />);
      }
      try {
        const response = await PostEditDivision(formData);
        if (response.status === status.s && response.data.bReturn) {
          toast.update(id, {
            // render: <SucessAlert />,

            isLoading: false,
            type: status.s,
            autoClose: 200,
          });

          // setProgress(99.99);
          setStepWizard("CreateNewInfo");
          setShowWizard(false);
          setDivisionData({});
          setIsSameDetailAsAboveActive(false);
          GetDivisionByOrganisationIdRefetch();
          GetDivisionByDivisionIdRefetch();
        } else {
          toast.update(id, {
            render: <ErrorAlert />,
            isLoading: false,
            type: status.e,
            autoClose: 2000,
          });
        }
      } catch (error) {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    } else {
      formData.append("organisation_id", organizationId?.toString());
      formData.append("logo_thumb_file", thumbnailFile?.[0]);

      if (!PostAddDivisionIsLoading) {
        id = toast.loading(<LoadingAlert />);
      }
      try {
        const response = await PostAddDivision(formData);
        if (response.status === status.s && response.data.bReturn) {
          toast.update(id, {
            // render: <SucessAlert />,
            isLoading: false,
            type: status.s,
            autoClose: 200,
          });

          // setProgress(99.99);
          setStepWizard("CreateNewInfo");
          setShowWizard(false);
          setDivisionData({});
          GetDivisionByOrganisationIdRefetch();
          setIsSameDetailAsAboveActive(false);
        } else {
          toast.update(id, {
            render: <ErrorAlert />,
            isLoading: false,
            type: status.e,
            autoClose: 2000,
          });
        }
      } catch (error) {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    }
    // }
    // let id;
    // let formData = new FormData();
    // let divisionDetailsPlayload = {
    //   name: capitalizeEachWord(data?.div_name),
    //   company_name: capitalizeEachWord(data?.company),
    //   shortname: data?.short_name?.toUpperCase(),
    //   care_of: capitalizeEachWord(data?.care_of),
    //   street_name: data?.street,
    //   house_number: data?.house_number,
    //   zipcode_id: data?.zipcode_id?.value,
    //   country_id: data?.country?.value,
    //   owner_id: data?.division_owner?.value,
    //   public_type_id1: "1",
    //   public_id1: data?.vat_number || "",
    //   public_type_id2: "1",
    //   public_id2: data?.gst_number || "",
    //   public_type_id3: "1",
    //   public_id3: data?.cvr_number || "",
    //   contact_person_name: capitalizeEachWord(data?.contact_person_name),
    //   website_url: data?.website,
    //   contact_person_email: data?.contact_person_email?.toLowerCase(),
    //   contact_person_isd_code: data?.country_code,
    //   contact_person_phone_no: data?.contact_person_phone_no,
    //   door: data?.door || "",
    //   side_id: data?.side?.value?.toString(),
    //   floor_id: data?.floor_number?.value?.toString(),
    //   is_same_detail_as_defined_general: isSameDetailAsAboveActive ? "1" : "0",
    //   billing_method_applicable_id: data?.billing_method?.value,
    //   billing_details:
    //     data?.billing_method?.value?.toString() === "D"
    //       ? JSON.stringify({
    //           company_name: capitalizeEachWord(data?.bInfo_company),
    //           care_of: capitalizeEachWord(data?.bInfo_care_of),
    //           street_name: data?.bInfo_street,
    //           house_number: data?.bInfo_house_number,
    //           door: data?.bInfo_door,
    //           floor_id: data?.bInfo_floor_number?.value?.toString() || "",
    //           side_id: data?.bInfo_side?.value?.toString() || "",
    //           zipcode_id: data?.bInfo_zipCode?.value || "",
    //           country_id: data?.bInfo_country?.value,
    //           public_id1: data?.bInfo_vat_number || "",
    //           public_id2: data?.bInfo_gst_number || "",
    //           public_id3: data?.bInfo_cvr_number || "",
    //           public_type_id1: "1",
    //           public_type_id2: "1",
    //           public_type_id3: "1",
    //           ean_number: data?.ean_number,
    //           invoice_email: data?.invoice_email?.toLowerCase(),
    //           attention_person: capitalizeEachWord(data?.attention_person),
    //         })
    //       : JSON.stringify({}),
    //   logo_thumb_file: "",
    // };
    // for (const [key, value] of Object.entries(divisionDetailsPlayload)) {
    //   formData.append(key, value);
    // }

    // if (isDivisionEdited) {
    //   formData.append("division_id", division_id?.toString());
    //   formData.append("logo_thumb_file", ""); //File will be dynamic once image upload component is integrated
    //   formData.append(
    //     "logo_thumb_filename",
    //     GetDivisionByDivisionId?.data?.[0]?.logo_thumb_filename
    //   ); //File name will be dynamic once image upload component is integrated

    //   if (!isEditDivisionIsLoading) {
    //     id = toast.loading(<LoadingAlert />);
    //   }
    //   try {
    //     const response = await PostEditDivision(formData);
    //     if (response.status === status.s && response.data.bReturn) {
    //       toast.update(id, {
    //         // render: <SucessAlert />,

    //         isLoading: false,
    //         type: status.s,
    //         autoClose: 200,
    //       });

    //       setProgress(55);
    //       setStepWizard("CreateNewInfo");
    //       // setStepWizard("DivisionCreated");
    //       setShowWizard(false);
    //       // setDivisionData({});
    //       setIsSameDetailAsAboveActive(false);
    //       setDivision_id("");
    //       // GetDivisionByOrganisationIdRefetch();
    //       // GetDivisionByDivisionIdRefetch();
    //     } else {
    //       toast.update(id, {
    //         render: <ErrorAlert />,
    //         isLoading: false,
    //         type: status.e,
    //         autoClose: 2000,
    //       });
    //     }
    //   } catch (error) {
    //     toast.update(id, {
    //       render: <ErrorAlert />,
    //       isLoading: false,
    //       type: status.e,
    //       autoClose: 2000,
    //     });
    //   }
    // } else {
    //   let id;
    //   formData.append("organisation_id", organizationId?.toString());

    //   if (!PostAddDivisionIsLoading) {
    //     id = toast.loading(<LoadingAlert />);
    //   }
    //   try {
    //     const response = await PostAddDivision(formData, {
    //       onSuccess: () =>
    //         queryClient.invalidateQueries("qGetOrganizationOverviewById"),
    //     });
    //     if (response.status === status.s && response.data.bReturn) {
    //       toast.update(id, {
    //         // render: <SucessAlert />,
    //         isLoading: false,
    //         type: status.s,
    //         autoClose: 200,
    //       });

    //       setProgress(55);
    //       setStepWizard("CreateNewInfo");
    //       setShowWizard(false);
    //       // setDivisionData({});
    //       GetDivisionByOrganisationIdRefetch();
    //       setIsSameDetailAsAboveActive(false);
    //     } else {
    //       toast.update(id, {
    //         render: <ErrorAlert />,
    //         isLoading: false,
    //         type: status.e,
    //         autoClose: 2000,
    //       });
    //     }
    //   } catch (error) {
    //     toast.update(id, {
    //       render: <ErrorAlert />,
    //       isLoading: false,
    //       type: status.e,
    //       autoClose: 2000,
    //     });
    //   }
    // }
  };

  // -------------- handle Delete Division -----------
  const handleDeleteDivision = async () => {
    let id;

    try {
      const response = await DeleteDivision(division_id, {
        onSuccess: () => {
          queryClient.invalidateQueries("qGetOrganizationOverviewById");
          queryClient.invalidateQueries("qGetDivisionByUserId");
        },
      });
      if (!DeleteDivisionIsLoading) {
        id = toast.loading(
          // <LoadingAlert
          //   loadingAlertMessage={"The system is currently deleting a Division"}
          // />
          <LoadingAlert />
        );
      }
      if (response.status == status.s && response.data.bReturn) {
        toast.update(id, {
          render: <SucessAlert />,
          isLoading: false,
          type: status.s,
          autoClose: 2000,
        });
        if (division_id?.toString() === divisionId?.toString()) {
          setDivisionId("");
        }
        GetDivisionByOrganisationIdRefetch();
        setDivision_id("");
      } else {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.update(id, {
        render: <ErrorAlert />,
        isLoading: false,
        type: status.e,
        autoClose: 2000,
      });
    }
  };
  // -------------- handle create new billing details -----------
  const handleCreateNewBillingDetailsNext = (data) => {
    setProgress(99.99);
    setStepWizard("UploadDivisionThumbnails");
    setDivisionData({
      ...divisionData,
      ...data,
    });
  };
  const handleCreateNewBillingDetailsBack = () => {
    setProgress(55);
    setStepWizard("CreateNewInfo");
  };

  // handle edit division wizard
  const handleEditCompany = () => {
    setIsSameDetailAsAboveActive(false);
    setShowWizard(true);
    setIsDivisionEdited(true);
    // setCompanyId(companyId);
    // setCompaniesName(company_name);
    // navigate("/company/details", {
    //   state: {
    //     editCompanyId: companyId,
    //     companyName: company_name,
    //     editOrganizationId: organizationId,
    //     Tab: "Details",
    //   },
    // });
  };

  //   --------division created----//

  const HandleDivisionCreated = () => {
    setProgress(55);
    setShowWizard(false);
    setStepWizard("CreateNewInfo");
    setDivisionData({});
  };

  // ----------------- handle upload thumbnail ----------------- //

  const handleUploadThumbnailBack = () => {
    setStepWizard("CreateNewBillingDetails");
    setProgress(66.66);
  };

  const props = {
    flag: "Division",
    setShow: setShowWizard,
    setStepWizard: setStepWizard,
    setProgress,
    handleNext: handleNext,
    handleClose: handleCreateNewInfoBack,
    formData: divisionData,
    setFormData: setDivisionData,
    isSameDetailAsAboveActive,
    setIsSameDetailAsAboveActive,
    handlePrevious: handleCreateNewBillingDetailsBack,
    handleAddDataFun: handleCreateNewBillingDetailsNext,
    createButtonDisable: PostAddDivisionIsLoading || isEditDivisionIsLoading,
    flagEdit: isDivisionEdited,
    handleCloseButton: HandleDivisionCreated,
    name: divisionData?.div_name,
    divisionId: division_id,

    address: `${divisionData?.street || ""} ${
      divisionData?.house_number || "-"
    }, ${divisionData?.zipcode_id?.value || ""} ${divisionData?.city || ""}`,
    organizationOwner: `${divisionData?.division_owner?.label || ""} `,
    handleUploadThumbnailBack: handleUploadThumbnailBack,
    handleUploadThumbnailNext: handleCreateNewDivisionPost,
    thumbnailFile: thumbnailFile,
    setThumbnailFile: setThumbnailFile,
    thumbNailFilename: thumbNailFilename,
    setThumbnailFileName: setThumbnailFileName,
  };

  const WizardsPages = {
    CreateNewInfo: <CreateNewInformation {...props} />,
    CreateNewBillingDetails: <CreateBillingDetails {...props} />,
    UploadDivisionThumbnails: <UploadDivisionThumbnails {...props} />,
    // DivisionCreated: <CreateClientStepFour {...props} />,
  };

  return (
    <div className="whiteContainerStyle">
      <div className="p-4 border-bottom">
        <div className="d-flex align-items-center gap-2">
          <H2>Divisions</H2>
          <CustomToolTip size="sm" text="Division" />
        </div>
      </div>
      <div className="p-4">
        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={columns?.map((columns) => {
                return {
                  ...columns,
                  sortDescFirst: false,
                };
              })}
              autoResetPageIndex={false}
              renderEmptyRowsFallback={renderEmptyRowsFallback}
              data={GetDivisionByOrganisationId?.data || []} //data is undefined on first render
              enableColumnActions={false}
              enableBottomToolbar={true}
              icons={{
                ArrowDownwardIcon: () => SortIcon?.ArrowDownwardIcon(sorting),
                SyncAltIcon: () => SortIcon?.SyncAltIcon(),
              }}
              state={{
                sorting: sorting,
              }}
              onSortingChange={setSorting}
              globalFilterFn="contains"
              muiSearchTextFieldProps={{
                ...SearchStyle,

                InputProps: {
                  ...SearchIcon,
                },
              }}
              getRowId={(originalRow) => originalRow?.company_id}
              //give each row a more useful id
              enableSelectAll={false}
              enableRowActions
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "",
                  size: 40,
                  muiTableBodyCellProps: {
                    sx: {
                      paddingRight: "16px!important",
                      borderTop: "1px solid #D1D1D1 !important",
                      borderBottom: "1px solid #D1D1D1 !important",
                      borderRight: "1px solid #D1D1D1 !important",
                      alignItems: "left",
                    },
                  },
                },
                "mrt-row-select": {
                  header: "",
                },
              }}
              muiTablePaperProps={{
                sx: {
                  backgroundColor: "transparent",
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                },
              }}
              renderTopToolbar={({ table }) => (
                <>
                  <div className="pb-2" style={{ paddingTop: "1px" }}>
                    <Row className="justify-content-end">
                      <Col xl={6}>
                        <div className="d-flex justify-content-end gap-3">
                          <Row>
                            <Col lg={{ span: 10, offset: 2 }}>
                              <MRT_GlobalFilterTextField table={table} />
                            </Col>
                          </Row>
                          <div className="d-flex justify-content-end">
                            <ETButton
                              onClick={() => {
                                setIsDivisionEdited(false);
                                setDivisionData({});
                                setIsSameDetailAsAboveActive(false);
                                setShowWizard(true);
                                setProgress(55);
                              }}
                              variant={"primary"}
                              buttonText="Create new division"
                              width="auto"
                              icon={true}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
              muiTableContainerProps={{
                elevation: 0,
                sx: {
                  background: "white",
                  // margin: "16px 0px 0px 0px ",
                  padding: "0px",
                },
              }}
              positionActionsColumn="last"
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
                sorting: [
                  { id: "created", desc: true }, // sort by created_date in descending order by default
                ],
              }}
              //   rowCount={noOfRows}

              muiPaginationProps={{
                ...paginationoptions,
              }}
              renderRowActions={({ row, table }) => (
                <>
                  <div className="d-flex justify-content-start">
                    <Menus
                      itemheight={24}
                      itemwidth={24}
                      iconborderradius="4px"
                      itemcontainerwidth="150px"
                      component={
                        <>
                          <MenuItem
                            key="Go_to_division"
                            sx={FontStyleOnMenuItem}
                            onClick={() => {
                              handleEditDivision(
                                row?.original?.division_id,
                                row?.original?.name
                              );
                            }}
                          >
                            Go to Division
                          </MenuItem>
                          <hr className="hr m-0"></hr>
                          <MenuItem
                            key="edit"
                            sx={FontStyleOnMenuItem}
                            onClick={() => {
                              setDivision_id(row?.original?.division_id);
                              handleEditCompany();
                            }}
                          >
                            Edit division
                          </MenuItem>
                          <hr className="hr m-0"></hr>
                          <MenuItem
                            key="disconnect_all_users"
                            sx={FontStyleOnMenuItem}
                          >
                            Disconnect all users
                          </MenuItem>
                          <hr className="hr m-0"></hr>
                          <MenuItem
                            key="delete"
                            sx={FontStyleOnMenuItem}
                            onClick={() => {
                              setOpenDeleteDivision(true);
                              setDivision_id(row?.original?.division_id);
                            }}
                          >
                            Delete division
                          </MenuItem>
                        </>
                      }
                    />
                  </div>
                </>
              )}
            />
          }
        />
        <div>
          <CustomPopup
            style={{ position: "relative" }}
            size="xl"
            open={showWizard}
            // onHide={() => setShow(false)}
            title={
              `${isDivisionEdited ? "Edit division" : "Create division"}`
              // (flag == "organization" && "Create division") ||
              // (flag == "division" && "Create company") ||
              // (flag == "company" && "Create property")
            }
            title_color="black"
            titleTooltipText="Need Help About Tags"
            titleTooltip={true}
            enableCrossButton={true}
            onClickCrossButton={() => {
              setStepWizard("CreateNewInfo");
              setProgress(55);
              setShowWizard(false);
              setDivisionData({});
              setIsSameDetailAsAboveActive(false);
            }}
            action_button_label="YES"
            action_button_label_color="white"
            action_button_color="#619130"
            cancel_button={true}
            cancel_button_bordercolor="grey"
            cancel_button_label="NO"
            cancel_button_label_color="black"
            cancel_button_color="#fff"
            footer_color={"#F9FAFA"}
          >
            <div>
              <StyledLoadingBar>
                <LoadingBar
                  color={customproperties.primaryColor}
                  progress={progress}
                  containerStyle={{
                    position: "absolute",
                    height: "2.5px",
                  }}
                  shadow={false}
                  onLoaderFinished={() => setProgress(55)}
                />
              </StyledLoadingBar>
            </div>
            <div>{WizardsPages[stepWizard]}</div>
          </CustomPopup>
        </div>
        {/* ------ Delete Pop up ------- */}
        <CustomPopup
          open={openDeleteDivision}
          onHide={() => setOpenDeleteDivision(false)}
          title="Delete Division?"
          title_color="black"
          enableCrossButton={false}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
          deletePopUpStyle={true}
          titleTooltip={false}
        >
          <div className="p-3">
            <div className="px-2">
              <div className="light-font-text my-1" style={{ height: "60px" }}>
                <p>You are deleting this division.</p>
                <p>Are you sure you want to delete?</p>
              </div>
            </div>
          </div>
          <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => {
                setOpenDeleteDivision(false);
              }}
            />
            <ETButton
              variant={"primary"}
              buttonText="I'M SURE"
              disabled={DeleteDivisionIsLoading}
              onClick={() => {
                handleDeleteDivision();
                setOpenDeleteDivision(false);
              }}
            />
          </div>
        </CustomPopup>
      </div>
    </div>
  );
};

export default DivisionsNewTable;

export const TableDataBorderRight = () => {
  return (
    <div
      style={{
        paddingLeft: "1px",
        backgroundColor: "#d1d1d1",
        width: "1px",
        height: "34px",
      }}
    ></div>
  );
};

const FontStyleOnMenuItem = {
  fontSize: "13px",
  fontFamily: "Cerebri sans-light !important",
};
